/*Lib*/
@import 'lib/bootstrap.min';
@import 'lib/fullpage.min';
@import 'lib/animate.min';
@import 'lib/owl.carousel.min';
@import 'lib/owl.theme.default.min';
/*Lib*/

@import 'variables';
@import 'reset';
@import 'settings';
@import 'fonts';
@import 'icons';

.noise{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	&:after{
		content: "";
	    position: absolute;
	    z-index: -1;
	    height: 180%;
	    left: -40%;
	    top: -40%;
	    width: 180%;
	    pointer-events: none;
	    will-change: transform;
	    transition: opacity .6s ease-out;
	    -webkit-animation: noiseAnimation 1s steps(4) infinite;
	    animation: noiseAnimation 1s steps(4) infinite;
	    background-image: url(#{$images}/content/look-noise.png);
	    opacity: 0.85;
	}
}

#loader{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $color-primary;
	z-index: 99;
	transform: translate3d(0,0,0);
	display: none;
	#hello-bar{
		max-width: 460px;
		max-height: 168px;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}

#testanim{
		max-width: 460px;
		max-height: 168px;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 50%;
		left: -5%;
		background:$color-primary;
	}

/*************************header*************************/
header{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 10;
	@include transition(.5s ease-in-out);
	@media (max-width: $sm){
		background: $typography-light;
	}
	&.detail-header,&.blue-header{
		@media (max-width: $sm){
			background: var(--background-color);
		}
		&:before{
			height:100%;
		}
		.wrap{
			> div{
				a{

					&.menu-open{
						color: $color-white;
						i{
							&:before,&:after{
								background:$color-white;
							}
						}
					}
				}
				i{
					&.icon-look{
						color:$color-white;
					}
				}
			}
		}
	}
	&.blue-header{
		@media (max-width: $sm){
			background: $color-primary;
		}
	}
	.wrap{
		position: relative;
		display: table;
		width: 100%;
		padding: 45px 61px 41px 60px;
			@media (max-width:$sm){
				padding: 5px 20px;
			}
		> div{
			width: 50%;
			float: left;
			position: relative;
			z-index: 10;

			a{
				pointer-events: all;
				&.menu-open{
					font-family: $metricmedium;
					@include font-size(20,24);
					color:$typography-dark;
					padding-left: 66px;
					position: relative;
					display: inline-block;
					margin-top: 18px;
					i{
						pointer-events: none;
						width: 50px;
						height: 18px;
						position: absolute;
						top: 50%;
						left: 0;
						margin-top: -9px;
						&:before,&:after{
							content:"";
							position: absolute;
							left: 0;
							width: 100%;
							height: 4px;
							border-radius: 3px;
							background: $typography-dark;
						}
						&:before{
							top: 0;
						}
						&:after{
							bottom: 0;
						}
					}
					span{
						pointer-events: none;
					}
				}
				&.header-logo{
					display: inline-block;
					float: right;
				}
			}
			i{
				&.icon-look{
					font-size: 61px;
					color:$color-primary;
					float: right;
					pointer-events: none;
				}
			}
		}
	}
}
.menu-panel{
		position: fixed;
		width: 100%;
		height: 100%;
		background: $color-primary;
		left: 0;
		bottom: 100%;
		min-height: 600px;
		z-index: 20;
		@media (max-width: $sm){
			min-height: auto;
		}
		> *{
			opacity: 0;
		}
		.wrap{
			position: relative;
			display: table;
			width: 100%;
			padding: 45px 61px 41px 60px;
			@media (max-width: $sm){
				padding: 5px 20px;
			}
			> div{
				width: 50%;
				float: left;
				a{
					&.menu-close{
						display: inline-block;
						pointer-events: all;
					}
					i{
						&.icon-close{
							font-size: 36.53px;
							color:$color-white;
							margin-top: 5px;
							pointer-events: none;
							@media (max-width: $sm){
								margin-top: 15px;
								font-size: 31px;
							}
						}
					}
				}
				i{
					&.icon-look{
						&.icon-look{
							font-size: 61px;
							color:$color-primary;
							float: right;
							&:before{
								color:$color-white;
							}
						}
					}
				}
			}
		}
		nav{
			position: absolute;
			top: 50%;
			@include translate(0,-50%);
			padding-left: 200px;
			@media (max-width: $sm){
				padding-left: 20px;
				width: 100%;
				transform: translate(0,0);
				top: 84px;
			}
			ul{
				display: table;
				@media (max-width: $sm){
					width: 100%;
				}
				li{
					@include transition;
					&:not(:last-child){
						padding-bottom: 36px;
						@media ($media_height_850){
							padding-bottom: 17px !important;
						}
						@media ($media_height_768){
							padding-bottom: 0 !important;
						}
					}
					&:nth-child(1){
						a{
							i{
								&:before{content:'01';}
							}
						}
					}
					&:nth-child(2){
						a{
							&:before{
								/*content:url(#{$images}/menu/what-we-do.png);*/
								content:"";
								position: absolute;
								background:url(#{$images}/menu/what-we-do.png) no-repeat;
								background-size: 100% auto;
								width: 310px;
								height:370px;
								left: 70%;
							}
							i{
								&:before{content:'02';}
							}
						}
					}
					&:nth-child(3){
						a{
							&:before{
								/*content:url(#{$images}/menu/work.png);*/
								content:"";
								position: absolute;
								background:url(#{$images}/menu/work.png) no-repeat;
								background-size: auto 100%;
								width: 350px;
								height:370px;
							}
							i{
								&:before{content:'03';}
							}
						}
					}
					&:nth-child(4){
						a{
							&:before{
								/*content:url(#{$images}/menu/team.png);*/
								content:"";
								position: absolute;
								background:url(#{$images}/menu/team.png) no-repeat;
								background-size: 100% auto;
								width: 370px;
								height:370px;
							}
							i{
								&:before{content:'04';}
							}
						}
					}
					&:nth-child(5){
						a{
							&:before{
								/*content:url(#{$images}/menu/contactt.png);
								top: 100%;
								transform:translate(-20%,-64%);*/
								content:"";
								position: absolute;
								background:url(#{$images}/menu/contact.png) no-repeat;
								background-size: 100% auto;
								width: 400px;
								height:340px;
							}
							i{
								&:before{content:'05';}
							}
						}
					}
					a{
						font-family: $metricmedium;
						@include font-size(70,84);
						@include transition;
						color:$color-white;
						position: relative;
						pointer-events: all;
						> *{
							pointer-events: none;
						}
						@media ($media_height_768){
							@include font-size(60,74);
						}
						@media (max-width: $sm){
							@include font-size(52,62);
							display:block;
						}
						@media (max-width: $extra_xs){
							@include font-size(40,50);
						}
						span{
							position: relative;
							z-index: 2;
						}
						&:before{
							position: absolute;
							left: 100%;
							top: 100%;
							transform: translate(-20%,-50%);
							z-index: -1;
							opacity: 0;
							@include transition_e(0.4s ease-in-out);
							@media (max-width: $sm){
								content:none !important;
							}
						}
						i{
							display: inline-block;
							margin-right: 30px;
							line-height: 0;
							width: 20px;
							height: 20px;
							position: relative;
							overflow: hidden;
							@media (max-width: $sm){
								right: 20px;
								top: 50%;
								margin-top: -10px;
								position: absolute;
								margin-right: 0;
							}
							&:before{
								@include font-size(20,24);
								position: absolute;
								top: 100%;
								@include transition_e(0.4s ease-in-out);
								@media (max-width: $sm){
									top: 0;
								}
							}
						}
						&:hover{
							&:before{
								top:50%;
								opacity: 1;
							}
							i{
								&:before{
									top:0;
								}
							}
						}
					}
				}
			}
		}
		.bottom{
			padding: 0 60px 60px 60px;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			@media (max-width: $sm){
				padding:0 20px 20px 20px;
			}
			> div{
				width: 50%;
				float: left;
				@media (max-width: $sm){
					width: 100%;
					float: none;
				}
				a{
					font-family: $metricmedium;
					@include font-size(20,24);
					color:$color-white;
					@media (max-width: $sm){
						display: table;
						margin:0 auto;
					}
				}
				ul{
					display: table;
					float: right;
					@media (max-width: $sm){
						float: none;
						margin:80px auto 0 auto;
					}
					@media (max-width: $middle_xs){
						margin-top:50px;
					}
					li{
						display: inline-block;
						float: left;
						&:not(:last-child){
							padding-right: 30px;
						}
					}
				}
			}
		}
	}
/*************************header*************************/

/*************************lookPage*************************/
.section{
	@media (max-width: $sm){
		overflow:hidden;
	}
	&:first-child{
		padding-top: 147px;
		@media (max-width:$sm){
			padding-top: 71px;
		}
		.fp-tableCell{
			vertical-align: top;
			padding:0 14px;
			@media (max-width:$sm){
				padding:0;
			}
		}
		.center{
			@media (max-width: $sm){
				/*padding-bottom: 70px;*/
				overflow:hidden;
			}
		}
	}
	&:last-child{
		.fp-tableCell{
			vertical-align: bottom;
		}
	}
	.fp-tableCell{
		@media (max-width: $sm){
			vertical-align: top;
		}
	}
	.center{
		@media (max-width: $sm){
			padding:0 20px;
		}
	}
}
.center{
	@media (max-width: $md){
		max-width: 950px;
	}
	@media (max-width: $sm){
		max-width: 100%;
	}
}
/*************************lookPage*************************/

/*************************main-slider*************************/
.main-slider{
	min-height: 540px;
	position: relative;
	/*padding-top: 32px;*/
	.slide-figure{
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
		display: none;
		@media (max-width:$sm){
			top: 170px;
			right: -50px;
		}
		figure{
			width: 534px;
			height: auto;
			text-align: right;
			@include transition;
			@media ($media_height_768){
				/*height: 400px;*/
	    		width: 400px;
			}
			@media (max-width: $md1100){
				width:400px;
			}
			@media (max-width: $xs){
				width:300px;
			}
			@media (max-width: $extra_xs){
				width:250px;
			}
			img{max-width: 100%;max-height: 550px}
		}
		i{
			&.def-icon-border-bar{
				width: 27px;
				height: 40px;
				background: url(#{$images}/svg/border-bar.svg) no-repeat;
				background-size: cover;
				position: absolute;
			    top: 100%;
			    margin-top: 12px;
			    right: 87px;
			    left:auto !important;
			    top:auto !important;
			}
			&.def-icon-two-border{
				width: 60px;
				height: 55px;
				background: url(#{$images}/svg/two-border.svg) no-repeat;
				background-size: cover;
				position: absolute;
			    top: 100%;
			    margin-top: 84px;
			    right: 171px;
			    left: auto !important;
			    top:auto !important;
			}
			@media (max-width: $sm){
				display:none !important;
			}
		}
	}
	.slide-caption{
		@include transition_e(0.5s ease-in-out);
		@media ($media_height_850){
			padding-top: 10px !important;
		}
		@media (max-width:$sm){
			padding-top:14px !important;
		}
		h1{
			padding-left: 25px;
			font-family: $metricbold;
			color:$typography-dark;
			-webkit-animation-duration: 1s;
		    animation-duration: 1s;
		    -webkit-animation-fill-mode: both;
		    animation-fill-mode: both;
		    opacity: 0;
		    position: relative;
			span{
				display: table;
				position: relative;
				@media (max-width:$middle_xs){
					display: inline-block;
				}
			}
			@media (max-width:$sm){
				@include font-size(52,62);
				padding-left:0;
			}
			@media (max-width: $extra_xs){
				@include font-size(40,45);
			}
		}
		i{
			z-index: -1;
			&.def-icon-cube-one{
				width: 50px;
				height: 35px;
				background:#E7C300;
				position: absolute;
				left: -25px;
				top: 5px;
				opacity: 0.5;
			}
			&.def-icon-cube-two{
				width: 33px;
				height: 42px;
				background:#5AD52C;
				position: absolute;
				bottom: 0;
				right: 0;
				opacity: 0.3;
				@media (max-width: $xs){
					left: 0;
					right: auto;
				}
			}
			&.def-icon-cube-center{
				width: 33px;
				height: 42px;
				background:#5AD52C;
				opacity: 0.5;
				position: absolute;
				bottom: -10px;
				left: 60%;
				margin-left: -16.5px;
				@media (max-width: $xs){
					left: 0;
				}
			}
		}
	}
	.carousel{
		.item{
			&:first-child{
				.slide-caption{
					padding-top:122px;
				}
			}
			&:last-child{
				.slide-caption{
					padding-top:61px;
				}
			}
			&.active{
				.slide-caption{
					h1{
						-webkit-animation-name: fadeIn;
    					animation-name: fadeIn;
					}
				}
			}
		}
	}
}

.ear-figure{
	position: absolute;
	display: table;
	bottom: 77px !important;
	top:auto !important;
	@include transition;
	/*width: 494px;
	height: 310px;*/
	width: 584px;
	height: 328px;
	background: url(#{$images}/content/slide-figure.png) no-repeat;
	background-size: 100%;
	@media (max-width: $md){
		width: 400px;
		height: 228px;
	}
	@media (max-width:$sm){
		height: 180px;
		width: 312px;
	}
	@media (max-width:$middle_xs){
		height:160px;
		height:205px;
	}
	@media ($media_height_850){
		bottom: 20px;
	}
	@media (max-width:$sm){
		top:50%;
		bottom:auto;
		margin-top:-125px;
	}
	@media (max-width:$middle_xs){
		display:none;
		top:auto;
		margin:0;
		bottom:160px;
		left:-70px;
	}
	/*@media (max-width: $extra_xs){
		display:none;
	}*/
}

.fixed-element{
	position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
	.def-icon-zigzag{
		display: none;
		width: 52px;
		height: 233px;
		position: absolute;
		left: 14px;
		bottom: 127px;
		background: url(#{$images}/svg/zigzag-icon.svg) no-repeat center;
		background-size: cover;
		@media ($media_height_768){
			height: 190px;
    		width: 43px;
		}
		@media (max-width:$sm){
			width: 30px;
			height: 132px;
			bottom: 68px !important;
		}
	}
	a.contact-url{
		font-family: $metricmedium;
		@include font-size(20,24);
		color:$typography-middle;
		position: absolute;
		left: 60px;
		bottom: 60px;
		z-index: 9;
		@media (max-width:$sm){
			left: 20px;
			bottom: 30px;
		}
	}
}
.scroll-text{
	font-family: $metricregular;
	color:$typography-dark;
	@include font-size(20,24);
    transform: rotate(270deg);
    display: table;
    position: absolute;
    right: 55px;
    bottom: 93px;
    &:before{
    	content: '';
	    position: absolute;
	    right: 100%;
	    top: 14px;
	    height: 1px;
	    width: 78px;
	    background: $typography-dark;
	    margin-right: 6px;
    }
    @media (max-width: $sm){
    	display:none;
    }
}
/*************************main-slider*************************/

/*************************page-element*************************/
.scroll{
	width: 1px;
	height: 215px;
	background: $typography-dark;
	position: absolute;
	right: 73px;
	bottom: 0;
	opacity: 0;
	span{
		position: absolute;
		width: 100%;
		height: 40px;
		bottom: 80%;
		background: $color-white;
	}
	@media (max-width: $sm){
		display:none;
	}
}

.web-page-name{
	font-family: $metricregular;
	@include font-size(20,24);
	color:$typography-dark;
	display: inline-block;
	position: absolute;
	left: 60px;
	top: 100%;
	opacity: 0;
	transform: rotate(270deg);
    transform: rotate(270deg) translate(-50%, 0);
    transform-origin: 0 0;
    transform: perspective(1px) translateZ(0px) rotate(270deg);
    @media (max-width: $sm){
    	display: none;
    }
}

.page-name{
	font-family: $metricregular;
	@include font-size(20,24);
	color:$typography-dark;
	display: inline-block;
	position: absolute;
	left: 60px;
	top: 50%;
	transform: rotate(270deg);
    transform: rotate(270deg) translate(-50%, 0);
    transform-origin: 0 0;
    transform: perspective(1px) translateZ(0px) rotate(270deg);
    @media (max-width: $sm){
    	position: initial;
	    transform: rotate(0deg);
	    left: 0;
	    width: 100%;
	    top: auto;
	    margin-top: 70px;
	    padding-left: 20px;
	    margin-bottom: 10px;
    }
}
i.def-icon-narrow-plus{
	width: 35px;
	height: 180px;
	background: url(#{$images}/svg/narrow-plus.svg) no-repeat;
	position: absolute;
	right: 72px;
	bottom: 0;
	opacity: 0;
	@media (max-width: $sm){
		display: none;
	}
}
/*************************page-element*************************/

/*************************what-we-do*************************/
.section-what-we-do{
	.page-name{
		display: none;
		@media (max-width: $sm){
			display: block;
		}
	}
}

.what-we-do{
	padding:0 98px;
	position: relative;
	display: table;
	@media (max-width: $sm){
		padding:0;
	}
	.wrap{
		> div{
			&:first-child{
				width:54%;
			}
			&:last-child{
				width: 46%;
			}
			float: left;
			@media (max-width: $sm){
				width:100% !important;
				float:none
			}
		}
		h2{
			font-family: $metricbold;
			color:$typography-dark;
			@media (max-width: $sm){
				@include font-size(38,46);
			}
		}
		p{
			font-family: $metricregular;
			margin:18px 0 33px;
			@include font-size(24,32);
			@media (max-width: $sm){
				@include font-size(18,22);
			}
		}
		a{
			font-family: $metricmedium;
			@include font-size(20,24);
			color:$color-primary;
			@media (max-width: $sm){
				@include font-size(16,19);
			}
		}
		figure{
			position: absolute;
		    right: 0;
		    top: 100%;
		    opacity: 0;
		    transform: translate(0,-50%);
		    width: 600px;
		    height: 440px;
		    background-size: cover !important;
		    @media (max-width: $sm){
		    	top: 100%;
		    	transform: translate(15%,-20%);
		    	z-index: -1;
		    }
		    @media (max-width: $xs){
	    		width:450px;
	    		height:330px;
	    		top:100% !important;
	    	}
	    	@media (max-width: $extra_xs){
	    		top:60% !important;
	    	}
		    &.v1{
		    	background: url(#{$images}/content/strategy.svg) no-repeat center;
		    }
		    &.v2{
		    	background: url(#{$images}/content/design.svg) no-repeat center;
		    }
		    &.v3{
		    	background: url(#{$images}/content/technology.svg) no-repeat center;
		    }
		}
	}
}
/*************************what-we-do*************************/

/*************************get-to-know-us*************************/
.know-us{
	max-width: 946px;
	width: 100%;
	margin:0 auto;
	@media ($media_height_768){
		max-width: 700px;
	}
	@media (max-width: $md){
		max-width: 700px;
	}
	.video-player{
		position: relative;
		@media (max-width: $sm){
			margin-top: 50px;
		}
		i{
			&.def-icon-full-plus{
				position: absolute;
				left: -51px;
				top: 100px;
				width: 201px;
				height: 180px;
				background: url(#{$images}/svg/full-plus.svg) no-repeat;
				background-size: cover;
				opacity: 0;
				z-index: -1;
				@media (max-width: $sm){
					left: -41px;
				}
			}
			&.def-icon-arrow-video{
				position: absolute;
				width: 378px;
				height: 173px;
				right: -47px;
				bottom: -127px;
				background: url(#{$images}/content/video-figure.png) no-repeat;
				background-size: cover;
				z-index: -1;
				@media (max-width: $extra_xs){
			    	display:none;
			    }
			}
		}
		figure{
			height: 543px;
			border-radius: 8px;
			position: relative;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			background-position: center !important;
			top: 200px;
			opacity: 0;
			display: none;
			@media ($media_height_768){
				height: 453px;
			}
			@media (max-width: $md){
				height: 453px;
			}
			@media (max-width: $sm){
				display:block;
			}
			@media (max-width: $middle_xs){
				height: 350px;
			}
			@media (max-width: $extra_xs){
				height: 250px;
			}
			&:before{
				content:'';
				position: absolute;
				width: 82px;
				height: 82px;
				background: $color-white;
				border-radius: 50%;
				left: 50%;
				top: 50%;
				margin-top: -41px;
				margin-left: -41px;
			}
			&:after{
				content:'\e90d';
				position: absolute;
				font-family: 'icomoon';
				font-size: 26px;
				color:$color-primary;
				top: 50%;
				left: 50%;
				margin-left: -10px;
				margin-top: -12px;
				line-height: 1;
			}
		}
	}
	iframe{
		width: 100%;
		height: 543px;
		@media ($media_height_768){
			height: 453px;
		}
		@media (max-width: $md){
			height: 453px;
		}
		@media (max-width: $sm){
			display:none;
		}
		/*@media (max-width: $middle_xs){
			height: 350px;
		}
		@media (max-width: $extra_xs){
			height: 250px;
		}*/
	}
}
/*************************get-to-know-us*************************/

/*************************main-works*************************/
.page-name{
	a.all-url-mobile{
		font-family: $metricmedium;
		@include font-size(20,24);
		color:$color-primary;
		position: absolute;
		right: 20px;
		display: none;
		top: 0;
		@media (max-width: $sm){
			display: block;
		}
		i{
			vertical-align: inherit;
			line-height: 0;
			margin-left: 5px;
			&:before{
				color:$color-primary;
				font-size: 12px;
			}
		}
	}
}
.work-center{
	@media (max-width: $xs){
		padding: 0 !important;
	}
}
.work{
	position: relative;
	@media (max-width: $md1400){
		max-width: 980px;
		margin:0 auto;
	}
	@media (max-width: $md1100){
		max-width: 830px;
	}
	@media ($media_height_768){
		max-width: 830px;
	}
	a.all-project{
		font-family: $metricmedium;
		@include font-size(20,24);
		color:$color-primary;
		position: absolute;
		left: 0;
		bottom: 80px;
		z-index: 1;
		@media (max-width: $sm){
			display: none;
		}
	}
}

.work-slide{
	.wrap{
		@include flex;
		align-items: center;
		@media (max-width: $sm){
			display: block !important;
		}
		> div{
			position: relative;
			width: 58%;
			float: left;
			&.information{
				width: 41%;
				right: 58%;
			}
			&.image{
				width: 58%;
				left: 41%;
				@media (max-width: $sm){
					text-align: center;
				}
			}
			@media (max-width: $sm){
				width:100% !important;
				left:auto !important;
				right:auto !important;
				float:none !important;
			}
		}
	}
	.owl-carousel{
		.owl-stage-outer{
			padding:45px 0 80px 0;
			@media (max-width: $sm){
				padding: 0 0 50px 0;
			}
			@media (max-width: $extra_xs){
		    	padding:0;
		    }
		}
		.owl-stage{
			@media (max-width: $xs){
				padding: 0 0 0 20px !important;
			}
		}
		.owl-nav{
			margin:0;
			max-width: 76px;
			position: absolute;
		    width: 100%;
		    bottom: 3px;
			left: 0;
		    height: 31px;
		    @media (max-width: $sm){
		    	left: 50%;
		    	margin-left: -100px;
		    }
		    @media (max-width: $extra_xs){
		    	display:none;
		    }
			.owl-prev,.owl-next{
				transition:.3s ease-in-out;
			}
			.owl-prev{
				left: 0;
				&:before{
					content:"\e90f";
					font-family: 'icomoon';
					left: 0;
					color:$color-primary;
				}
			}
			.owl-next{
				right: 0;
				&:before{
					content: "\e900";
					font-family: 'icomoon';
					right: 0;
					color:$color-primary;
				}
			}
			// > div{
			// 	margin:0;
			// 	position: absolute;
			// 	width: 78px;
			// 	height: 31px;
			// 	background: none !important;
			// 	@include transition;
			// 	pointer-events: all;
			// 	&:before{
			// 		font-family:'icomoon';
			// 		@include font-size(73,31);
			// 		position: absolute;
			// 		top: 0;
			// 		color: $typography-dark;
			// 		pointer-events: none;
			// 	}
			// 	&.owl-prev{
			// 		left: 0;
			// 		&:before{
			// 			content:"\e90f";
			// 			left: 0;
			// 		}
			// 	}
			// 	&.owl-next{
			// 		right: 0;
			// 		&:before{
			// 			content: "\e900";
			// 			right: 0;
			// 		}
			// 	}
			// }
		}
		.owl-dots {
			counter-reset: slides-num;
		    position: absolute;
		    bottom: 40px;
		    left: 19px;
		    @media (max-width: $sm){
		    	left: 50%;
		    	margin-left: -4px;
		    }
		    @media (max-width: $extra_xs){
		    	display:none;
		    }
	    	&:before{
	    		content:" / ";
	    		position: absolute;
	    		left: 0;
	    		top: 0;
	    		font-family: $metricbold;
	    		@include font-size(20,24);
	    		color:$typography-dark;
	    	}
			&:after {
				content: counter(slides-num);
				display: inline-block;
				@include font-size(20,24);
				font-family: $metricbold;
				color: $typography-dark;
				padding-left: 12px;
			}
		}
		.owl-dot {
	    	display: inline-block;
			counter-increment: slides-num;
			position: absolute;
			span {
				display: none;
			}
			&.active {
				&:before {
					content:  counter(slides-num);
					display: inline-block;
			        position: absolute;
			        font-family: $metricbold;
			        @include font-size(20,24);
			        color: $typography-dark;
			        left: -11px;
			        top: 0;
				}
			}
		}
	}
	.work-information{
		@media (max-width: $sm){
			max-width: 450px;
			margin:0 auto;
		}
		@media (max-width: $middle_xs){
			max-width: 320px;
		}
		@media (max-width: $extra_xs){
			max-width: 240px;
		}
		span{
			display: block;
			font-family: $metricregular;
			color: $typography-dark;
			pointer-events: none;
			@include font-size(24,30);
			opacity: 0.5;
			@media (max-width: $sm){
				@include font-size(18,30);
			}
		}
		h3{
			font-family: $metricbold;
			@include font-size(70,84);
			color:$typography-dark;
			margin-bottom: 20px;
			pointer-events: none;
			@media ($media_height_768){
				@include font-size(50,64);
			}
			@media (max-width: $md1400){
				@include font-size(60,74);
			}
			@media (max-width: $md1100){
				@include font-size(50,64);
			}
			@media (max-width: $sm){
				@include font-size(30,36);
			}
			@media (max-width: $extra_xs){
				@include font-size(20,26);
			}
		}
		a.detail-url{
			font-family: $metricmedium;
			@include font-size(20,24);
			color:$color-primary;
			@media (max-width: $sm){
				display: none;
			}
		}
		a{
			display: inline-block;
			pointer-events: all;
		}
	}
	.banner{
		position: relative;
		height: 653px;
			@media ($media_height_850){
				width: 623px;
				height: 623px;
			}
			@media (max-width: $md1400){
				width:550px;
				height: 550px;
			}
			@media (max-width: $md1100){
				width:450px;
				height: 450px;
			}
			@media ($media_height_768){
				width: 450px;
				height: 450px;
			}
			@media (max-width: $sm){
				display:table;
				margin:0 auto;
				/*width:100%;
				height:100%;*/
			}
			@media (max-width: $xs){
				width: 100%;
				height: auto;
			}
		i{
			&.def-icon-arrow{
				position: absolute;
				left: -43px;
				width: 78px;
				height: 56px;
				background: url(#{$images}/svg/icon-arrow.svg) no-repeat;
				background-size: auto 100%;
				z-index: -1;
				top: 100px;
				opacity: 0;
			}
			&.def-icon-full-plus{
				position: absolute;
				left: -80px;
				bottom: -73px;
				width: 201px;
				height: 180px;
				background: url(#{$images}/svg/full-plus.svg) no-repeat;
				background-size: cover;
				opacity: 1;
				z-index: -1;
			}
			@media (max-width: $sm){
				display:none;
			}
		}
		figure{
			width: 653px;
			height: 653px;
			background-size: cover !important;
			border-radius: 8px;
			position: absolute;
			top: 100px;
			pointer-events: none;
			opacity: 0;
			@media ($media_height_850){
				width: 623px;
				height: 623px;
			}
			@media (max-width: $md1400){
				width: 550px;
				height: 550px;
			}
			@media ($media_height_768){
				width: 450px;
				height: 450px;
			}
			@media (max-width: $md1100){
				width: 450px;
				height: 450px;
			}
			@media (max-width: $sm){
				position:initial;
				/*width:100%;
				height:100%;*/
			}
			@media (max-width: $xs){
				width: 100%;
				height: auto;
			}
			> a{
				display: inline-block;
				pointer-events: all;
			}
			img{
				pointer-events: none;
				@media (max-width: $xs){
					display: block;
				}
			}
		}
		
	}
}
/*************************main-works*************************/

/*************************clients*************************/
.clients{
	overflow: hidden;
	@media (max-width: $md1400){
		max-width: 980px;
		margin:0 auto;
	}
	@media (max-width: $md1100){
		max-width: 830px;
		margin:0 auto;
	}
	h3{
		font-family: $metricbold;
		@include font-size(65,79);
		color: $typography-dark;
		margin-top: 50px;
		opacity: 0;
		@media ($media_height_980){
			@include font-size(65,79);
		}
		@media ($media_height_850){
			@include font-size(55,69);
		}
		@media ($media_height_768){
			@include font-size(45,59);
		}
		@media (max-width: $md1330){
			@include font-size(55,69);
		}
		@media (max-width: $md1100){
			@include font-size(45,59);
		}
		@media (max-width: $sm){
			@include font-size(38,46);
		}
		@media (max-width: $middle_xs){
			@include font-size(28,36);
		}
		@media (max-width: $extra_xs){
			@include font-size(20,28);
		}
		span{
			display: block;
			@media (max-width: $middle_xs){
				display: inline;
			}
		}
	}
	ul{
		margin:55px -15px 17px -15px;
		opacity: 0;
		@media (max-width: $middle_xs){
			margin-bottom: 20px;
			display: table;
		}
		@media (max-width: $extra_xs){
			margin-top:0 !important;
		}
		li{
			width: 25%;
			float: left;
			padding: 0 15px;
			@media (max-width: $middle_xs){
				width: 50%;
				margin-bottom: -20px;
			}
			@media (max-height: 750px){
				margin-bottom: -37px;
			}
			img{
				max-width: 100%;
			}
			&.middle_xs{
				@media (max-width: $middle_xs){
					display: none;
				}
			}
		}
	}
	a.all-button{
		font-family: $metricmedium;
		@include font-size(20,24);
		color:$color-primary;
		display: table;
		margin:0 auto;
		opacity: 0;
	}
}
/*************************clients*************************/

/*************************medium*************************/
.medium{
	width: 100%;
	position: relative;
	max-height: 722px;
	margin-top: 150px;
	/*min-height: 466px;*/
	@media (max-width: $md1400){
		max-width: 980px;
		margin:0 auto;
	}
	@media (max-width: $md1100){
		max-width: 830px;
		margin:0 auto;
	}
	@media (max-width: $sm){
		max-width: 100%;
	}
	@media (max-width: $xs){
		height:500px !important;
	}
	@media (max-width: $extra_xs){
		height:400px !important;
	}
	@media ($media_height_480){
		height:330px !important;
	}
	.inner{
		background:$color-secondry;
		max-height: 722px;
		@include flex;
		align-items: center;
		position: absolute;
		left: 0;
		width: 100%;
		top: 100%;
		min-height: 500px;
		opacity: 0;
		@media (max-width: $xs){
			height: 500px !important;
			min-height: auto !important;
		}
		@media (max-width: $extra_xs){
			height:400px !important;
		}
		@media ($media_height_480){
			height:330px !important;
		}
		ul{
			display: table;
			margin:0 auto;
			padding: 0 15px;
			position: relative;
			z-index: 2;
			@media (max-width:$xs){
				display:none;
			}
			li{
				@include transition;
				position: relative;
				display: table;
				&:first-child{
					margin-bottom:40px;
					@media (max-width: $middle_xs){
						margin-bottom: 40px;
					}
				}
				&:last-child{
					margin-top: 80px;
					@media (max-width: $middle_xs){
						margin-top: 30px;
					}
				}
				span{
					display: block;
					font-family: $metricregular;
					@include font-size(24,30);
					color:$color-white;
					opacity: 0.7;
					position: relative;
					z-index: 1;
				}
				figure{
					width: 452px;
					height: 276px;
					position: absolute;
					right: -7%;
					top: 30%;
					transform: translate(0,-50%);
					background-size: cover !important;
					z-index: 0;
					opacity: 0;
					@media (max-width: $md1100){
						right: -5%;
						width: 330px;
						height: 190px;
					}
					@media (max-width: $xs){
						width: 160px;
						height: 90px;
					}
				}
				a{
					position: relative;
					display: inline-block;
					pointer-events: all;
					z-index: 1;
					h4{
						font-family: $metricbold;
						@include font-size(60,74);
						color:$color-white;
						margin-top: -7px;
						position: relative;
						z-index: 1;
						pointer-events: none;
						@media (max-width: $md){
							@include font-size(60,74);
						}
						@media (max-width: $sm){
							@include font-size(50,64);
						}
						@media (max-width: $xs){
							@include font-size(30,44);
						}
						@media (max-width: $middle_xs){
							@include font-size(25,39);
						}
					}
					&.more-medium{
						font-family: $metricmedium;
						@include font-size(20,24);
						color:$color-primary;
						pointer-events: all;
						i{
							vertical-align: inherit;
							line-height: 0;
							margin-left: 5px;
							pointer-events: none;
							&:before{
								color:$color-primary;
								font-size: 12px;
							}
						}
					}
				}
			}
		}
		.owl-theme{
			display: none;
			@media (max-width: $xs){
				display: block;
			}
			.owl-stage{
				padding-left: 20px !important;
			}
			.wrap{
				figure{
					height: 260px;
					background-size: cover !important;
					background-repeat: no-repeat !important;
					background-position: center !important;
					margin-bottom: 7px;
					@media (max-width: $middle_xs){
						height:171px;
					}
				}
				.title{
					span{
						display: block;
						font-family: $metricregular;
						@include font-size(20,30);
						opacity: 0.7;
						color:$color-white;
					}
					h4{
						font-family: $metricbold;
						@include font-size(38,46);
						color:$color-white;
						@media (max-width: $xs){
							@include font-size(32,40);
						}
						@media (max-width: $extra_xs){
							@include font-size(28,36);
						}
					}
				}
			}
		}
	}
	i{
		&.def-icon-narrow{
		    width: 35px;
		    height: 180px;
		    background: url(#{$images}/svg/narrow-plus.svg) no-repeat;
		    position: absolute;
		    right: 424px;
		    bottom: -90px;
		    transform: rotate(90deg);
		    @media (max-width: $xs){
		    	display: none;
		    }
		}
	}
	> figure{
		width: 494px;
		height: 312px;
		position: absolute;
		right: -49px;
		bottom: -97px;
		background: url(#{$images}/content/slide-figure.png) no-repeat;
    	background-size: 100%;
    	@media (max-width: $xs){
			display: none;
		}
	}
}
/*************************medium*************************/

/*************************team*************************/
.team{
	position: relative;
	@media (max-width: $md1330){
		max-width: 960px;
		margin:0 auto;
	}
	@media (max-width: $md1100){
		max-width: 830px;
	}
	h4{
		font-family: $metricbold;
		@include font-size(70,84);
		color:$typography-dark;
		padding-right: 100px;
		position: relative;
		@media ($media_height_980){
			@include font-size(65,79);
		}
		@media ($media_height_850){
			@include font-size(55,69);
		}
		@media ($media_height_768){
			@include font-size(45,59);
		}
		@media (max-width: $md1330){
			@include font-size(55,69);
			padding-right:0;
		}
		@media (max-width: $md1100){
			@include font-size(45,59);
		}
		@media (max-width: $sm){
			@include font-size(38,46);
		}
		@media (max-width: $middle_xs){
			@include font-size(28,36);
		}
		@media (max-width: $extra_xs){
			@include font-size(20,28);
		}
		i{
			&.def-icon-cube{
				width: 50px;
			    height: 35px;
			    background: #f9df6e;
			    position: absolute;
			    top: 39%;
			    z-index: -1;
			    left: 43%;
			}
		}
	}
	.photo{
		position: relative;
		ul{
			margin-top: 43px;
			display: table;
			width: 100%;
			position: relative;
			@media ($media_height_768){
				margin-top: 25px;
			}
			@media (max-width: $sm){
				margin-top:25px;
			}
			li{
				float: left;
				display: inline-block;
				text-align: center;
				@media (max-width: $md1330){
					width: 33.3333%;
				}
				@media (max-width: $sm){
					width: 50%;
				}
				&:not(:last-child){
					padding-right: 28px;
				}
				&:nth-child(2){
					figure{
						img{
							max-height: 320px;
							@media (max-width: $md1330){
								max-height: 270px;
							}
						}
					}
				}
				&:last-child{
					position: absolute;
					bottom: 0;
					@media (max-width: $sm){
						bottom: -30%;
						left: 55%;
					}
				}
				&:first-child{
					@media (max-width: $sm){
						padding-top: 44px;
					}
				}
				i{
					&.def-icon-border{
						width: 18px;
						height: 46px;
						background: #50f1bb;
						position: absolute;
						left: -10px;
						top:-19px;
						z-index: -1;
						opacity: 0;
						@media (max-width: $sm){
							top: 20px;
						}
					}
				}
				figure{
					img{
						max-width: 100%;
						@media ($media_height_980){
							max-height: 400px;
						}
						@media ($media_height_850){
							max-height: 370px;
						}
						@media (max-width: $sm){
							max-width:350px;
						}
						@media (max-width: $xs){
							max-height:220px;
							max-width:190px;
						}
						@media (max-width: $middle_xs){
							max-width:150px;
						}
						@media (max-width: $extra_xs){
							max-width:120px;
						}
					}
				}
			}
		}
		i{
			&.def-icon-arrow-up{
				width: 270px;
			    height: 420px;
			    background: url(#{$images}/content/team-arrow-up.png) no-repeat center;
			    background-size: cover;
			    position: absolute;
			    right: 0;
			    bottom: 0;
			    @media (max-width: $sm){
			    	display: none;
			    }
			}
			&.def-icon-fly{
				width: 291px;
				height: 251px;
				position: absolute;
				right: -150px;
				top: -21px;
				background: url(#{$images}/content/team-icon.png) no-repeat center;
				background-size: cover !important;
				top: 10%;
				opacity: 0;
				@media ($media_height_980){
					top: -71px;
				}
				@media (max-width: $sm){
					width: 185px;
				    height: 195px;
				    position: absolute;
				    top: 80%;
				    left: 35%;
				    right: auto;
				    transform: rotate(28deg);
				}
				@media (max-width: $xs){
				    left: 95px;
				    width: 150px;
				    height: 165px;
				}
				@media (max-width: $extra_xs){
				    left: 65px;
				}
			}
		}
	}
	a.team-all-button{
		font-family: $metricmedium;
		@include font-size(20,24);
		color: $color-primary;
		display: table;
		margin:60px auto 0 auto;
		@media ($media_height_850){
			margin-top: 30px;
		}
		@media (max-width:$sm){
			display:none;
		}
	}
}
/*************************team*************************/

/*************************footer*************************/
.contact{
	background: $color-primary;
	padding:96px 0 0 0;
	position: relative;
	&.main-contact{
		position: absolute;
		bottom: -100%;
		width: 100%;
	}
	@media ($media_height_850){
		padding-top: 50px;
	}
	@media ($media_height_768){
		padding-top:40px;
	}
	@media (max-width: $sm){
		padding-top:0;
	}
	&:before{
		content:"";
		position: absolute;
		left: 0;
		width: 100%;
		height: 110px;
		background: $color-black;
		opacity: 0.1;
		bottom:0;
		@media ($media_height_768){
			height:90px;
		}
		@media (max-width: $sm){
			content: none;
		}
	}
	footer{
		@media (max-width: $sm){
			display: none;
		}
	}
	@include font-size(20,24);
	.page-top{
		display: inline-block;
		width: 67px;
		height: 67px;
		background: $color-white;
		border-radius: 6px;
		position: absolute;
		right: 0;
		bottom: 0;
		@include transition;
		@media (max-width: $sm){
			width: 47px;
			height: 47px;
			bottom: auto;
			top: -25px;
			right: 20px;
		}
		&:before{
			content:"\e900";
			font-family: 'icomoon';
			color:$typography-dark;
			font-size: 58px;
			transform: rotate(270deg);
			position: absolute;
			left: 50%;
			margin-left: -28px;
			bottom: 0;
			@include transition;
			@media (max-width: $sm){
				font-size: 38px;
				margin-left: -19px;
				bottom: -5px;
			}
		}
		&:hover{
			&:before{
				bottom:10px;
			}
		}
	}
	.footer-top{
		h6{
			font-family: $metricmedium;
			color:$color-white;
			@include font-size(20,24);
			margin-bottom: 17px;
		}
		nav{
			ul{
				li{
					&:not(:last-child){
						margin-bottom: 9px;
					}
					a{
						font-family: $metricregular;
						color:$color-white;
					}
				}
			}
		}
		address{
			font-family: $metricregular;
			color:$color-white;
			opacity: 0.7;
			margin-top: -2px;
			a{
				display: block;
				margin-top: 25px;
				color: $color-white;
			}
		}
		.email{
			margin-top: 36px;
			h6{
				margin-bottom: 0;
			}
			a{
				color:$color-white;
			}
		}
		a.f-contact-us{
			pointer-events: all;
			display: inline-block;
			float: right;
			> *{pointer-events: none;}
			h5{
				font-family: $metricbold;
				@include font-size(70,84);
				color:$color-white;
				margin-top: -12px;
				float: right;
				margin-right: 30px;
				span{
					display: block;
				}
				i{
					@include font-size(58,1);
					margin-top: -60px;
				}
			}
		}
	}
	.footer-middle{
		position: relative;
		margin-top: 174px;
		@media ($media_height_850){
			margin-top: 134px;
		}
		@media ($media_height_768){
			margin-top:100px;
		}
		ul.social-media{
			display: table;
			li{
				float: left;
				display: inline-block;
				&:not(:last-child){
					margin-right: 26px;
				}
				a{
					pointer-events: all;
					display: block;
					i{
						pointer-events: none;
						@include font-size(16,1);
						&:before{
							color:$color-white;
						}
						&.icon-behance{
							font-size: 2rem;
						}
					}
				}
			}
		}
	}
	.footer-bottom{
		position: relative;
		padding: 43px 0;
		margin-top: 68px;
		width: 100%;
		display: table;
		@media ($media_height_768){
			margin-top:45px;
			padding:33px 0;
		}
		div{
			width: 50%;
			float: left;
		}
		p{
			color:$color-white;
			opacity: 0.7;
		}
		ul{
			display: table;
			float: right;
			li{
				display: inline-block;
				float: left;
				color: $color-white;
				font-family: $metricregular;
				position: relative;
				&:nth-child(2){
					opacity: 0.7;
					padding-left: 13px;
					&:before{
						content:"";
						position: absolute;
						left: 5px;
						top: 6px;
						width: 2px;
						height: 15px;
						background: $color-white;
						opacity: 0.7;
					}
				}
				a{
					color: $color-white;
				}
			}
		}
	}
	.mobile-footer{
		padding-top:30px;
		display: none;
		@media (max-width: $sm){
			display: block;
		}
		@media (max-width: $extra_xs){
			padding-top:15px;
		}
		address{
			margin-bottom: 40px;
			text-align: center;
			@media (max-width: $extra_xs){
				margin-bottom: 15px;
			}
			h6{
				@include font-size(20,24);
				font-family: $metricmedium;
				margin-bottom: 10px;
				color:$color-white;
				@media (max-width: $extra_xs){
					@include font-size(18,22);
				}
			}
			p{
				font-family: $metricregular;
				color:$color-white;
				opacity: 0.7;
				@include font-size(20,24);
				@media (max-width: $extra_xs){
					@include font-size(18,22);
				}
			}
			a{
				font-family: $metricregular;
				@include font-size(20,24);
				color:$color-white;
				opacity: 0.7;
				display: inline-block;
				margin-top: 20px;
				@media (max-width: $extra_xs){
					@include font-size(18,22);
					margin-top: 15px;
				}
			}
		}
		ul.social-media{
			display: table;
			margin:0 auto;
			li{
				float: left;
				display: inline-block;
				&:not(:last-child){
					margin-right: 26px;
					@media (max-width: $extra_xs){
						margin-right: 15px;
					}
				}
				a{
					display: block;
					i{
						@include font-size(19,1);
						&:before{
							color:$color-white;
						}
						&.icon-behance{
							font-size: 2rem;
						}
					}
				}
			}
		}
		a{
			&.contact-url{
				font-family: $metricbold;
				@include font-size(30,36);
				color:$color-white;
				display: table;
				margin:57px auto 46px auto;
				@media (max-width: $extra_xs){
					margin:15px auto;
				}
				i{
					&.icon-arrow-right{
						@include font-size(58,1);
						margin-left: 10px;
					}
				}
			}
		}
		.email-bar{
			text-align: center;
			h6{
				font-family: $metricmedium;
				@include font-size(20,24);
				color:$color-white;
			}
			a{
				color:$color-white;
				font-family: $metricregular;
				display: inline-block;
			}
		}
		.mobile-footer-bottom{
			margin-top: 30px;
			padding:24px 0;
			@media (max-width: $extra_xs){
				margin-top: 10px;
				padding:15px 0;
			}
			&:before{
				content:"";
				position: absolute;
				left: 0;
				bottom: 0;
				background: $color-black;
				opacity: 0.1;
				height: 110px;
				width: 100%;
				@media (max-width: $extra_xs){
					height: 70px;
				}
			}
			p{
				font-family: $metricregular;
				@include font-size(18,22);
				color: $color-white;
				opacity: 0.7;
				text-align: center;
				margin-bottom: 17px;
				@media (max-width: $extra_xs){
					@include font-size(15,18);
					margin-bottom: 9px;
				}
			}
			ul{
				display: table;
				margin:0 auto;
				li{
					display: inline-block;
					float: left;
					color: $color-white;
					font-family: $metricregular;
					position: relative;
					@include font-size(18,22);
					@media (max-width: $extra_xs){
						@include font-size(15,18);
					}
					&:nth-child(2){
						opacity: 0.7;
						padding-left: 13px;
						&:before{
							content:"";
							position: absolute;
							left: 5px;
							top: 4px;
							width: 2px;
							height: 15px;
							background: $color-white;
							opacity: 0.7;
						}
					}
					a{
						color: $color-white;
					}
				}
			}
		}
	}
}
/*************************footer*************************/

/*************************wrapper*************************/
.wrapper{
	overflow: hidden;
}
/*************************wrapper*************************/

/*************************project-detail*************************/
.wrapper{
	.top-banner{
		background: $color-primary;
		position: relative;
		.this-name{
			font-family: $metricregular;
			@include font-size(20,24);
			color: $color-white;
			position: absolute;
			left: 65px;
			bottom: 87px;
			transform: rotate(270deg);
		    transform: rotate(270deg) translate(-50%, 0);
		    transform-origin: 0 0;
		    transform: perspective(1px) translateZ(0px) rotate(270deg);
		    position: fixed;
		    top: 560px;
		    @include transition;
		    @media (max-width: $md1150){
		    	opacity: 0;
		    }
		    @media (max-width: $sm){
		    	opacity:1;
				transform: rotate(0deg);
			    transform: rotate(0deg) translate(-50%, 0);
			    transform-origin: 0 0;
			    transform: perspective(1px) translateZ(0px) rotate(0deg);
			    position: initial;
			    padding-left: 20px;
			}
			&.colored{
				color:$typography-dark;
				@media (max-width: $sm){
					color:$color-white;
				}
			}
		}
	}
	.project-detail{
		.top-banner{
			height: 650px;
			padding-top: 147px;
			background: var(--background-color);
			position: relative;
			overflow:hidden;
			@media (max-width: $sm){
				padding-top: 98px;
				height: auto;
			}
			.title{
				width: 66.66%;
				padding-top: 52px;
				@media (max-width: $md1700){
					width: 50%;
				}
				@media (max-width: $sm){
					padding-top: 29px;
					width: 100%;
				}
				span{
					display: block;
					font-family: $metricregular;
					@include font-size(24,30);
					color: $color-white;
					opacity: 0.7;
					margin-bottom: 8px;
					@media (max-width: $sm){
						@include font-size(18,36);
						margin-bottom: -3px;
					}
				}
				h1{
					color:$color-white;
					font-family: $metricbold;
					@media (max-width: $md1400){
						@include font-size(50,64);
					}
				}
			}
			figure{
				position: absolute;
				right: 0;
				bottom: 0px;
				@include transition;
				@media (max-width: $md1920){
					right: -90px;
				}
				@media (max-width: $md){
					right: -130px;
				}
				@media (max-width: $sm){
					position: initial;
				}
				img{
					max-height: 568px;
					max-width: 100%;
					@media (max-width: $md1700){
						max-height:568px;
					}
					@media (max-width: $md){
						max-height:468px;
					}
					@media (max-width: $sm){
						max-height:100%;
						max-width:100%;
					}
				}
			}
		}
		.summary{
			padding:46px 0 21px 0;
			background: $color-white;
			@media (max-width: $md){
				padding:30px 0 21px 0;
			}
			@media (max-width: $sm){
				padding:32px 0 40px 0;
			}
			.row{
				margin:0;
				div{
					float: left;
					@media (max-width: $sm){
						width: 100% !important;
						margin-bottom: 21px;
					}
					&:nth-child(1),&:nth-child(2){
						width:25%;
					}
					&:nth-child(3){
						width: 33%;
						@media (max-width: $sm){
							margin-bottom: 33px;
						}
					}
					&:nth-child(4){
						width: 17%;
						
					}
					&:last-child{
						@media (max-width: $sm){
							margin-bottom: 0;
						}
					}
				}
			}
			span{
				font-family: $metricmedium;
				@include font-size(20,24);
				color: $typography-dark;
				margin-bottom: 11px;
				display: block;
				@media (max-width: $sm){
					margin-bottom: 3px;
				}
			}
			p{
				font-family: $metricregular;
				@include font-size(20,24);
				color: $typography-dark;
				opacity: 0.7;
			}
			a{
				font-family: $metricmedium;
				@include font-size(20,24);
				color:$color-primary;
				float: right;
				margin-top: 15px;
				@media (max-width: $sm){
					float: none;
				}
				i{
					vertical-align: inherit;
					line-height: 0;
					margin-left: 3px;
					font-weight: bold;
					&:before{
						color:$color-primary;
						font-size: 12px;
					}
				}
			}
		}
		.cuff{
			padding: 81px 0 108px 0;
			@media (max-width: $sm){
				padding:44px 0 86px 0;
			}
			article{
				max-width: 751px;
				margin:0 auto;
				h2{
					font-family: $metricbold;
					color:$typography-dark;
					margin-bottom: 14px;
					@media (max-width: $sm){
						margin-bottom: 17px;
						@include font-size(38,46);
					}
				}
				h4{
					font-family: $metricmedium;
				}
				p{
					font-family: $metricregular;
					color: $typography-dark;
					@include font-size(20,24);
				}
			}
			.cuff-banner{
				position: relative;
				margin:56px 0 55px 0;
				i{
					&.def-icon-full-plus{
						position: absolute;
						left: -53.5px;
						top: -47.5px;
						width: 201px;
						height: 180px;
						background: url(#{$images}/svg/full-plus.svg) no-repeat;
						background-size: cover;
						z-index: -1;
						@media (max-width: $sm){
							top: -13.5px;
							left: -12.5px;
						}
					}
				}
				img{
					max-width: 100%;
					width: 100%;
					&.web-image{
						@media (max-width: $xs){
							display: none;
						}
					}
					&.mobile-image{
						display: none;
						@media (max-width: $xs){
							display: block;
						}
					}
				}
			}
		}
		.user-experience{
			padding:64px 0 97px 0;
			overflow: hidden;
			.center{
				@media (max-width: $sm){
					padding:0;
				}
			}
			.wrap{
				background: var(--background-color);
				height: 543px;
				border-radius: 6px;
				@include flex;
				align-items: center;
				position: relative;
				padding:0 5px;
				@media (max-width: $sm){
					height: auto;
					display: block;
					padding:98px 0 130px 0;
					border-radius: 0;
				}
				&:before{
					content:"";
					position: absolute;
					left: -240px;
					bottom: 13px;
					width: 357px;
					height: 183px;
					background: url(#{$images}/content/user-interface-before-image.png) no-repeat;
					background-size: cover;
					@media (max-width: $md){
						left: -200px;
					}
					@media (max-width: $sm){
						width:225px;
						height:110px;
						background-size:auto 100%;
						left:-81px;
						bottom:5px;
					}
				}
				div{
					&.experience-icons{
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
					}
				}
				i{
					&.def-icon-full-plus{
						position: absolute !important;
						left: auto !important;
						right: -73.5px !important;
						top: -64.5px !important;
						width: 201px;
						height: 180px;
						background: url(#{$images}/svg/full-plus.svg) no-repeat;
						background-size: cover;
						@media (max-width: $sm){
							right: -93.5px !important;
						}
					}
					&.def-icon-fly{
						width: 200px;
						height: 212px;
						position: absolute !important;
						background: url(#{$images}/content/icon-fly.png) no-repeat;
						background-size: 100%;
						left: 58px !important;
						bottom: -97px !important;
						top:auto !important;
						@media (max-width: $md){
							left: 98px !important;
						}
						@media (max-width: $sm){
						    width: 110px;
						    height: 127px;
						    bottom: -61px !important;
						}
					}
				}
				.row{
					margin:-15px auto;
					max-width: 780px;
					> div{
						float: left;
						width: 50%;
						padding:0 15px;
						@media (max-width: $sm){
							float: none;
							width: 100%;
						}
						&:first-child{
							@media (max-width: $sm){
								margin-bottom: 25px;
							}
						}
						&:last-child{
							@media (max-width: $sm){
								margin-top: 25px;
							}
						}
					}
				}
				section{
					text-align: center;
					h2{
						font-family: $metricbold;
						color:$color-white;
						margin-bottom: 18px;
						@media (max-width: $sm){
							margin-bottom: 14px;
						}
					}
					p{
						font-family: $metricregular;
						@include font-size(20,26);
						color: $color-white;
					}
				}
			}
		}
		.wireframe,.user-interface{
			padding:99px 0 0 0;
			position: relative;
			@media (max-width: $sm){
				padding-top: 63px;
			}
			.row{
				margin:0 -15px;
				> div{
					float: left;
					padding:0 15px;
					@media (max-width: $sm){
						width: 100% !important;
					}
					&:first-child{
						width: 33.33333333%;
					}
					&:last-child{
						width: 66.66666667%;
						float: right;
					}
				}
			}
			.highlight{
				position: relative;
				section{
					h3,p{color:$color-white}
				}
			}
			.fix-title,.highlight-fix-title{
				position: absolute;
				top: 99px;
				left: 0;
				width: 100%;
				z-index: 2;
				&.fixed{
					position: fixed !important;
					@media (max-width: $sm){
						position: relative !important;
						top: 0;
					}
				}
				@media (max-width: $sm){
					position:relative;
					top: 0;
				}
			}
			section{
				@media (max-width: $sm){
					margin-bottom: 47px;
				}
				h3,p{
					@include transition_e(.3s ease-in-out);
				}
				h3{
					font-family: $metricbold;
					color:$typography-dark;
					margin-bottom: 8px;
					@media (max-width: $sm){
						@include font-size(30,36);
						margin-bottom: 14px;
					}
				}
				p{
					font-family: $metricregular;
					font-weight: 300;
					@include font-size(20,24);
					color: $typography-dark;
				}
			}
			.image-list{
				/*padding-left: 62px;*/
				margin-top: 8px;
				position: relative;
				z-index: 1;
				@media (max-width: $sm){
					padding-left: 0;
					margin-top: 0;
				}
				figure{
					margin-bottom: 53px;
					@media (max-width: $sm){
						margin-bottom: 37px;
					}
					img{
						max-width: 100%;
					}
				}
			}
		}
		.user-interface{
			padding:0;
			margin-bottom: 126px;
			@media (max-width: $sm){
				padding-top: 49px;
				margin-bottom: 118px;
			}
			&:before,&:after{
				content:"";
				position: absolute;
				width: 100%;
				@include transition_e(.5s ease-in-out);
			}
			&:before{
				left: 0;
				top: -421px;
				height:421px;
				z-index: -1;
			}
			/*&:after{
				left: 0;
				bottom: -1px;
				height: 217px;
				background: $typography-light;
				z-index: 0;
				@media (max-width: $sm){
					height: 101px;
				}
			}*/
			.bg{
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				z-index: -1;
				@include transition_e(.5s ease-in-out);
			}
			&.color{
				.bg{
					opacity: 1;
					background: var(--background-color);
					&:before{
						background: var(--background-color);
					}
				}
				.fix-title{
					section > *{
						color:$color-white !important;
					}
				}
			}
			section{
				margin-bottom: 24px;
			}
			.image-list{
				figure{
					position: relative;
					&:nth-child(3){
						&:before{
							content:"";
							position: absolute;
							left: -160px;
							bottom: -69px;
							width: 201px;
							height: 180px;
							background: url(#{$images}/svg/full-plus.svg) no-repeat;
							background-size: cover;
							z-index: -1;
							@media (max-width: $sm){
								left: -12px;
								bottom: auto;
								top: -59px;
							}
						}
					}
				}
			}
		}
		.customer-reviews{
			margin-bottom: 224px;
			@media (max-width: $sm){
				margin-bottom: 90px;
			}
		}
		.other-projects{
			margin-bottom: 218px;
			overflow: hidden;
			padding-bottom: 70px;
			@media (max-width: $sm){
				margin-bottom: 114px;
			}
			.top{
				margin-bottom: 38px;
				position: relative;
				@media (max-width: $sm){
					margin-bottom: 20px;
				}
				h3{
					font-family: $metricbold;
					color:$typography-dark;
					@media (max-width: $sm){
						@include font-size(38,46);
					}
					span{
						@media (max-width: $sm){
							display: block;
						}
					}
				}
				a{
					display: inline-block;
					font-family: $metricmedium;
					@include font-size(20,24);
					color:$color-primary;
					position: absolute;
					right: 0;
					bottom: 6px;
					@media (max-width: $sm){
						bottom: 4px;
					}
				}
			}
			.owl-carousel{
				.owl-stage-outer{
					overflow: visible;
					.owl-stage{
						padding-left: 0 !important;
					}
				}
				.owl-item{
					opacity: 0.5;
					@include transition;
					&.active{
						opacity: 1;
					}
				}
				.owl-nav{
					margin:0;
					max-width: 200px;
					position: absolute;
				    width: 100%;
				    bottom: -69px;
				    height: 31px;
				    @media (max-width: $sm){
				    	left: 50%;
				    	margin-left: -100px;
				    }
					> div{
						margin:0;
						position: absolute;
						width: 78px;
						height: 31px;
						background: none !important;
						@include transition;
						&:before{
							font-family:'icomoon';
							@include font-size(73,31);
							position: absolute;
							top: 0;
							color: $typography-dark;
						}
						&.owl-prev{
							left: 0;
							&:before{
								content:"\e90f";
								left: 0;
							}
						}
						&.owl-next{
							right: 0;
							&:before{
								content: "\e900";
								right: 0;
							}
						}
					}
				}
				.owl-dots {
					counter-reset: slides-num;
				    position: absolute;
				    bottom: -66px;
				    left: 95px;
				    @media (max-width: $sm){
				    	left: 50%;
				    	margin-left: -4px;
				    }
			    	&:before{
			    		content:" / ";
			    		position: absolute;
			    		left: 0;
			    		top: 0;
			    		font-family: $metricbold;
			    		@include font-size(20,24);
			    		color:$typography-dark;
			    	}
					&:after {
						content: counter(slides-num);
						display: inline-block;
						@include font-size(20,24);
						font-family: $metricbold;
						color: $typography-dark;
						padding-left: 12px;
					}
				}
				.owl-dot {
			    	display: inline-block;
					counter-increment: slides-num;
					span {
						display: none;
					}
					&.active {
						&:before {
							content:  counter(slides-num);
							display: inline-block;
					        position: absolute;
					        font-family: $metricbold;
					        @include font-size(20,24);
					        color: $typography-dark;
					        left: -11px;
					        top: 0;
						}
					}
				}
				.wrap{
					a{
						pointer-events: all;
						> *{
							pointer-events: none;
						}
					}
					figure{
						border-radius: 6px;
						margin-bottom: 31px;
						@media (max-width: $sm){
							margin-bottom: 20px;
						}
					}
					.information{
						span{
							display: block;
							font-family: $metricregular;
							@include font-size(20,30);
							color: $typography-dark;
							opacity: 0.5;
							@media (max-width: $sm){
								@include font-size(18,30);
							}
						}
						p{
							font-family: $metricbold;
							@include font-size(30,36);
							color:$typography-dark;
							margin-top: -3px;
						}
					}
				}
			}
		}
	}
}
/*************************project-detail*************************/

/*************************customer-reviews*************************/
.customer-reviews{
	.wrap{
		text-align: center;
		max-width: 943px;
		margin:0 auto;
		width: 100%;
		h2{
			font-family: $metricbold;
			color:$typography-dark;
			@media (max-width: $sm){
				@include font-size(38,46);
			}
		}
		.customer-profile{
			margin:53px 0 29px 0;
			@media (max-width: $sm){
				margin:43px 0 33px 0;
			}
			figure{
				height: 128px;
				text-align: center;
				margin-bottom: 7px;
				img{
					max-height: 100%;
					width: auto;
					display: inline-block !important;
				}
			}
			h6{
				font-family: $metricmedium;
				@include font-size(20,24);
				color:$typography-dark;
			}
			p{
				font-family: $metricregular;
				color:$typography-middle;
				margin-top: -3px;
			}
		}
		section{
			max-width: 750px;
			margin:0 auto;
			p{
				font-family: $metricregular;
				font-weight: 300;
				@include font-size(20,24);
				color:$typography-dark;
			}
		}
	}
	}
/*************************customer-reviews*************************/



/*************************work*************************/
.wrapper{
	.common-banner{
		background: $color-primary;
		position: relative;
		height: 650px;
		/*overflow:hidden;*/
		@media (max-width: $sm){
			height: auto;
			padding: 98px 0 70px 0;
		}
		.center{
			position: relative;
			/*padding-top: 237px;*/
			height: 100%;
			@media (max-width: $sm){
				padding-top:0;
			}
			.subpage-title{
				position: relative;
				height: 100%;
				@include flex;
				align-items: center;
				@media (max-width: $sm){
					display: block;
				}
				h1{
					font-family: $metricbold;
					color:$color-white;
					position: relative;
					z-index: 2;
					span{
						display: block;
						@media (max-width: $middle_xs){
							display: inline;
						}
					}
				}
			}
		}
		.this-name{
			font-family: $metricregular;
			@include font-size(20,24);
			color: $color-white;
			position: absolute;
			left: 65px;
			bottom: 87px;
			transform: rotate(270deg);
		    transform: rotate(270deg) translate(-50%, 0);
		    transform-origin: 0 0;
		    transform: perspective(1px) translateZ(0px) rotate(270deg);
		    @media (max-width: $sm){
				transform: rotate(0deg);
			    transform: rotate(0deg) translate(-50%, 0);
			    transform-origin: 0 0;
			    transform: perspective(1px) translateZ(0px) rotate(0deg);
			    position: initial;
			    padding-left: 20px;
			}
		}
	}
	.work-page{
		.work-slide{
			margin: 99px 0 100px 0;
			@media (max-width: $sm){
				margin-bottom: 40px;
			}
			.center{
				@media (max-width: $md1400){
					max-width: 1050px;
				}
				@media (max-width: $md1100){
					max-width: 905px;
				}
				@media (max-width: $xs){
					padding:0;
				}
			}
			.information{
				width: 48%;
				right: 50%;
			}
			.banner{
				figure{
					opacity: 1;
					top: 0;
				}
			}
		}
		.brands{
			padding-top: 65px;
			margin-bottom: 158px;
			.top-title{
				position: relative;
				padding-right: 224px;
				margin-bottom: 49px;
				@media (max-width: $sm){
					padding-right: 0;
					margin-bottom: -60px;
				}
				h2{
					font-family: $metricbold;
					@include font-size(70,84);
					color:$typography-dark;
					@media (max-width: $md){
				    	@include font-size(50,64)
				    }
				    @media (max-width: $sm){
				    	@include font-size(38,46)
				    }
				}
				figure{
					position: absolute;
					right: -88px;
					top: -50px;
					@media (max-width: $md1100){
				    	right: 0;
				    }
				    @media (max-width: $sm){
						position:relative;
						z-index:-1;
					}
					img{
						max-width: 260px;
					}
				}
			}
			ul{
				margin:0 -15px;
				display: table;
				@media (max-width: $sm){
			    	margin:0;
			    }
				li{
					float: left;
					width: 25%;
					padding: 0 15px;
					@media (max-width: $sm){
				    	width: 33%;
				    	padding:0;
				    }
				    @media (max-width: $xs){
				    	width: 50%;
				    }
					img{
						max-width: 100%;
					}
				}
			}
		}
	}
}
/*************************work*************************/

/*************************what-we-do*************************/
.wrapper{
	.content{
		margin-top: 129px;
		@media (max-width: $sm){
			margin-top: 90px;
		}
		.center{
			> section{
				color:$typography-dark;
				&:not(:last-child){
					margin-bottom: 170px;
					@media (max-width: $sm){
						margin-bottom: 60px;
					}
				}
				&:last-child{
					margin-bottom: 147px;
					@media (max-width: $sm){
						margin-bottom: 60px;
					}
				}
				> .row{
					+ .row{
						margin-top: 49px;
					}
					> div{
						float: left;
						min-height: 1px;
						padding:0 15px;
						@media (max-width: $sm){
							width: 100% !important;
						}
						&:first-child{
							width:20%;
						}
						&:last-child{
							width:80%;
						}
					}
					.row{
						> div{
							float: left;
							width: 50%;
							padding: 0 15px;
							@media (max-width: $xs){
								width: 100% !important;
							}
						}
					}
				}
				h4{
					font-family: $metricmedium;
					margin-top: 30px;
				}
				h2{
					font-family: $metricbold;
					margin-bottom: 18px;
					padding-right: 70px;
					@media (max-width: $sm){
						padding-right: 0;
						@include font-size(38,46);
					}
				}
				h6{
					font-family: $metricmedium;
					@include font-size(20,24);
					@media (max-width: $sm){
						margin-bottom: 9px;
					}
				}
				p{
					font-family: $metricthin;
					@include font-size(20,28);
				}
				ul{
					li{
						font-family: $metricmedium;
						@include font-size(20,34);
						position: relative;
						padding-left: 40px;
						@media (max-width: $sm){
							@include font-size(18,26);
							margin-bottom: 5px;
						}
						&:before{
							content:"";
							position: absolute;
							left: 0;
							top: 16px;
							height: 2px;
							background: $typography-dark;
							width: 16px;
						}
					}
				}
			}
		}
	}
	.open-positions-page{
		.content{
			margin-top:0;
			.center{
				> section{
					margin:0 !important;
					padding-top: 170px;
					@media (max-width: $sm){
						padding-top: 100px;
					}
					&:last-child{
						margin-bottom: 147px !important;
					}
					.row{
						.row{
							> div{
								width: 100%;
								float: none;
							}
						}
					}
					h4{
						margin-top: 0;
					}
					a{
						font-family: $metricmedium;
						@include font-size(20,24);
						color:$color-primary;
						display: inline-block;
						margin-top: 88px;
						@media (max-width: $sm){
							margin-top: 30px;
						}
					}
				}
			}
		}
	}
}

/*direction-bar*/
.direction-bar{
	background: $color-primary;
	height: 380px;
	border-radius: 6px;
	margin-bottom: 165px;
	position: relative;
	&.team-bar{
		margin-bottom: 130px;
	}
	h2{
		font-family: $metricbold;
		color:$color-white;
		/*max-width: 652px;*/
		margin:0 auto;
		text-align: center;
		position: relative;
		@media (max-width: $sm){
			@include font-size(38,46);
		}
		@media (max-width: $xs){
			@include font-size(30,41);
		}
		&:before{
			content:"\e900";
			position: absolute;
			left: 50%;
			bottom: -71px;
			font-family: 'icomoon';
			@include font-size(58,31);
			color:$color-white;
			margin-left: -29px;
			@include transition;
			@media (max-width: $sm){
				bottom: -50px;
			}
		}
		span{
			display: block;
			@media (max-width: $middle_xs){
				display: inline;
			}
		}
	}
	a{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		@include flex;
		align-items: center;
		padding: 0 30px;
		pointer-events: all;
		@media (max-width: $middle_xs){
			padding:0 15px;
		}
		> *{
			pointer-events: none;
		}
		&:hover{
			h2{
				&:before{
					margin-left:-15px;
				}
			}
		}
	}
	.icons{
		width: 100%;
		height: 100%;
		i{
			&.def-icon-full-plus{
				position: absolute !important;
				left: -79.5px !important;
				bottom: -51.5px !important;
				top:auto !important;
				width: 201px;
				height: 180px;
				background: url(#{$images}/svg/full-plus.svg) no-repeat;
				background-size: cover;
				z-index: -1;
				@media (max-width: $sm){
					left: -70px !important;
				}
				@media (max-width: $xs){
					width:150px;
					height:170px;
				}
			}
			&.def-icon-image{
				width: 287px;
				height: 321px;
				background: url(#{$images}/content/lets-build-icon.png) no-repeat;
				background-size: cover;
				position: absolute;
				left: -67px !important;
				bottom: -112px;
				top:auto !important;
				@media (max-width: $xs){
					width: 190px;
					height: 250px;
					left: 0 !important;
				}
				@media (max-width: $extra_xs){
					width:170px;
					height:200px;
				}
			}
			/*&.def-icon-word-tv{
				width: 230px;
				height: 290px;
				background: url(#{$images}/content/word-tv.png) no-repeat;
				background-size: cover;
				position: absolute !important;
				bottom: -131px !important;
				left: -43px !important;
				top:auto !important;
				@media (max-width: $sm){
					left: 0 !important;
					width: 190px;
					height: 250px;
				}
			}
			&.icon-figure-3{
				@include font-size(162,73);
				color:$color-primary;
				position: absolute !important;
				bottom: -116px !important;
				left: -64px !important;
				top: auto !important;
				z-index: -1;
				@media (max-width: $sm){
					left: 0 !important;
				}
			}
			&.def-icon-bullet{
				width: 9px;
				height: 58px;
				background: url(#{$images}/svg/bullet.svg) no-repeat;
				background-size: cover;
				position: absolute !important;
				top:100% !important;
				margin-top: 132px;
				left: -7px !important;
			}*/
		}
	}
	i{
		&.def-icon-full-plus{
			position: absolute;
			left: -51px;
			top: 10.5px;
			width: 201px;
			height: 180px;
			background: url(#{$images}/svg/full-plus.svg) no-repeat;
			background-size: cover;
			z-index: -1;
		}
		&.def-icon-world-icon{
			width: 295px;
			height: 243px;
			background: url(#{$images}/content/team-world-icon.png) no-repeat;
			position: absolute !important;
			top: -118px !important;
			right: -45px !important;
			left:auto !important;
			background-size: cover;
		}
	}
}
/*direction-bar*/

.what-we-do-page{
	.customer-reviews{
		margin-bottom: 145px;
		.owl-carousel{
			padding-bottom: 102px;
			.owl-nav{
				margin:0;
				max-width: 200px;
				position: absolute;
			    width: 100%;
			    bottom: 0;
			    height: 31px;
			    left: 50%;
			    margin-left: -100px;
			    @media (max-width: $sm){
			    	left: 50%;
			    	margin-left: -100px;
			    }
				> div{
					margin:0;
					position: absolute;
					width: 78px;
					height: 31px;
					background: none !important;
					@include transition;
					&:before{
						font-family:'icomoon';
						@include font-size(73,31);
						position: absolute;
						top: 0;
						color: $typography-dark;
					}
					&.owl-prev{
						left: 0;
						&:before{
							content:"\e90f";
							left: 0;
						}
					}
					&.owl-next{
						right: 0;
						&:before{
							content: "\e900";
							right: 0;
						}
					}
				}
			}
			.owl-dots {
				counter-reset: slides-num;
			    position: absolute;
			    bottom: 4px;
			    left: 50%;
			    margin-left: -5px;
			    @media (max-width: $sm){
			    	left: 50%;
			    	margin-left: -4px;
			    }
		    	&:before{
		    		content:" / ";
		    		position: absolute;
		    		left: 0;
		    		top: 0;
		    		font-family: $metricbold;
		    		@include font-size(20,24);
		    		color:$typography-dark;
		    	}
				&:after {
					content: counter(slides-num);
					display: inline-block;
					@include font-size(20,24);
					font-family: $metricbold;
					color: $typography-dark;
				}
			}
			.owl-dot {
		    	display: inline-block;
				counter-increment: slides-num;
		    	margin-right: 5px;
				span {
					display: none;
				}
				&.active{
					&:before {
						content:  counter(slides-num);
						display: inline-block;
				        position: absolute;
				        font-family: $metricbold;
				        @include font-size(20,24);
				        color: $typography-dark;
				        left: -11px;
				        top: 0;
					}
				}
			}
		}
	}
}
/*************************what-we-do*************************/

/*************************team*************************/
.team-page{
	.video-player{
		position: relative;
		margin:82px auto 106px auto;
		max-width: 946px;
		@media (max-width: $sm){
			margin-bottom: 60px;
		}
		i{
			&.def-icon-full-plus{
				position: absolute;
				left: -51px;
				top: -42px;
				width: 201px;
				height: 180px;
				background: url(#{$images}/svg/full-plus.svg) no-repeat;
				background-size: cover;
				@media (max-width: $sm){
					left: -41px;
				}
			}
		}
		figure{
			height: 551px;
			border-radius: 8px;
			position: relative;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			background-position: center !important;
			@media (max-width: $sm){
				height: 400px;
			}
			@media (max-width: $xs){
				height: 350px;
			}
			@media (max-width: $middle_xs){
				height: 300px;
			}
			/*&:before{
				content:'';
				position: absolute;
				width: 82px;
				height: 82px;
				background: $color-white;
				border-radius: 50%;
				left: 50%;
				top: 50%;
				margin-top: -41px;
				margin-left: -41px;
			}
			&:after{
				content:'\e90d';
				position: absolute;
				font-family: 'icomoon';
				font-size: 26px;
				color:$color-primary;
				top: 50%;
				left: 50%;
				margin-left: -10px;
				margin-top: -12px;
				line-height: 1;
			}*/
		}
	}
	.team-list{
		max-width: 1082px;
		margin:0 auto 125px auto;
		ul{
			display: table;
			width: 100%;
			li{
				float: left;
				display: inline-block;
				position: relative;
				@media (max-width: $middle_xs){
					float: none;
					display: block;
					text-align: center;
				}
				&:not(.disable){
					&:after{
						content:"";
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background: $typography-dark;
						display: inline-block;
						margin:0 6px;
						vertical-align: super;
						@media (max-width: $sm){
							width: 8px;
							height: 8px;
						}
						@media (max-width: $middle_xs){
							content:none;
						}
					}
				}
				&:nth-child(1){
					&:after{
						background:$color-secondry;
					}
				}
				&:nth-child(7){
					&:after{
						background:#E80127;
					}
				}
				&:nth-child(9){
					&:after{
						background:#E7C300;
					}
				}
				&:nth-child(6){
					&:after{
						background:$color-primary;
					}
				}
				&:nth-child(16){
					&:after{
						background:#E7C300;
					}
				}
				&:nth-child(17){
					&:after{
						background:#E80127;
					}
				}
				&:nth-child(21){
					&:after{
						background:$color-secondry;
					}
				}
				&:nth-child(24){
					&:after{
						background:$color-secondry;
					}
				}
				/*&:nth-child(3n+2){background: red}*/
				a{
					font-family: $metricbold;
					@include font-size(45,54);
					color:$typography-dark;
					@media (max-width: $md){
						@include font-size(40,49);
					}
					@media (max-width: $sm){
						@include font-size(37,46);
					}
					@media (max-width: $xs){
						@include font-size(34,43);
					}
					@media (max-width: $middle_xs){
						@include font-size(20,29);
					}
				}
			}
		}
	}
}
/*************************team*************************/

/*************************contact*************************/
.contact-page{
	.contact-information{
		margin:125px auto 103px auto;
		max-width: 1000px;
		@media (max-width: $xs){
			margin:60px 0 30px 0;
		}
		.row{
			> div{
				width: 50%;
				float: left;
				padding:0 15px;
				@media (max-width: $xs){
					width: 100%;
					float: none;
				}
			}
		}
		table{
			tr{
				td{
					font-family: $metricregular;
					@include font-size(24,29);
					color:$typography-dark;
					opacity: 0.7;
					padding-bottom:30px; 
					vertical-align: top;
					padding-right: 45px;
					&:first-child{
						font-family:$metricbold;
						opacity: 1;
						padding-right: 60px;
					}
					@media (max-width: $xs){
						display:block;
						padding-bottom:10px;
					}
					&.url-list{
						@media (max-width: $xs){
							padding-bottom: 0;
						}
					}
					a{
						color:$typography-middle;
						font-family: $metricmedium;
						&.email{
							color:$color-primary;
							font-family: $metricregular;
						}
					}
				}
			}
		}
	}
	.map-image{
		margin-bottom: 140px;
		@media (max-width: $sm){
			margin-bottom: 70px;
		}
		.row{
			> div{
				width: 50%;
				float: left;
				padding:0 15px;
				@media (max-width: $xs){
					width: 100%;
					float: none;
					padding:15px;
				}
			}
		}
		.map{
			position: relative;
			> a{
				position: absolute;
				right: 10px;
				top: 10px;
				z-index: 9;
				font-family: $metricmedium;
				@include font-size(20,24);
				color:$color-primary;
				background:$color-white;
				padding:10px 20px;
				border-radius: 6px;
				display: inline-block;
			}
		}
		#map,figure{
			width: 100%;
			height: 451px;
			background-size: cover !important;
			@media (max-width: $md){
				height: 390px;
			}
			@media (max-width: $sm){
				height: 320px;
			}
			@media (max-width: $xs){
				height: 300px;
			}
			@media (max-width: $extra_xs){
				height: 280px;
			}
		}
	}
}
/*************************contact*************************/


/*************************common-banner-pages*************************/
.work-page{
	.common-banner{
		figure{
			max-width: 406px;
			width: 100%;
			position: absolute;
			right: -85px;
			bottom: -53px;
		}
	}
}
.open-positions-page{
	.common-banner{
		figure{
			max-width: 465px;
			width: 100%;
			position: absolute;
			right: -124px;
			bottom: -81px;
			z-index: 1;
		}
		i{
			&.def-icon-full-plus{
				position: absolute;
				right: -63px;
				bottom: -112px;
				width: 201px;
				height: 180px;
				background: url(#{$images}/svg/full-plus.svg) no-repeat;
				background-size: cover;
			}
		}
	}
}
.what-we-do-page{
	.common-banner{
		figure{
			max-width: 350px;
			width: 100%;
			position: absolute;
			right: -101px;
			bottom: -43px;
			z-index: 0;
		}
		i{
			&.def-icon-full-plus{
				position: absolute;
				right: -49px;
				bottom: -136px;
				width: 201px;
				height: 180px;
				background: url(#{$images}/svg/full-plus.svg) no-repeat;
				background-size: cover;
			}
		}
	}
}
.team-page{
	.common-banner{
		@media (max-width: $sm){
			padding-bottom: 0;
		}
		figure{
			max-width: 353px;
			width: 100%;
			position: absolute;
			right: -85px;
			bottom: 0;
		}
	}
}
.contact-page{
	.common-banner{
		@media (max-width: $sm){
			padding-bottom: 0;
		}
		figure{
			max-width: 469px;
			width: 100%;
			position: absolute;
			right: -61px;
			bottom: -43px;
		}
	}
}
.wrapper{
	.common-banner{
		figure{
			@media (max-width: $sm){
				position: initial;
				margin-top: 15px;
				display: table;
			}
			@media (max-width: $xs){
				max-width:100%;
			}
			img{
				max-width: 100%;
			}
			i{
				@media (max-width: $sm){
					left: -38px;
					height: 114px;
					bottom: -120px;
				}
			}
		}
	}
}
/*************************common-banner-pages*************************/


/*************************cursor*************************/
#cursor{
	position:fixed;
	text-align:center;
	width:80px;
	height:80px;
	z-index:1000;
	-webkit-transform-origin:center center;
	transform-origin:center center;
	pointer-events:none;
}
@media (max-width:768.98px){
	#cursor{display:none}
}
#cursor .cursor__bg{
	-webkit-transition:transform .2s ease-in-out;
	-webkit-transition:-webkit-transform .2s ease-in-out;
	transition:-webkit-transform .2s ease-in-out;
	transition:transform .2s ease-in-out;
	transition:transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden;
	display:block;
	left:0;
	top:0;
	mix-blend-mode:exclusion;
	background:$color-primary;
	width:80px;
	height:80px;
	border-radius:80px;
	-webkit-transform:scale(.15);
	transform:scale(.15)
}
#cursor .cursor__label{
	display:block;
	width:80px;
	height:80px;
	line-height:80px;
	font-size:1.1em;
	font-weight:600;
	letter-spacing:.14384615em;
	text-transform:uppercase
}
#cursor .cursor__label,{
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate3d(-50%,-50%,0);
	transform:translate3d(-50%,-50%,0);z-index:1
}
#cursor.is-medium .cursor__bg{
	-webkit-transform:scale(.475);
	transform:scale(.475)
}
#cursor.is-big .cursor__bg{
	-webkit-transform:scale(0.8);
	transform:scale(0.8);
	opacity:0.7;
}
[data-cursor-type],[data-cursor-type]:hover{
	cursor:none!important
}
html.msie #cursor{
	display:none!important
}
/*a{pointer-events: all;
	*{pointer-events: none;}
}*/

.owl-carousel {
  -ms-touch-action: pan-y !important;
  touch-action: pan-y !important;
}
/*************************cursor*************************/