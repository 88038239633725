[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\e90f";
}
.icon-arrow-right:before {
  content: "\e900";
}
.icon-arrow-top-right:before {
  content: "\e901";
}
.icon-behance:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-dribbble:before {
  content: "\e904";
}
.icon-facebook:before {
  content: "\e905";
}
.icon-figure-1:before {
  content: "\e906";
}
.icon-figure-2:before {
  content: "\e907";
}
.icon-figure-3:before {
  content: "\e908";
}
.icon-instagram:before {
  content: "\e909";
}
.icon-linkedin:before {
  content: "\e90a";
}
.icon-look:before {
  content: "\e90b";
}
.icon-medium:before {
  content: "\e90c";
}
.icon-play-button:before {
  content: "\e90d";
}
.icon-twitter:before {
  content: "\e90e";
}