//............................................................
// = SETTINGS
//............................................................

/*-------------------------------page-selection-------------------------------*/
::selection {
  background: $color-primary;
  color: $color-white;
}
/*-------------------------------page-selection-------------------------------*/

/*-------------------------------navbar-media-------------------------------*/
@media (max-width: 991px) {
	header{height: auto;min-width: 320px;
		.navbar-header{float: none}
		.navbar-toggle{display: block}
		.navbar-collapse{border-top: 1px solid transparent;box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);max-width: 100% !important;
			&.collapse{display: none !important;overflow-y: auto !important}
		}
		.navbar-nav {float: none !important;margin: 0;
			> li {float: none;
				> a {padding-top: 10px;padding-bottom: 10px}
			}
		}
		.navbar-text{float: none;margin: 15px 0}
		.navbar-collapse{
			&.collapse.in{display: block !important}
		}
		.collapsing{overflow: hidden !important}
	}
}
.navbar-inverse{border: none;border-radius: 0;background: none;margin:0;
	button.navbar-toggle{margin: 0}
	li.active{
		a{background: none !important}
	}
}
/*-------------------------------navbar-media-------------------------------*/

/*-------------------------------carousel-fade-------------------------------*/
.carousel-fade {
    .carousel-inner {
        .item{transition-property: opacity;}
        .item,
        .active.left,
        .active.right {opacity: 0}
        .active,
        .next.left,
        .prev.right {opacity: 1}
        .next,
        .prev,
        .active.left,
        .active.right {left: 0;transform: translate3d(0, 0, 0)}
    }
    .carousel-control {
        z-index: 2;
    }
}
/*-------------------------------carousel-fade-------------------------------*/

/*-------------------------------figure-bg-class-------------------------------*/
.back{background-repeat: no-repeat !important;background-position: center center !important;background-size: cover !important}
/*-------------------------------figure-bg-class-------------------------------*/

/*-------------------------------center-------------------------------*/
.center{max-width:1180px;padding: 0 20px;width: 100%;margin:0 auto;
  @media (max-width: $md1400){
    max-width: 1050px;
  }
}
/*-------------------------------center-------------------------------*/

/*-------------------------------transition-------------------------------*/
@mixin translate($translate...){
  transform: translate($translate);
  -webkit-transform: translate($translate);
}

@mixin transition_e($erd...) {
  -webkit-transition: $erd;
  -moz-transition: $erd;
  -ms-transition: $erd;
  -o-transition: $erd;
  transition: $erd;
}

@mixin transition($erd...) {
  -webkit-transition: .3s ease-in-out;
  -moz-transition: .3s ease-in-out;
  -ms-transition: .3s ease-in-out;
  -o-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

@mixin flex{
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  display: flex;
}

// Font sizes
@mixin font-size($font-size, $line-height: normal) {
  font-size: #{$font-size / 10}rem;
  @if ($line-height == normal) {
    $line-height: map-get($lineHeights, $font-size);
  }
  line-height: #{$line-height / 10}rem;
}
/*-------------------------------transition-------------------------------*/

/*-------------------------------styleguide-------------------------------*/
.hero{
  font-family: $metricbold;
  @include font-size(80,96);
}

h1{
  @include font-size(70,84)
    @media (max-width: $md){
      @include font-size(50,64)
    }
    @media (max-width: $sm){
      @include font-size(38,46)
    }
}
h2{
  @include font-size(56,67)
    /*@media (max-width: $sm){
      @include font-size(38,46)
    }*/
}
h3{@include font-size(44,53)}
h4{
  @include font-size(24,29)
    @media (max-width: $sm){
      @include font-size(20,24)
    }
}
h5{@include font-size(20,16)}

p{
  @include font-size(16,19);
}
.tagline{@include font-size(20,16)}
.notes{@include font-size(14,17)}
/*-------------------------------styleguide-------------------------------*/

/*-------------------------------noise-------------------------------*/
@keyframes noiseAnimation {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0); }
  10% {
    -webkit-transform: translate(-5%, -5%);
    transform: translate(-5%, -5%); }
  20% {
    -webkit-transform: translate(-10%, 5%);
    transform: translate(-10%, 5%); }
  30% {
    -webkit-transform: translate(5%, -10%);
    transform: translate(5%, -10%); }
  40% {
    -webkit-transform: translate(-5%, 15%);
    transform: translate(-5%, 15%); }
  50% {
    -webkit-transform: translate(-10%, 5%);
    transform: translate(-10%, 5%); }
  60% {
    -webkit-transform: translate(15%);
    transform: translate(15%); }
  70% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%); }
  80% {
    -webkit-transform: translate(-15%);
    transform: translate(-15%); }
  90% {
    -webkit-transform: translate(10%, 5%);
    transform: translate(10%, 5%); }
  to {
    -webkit-transform: translate(5%);
    transform: translate(5%); }
}
/*-------------------------------noise-------------------------------*/
