//............................................................
// = RESET
//............................................................

html{min-height: 100%;min-width: 100%;}
body{width: 100%;height: 100%;padding: 0 !important;min-width: 100%;background: $typography-light;}

/*reset erd*/
h1,
h2,
h3,
h4,
h5,
h6,
th,
p,
label,
address,
figure{font-weight: normal;margin: 0}

a{text-decoration: none !important;}

ul,
ol{list-style: none;padding: 0;margin: 0;}

input,
select,
textarea,
button,
a{outline: none !important;border: none;-webkit-appearance: none;-moz-appearance: none;appearance: none;border-radius:0}

i{display: inline-block;vertical-align: middle;font-style: normal;}

nav{background: none;border: none;margin: 0;min-height: auto !important;
	button.navbar-toggle{background: none !important;border: none !important}
	ul{
		li{
			a{padding: 0 !important}
		}
	}
}

.navbar-collapse{border: none !important;box-shadow: 0 0 0 0 !important;padding: 0}

.navbar-brand{padding: 0;height: auto}

.dropdown{
	 > button{box-shadow: 0 0 0 0 !important}
}

.owl-carousel{touch-action: none}
/*reset erd*/