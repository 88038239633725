/*Lib*/
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background-color: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

h1 {
  margin: .67em 0;
  font-size: 2em; }

mark {
  color: #000;
  background: #ff0; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  line-height: normal; }

input[type=checkbox], input[type=radio] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  height: auto; }

input[type=search] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  padding: .35em .625em .75em;
  margin: 0 2px;
  border: 1px solid silver; }

legend {
  padding: 0;
  border: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: 700; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

td, th {
  padding: 0; }

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *, :after, :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  a, a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  blockquote, pre {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img, tr {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  h2, h3, p {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .btn > .caret, .dropup > .btn > .caret {
    border-top-color: #000 !important; }
  .label {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
  .table td, .table th {
    background-color: #fff !important; }
  .table-bordered td, .table-bordered th {
    border: 1px solid #ddd !important; } }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url(../fonts/glyphicons-halflings-regular.eot);
  src: url(../fonts/glyphicons-halflings-regular.eot?#iefix) format("embedded-opentype"), url(../fonts/glyphicons-halflings-regular.woff2) format("woff2"), url(../fonts/glyphicons-halflings-regular.woff) format("woff"), url(../fonts/glyphicons-halflings-regular.ttf) format("truetype"), url(../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular) format("svg"); }

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.glyphicon-asterisk:before {
  content: "\002a"; }

.glyphicon-plus:before {
  content: "\002b"; }

.glyphicon-eur:before, .glyphicon-euro:before {
  content: "\20ac"; }

.glyphicon-minus:before {
  content: "\2212"; }

.glyphicon-cloud:before {
  content: "\2601"; }

.glyphicon-envelope:before {
  content: "\2709"; }

.glyphicon-pencil:before {
  content: "\270f"; }

.glyphicon-glass:before {
  content: "\e001"; }

.glyphicon-music:before {
  content: "\e002"; }

.glyphicon-search:before {
  content: "\e003"; }

.glyphicon-heart:before {
  content: "\e005"; }

.glyphicon-star:before {
  content: "\e006"; }

.glyphicon-star-empty:before {
  content: "\e007"; }

.glyphicon-user:before {
  content: "\e008"; }

.glyphicon-film:before {
  content: "\e009"; }

.glyphicon-th-large:before {
  content: "\e010"; }

.glyphicon-th:before {
  content: "\e011"; }

.glyphicon-th-list:before {
  content: "\e012"; }

.glyphicon-ok:before {
  content: "\e013"; }

.glyphicon-remove:before {
  content: "\e014"; }

.glyphicon-zoom-in:before {
  content: "\e015"; }

.glyphicon-zoom-out:before {
  content: "\e016"; }

.glyphicon-off:before {
  content: "\e017"; }

.glyphicon-signal:before {
  content: "\e018"; }

.glyphicon-cog:before {
  content: "\e019"; }

.glyphicon-trash:before {
  content: "\e020"; }

.glyphicon-home:before {
  content: "\e021"; }

.glyphicon-file:before {
  content: "\e022"; }

.glyphicon-time:before {
  content: "\e023"; }

.glyphicon-road:before {
  content: "\e024"; }

.glyphicon-download-alt:before {
  content: "\e025"; }

.glyphicon-download:before {
  content: "\e026"; }

.glyphicon-upload:before {
  content: "\e027"; }

.glyphicon-inbox:before {
  content: "\e028"; }

.glyphicon-play-circle:before {
  content: "\e029"; }

.glyphicon-repeat:before {
  content: "\e030"; }

.glyphicon-refresh:before {
  content: "\e031"; }

.glyphicon-list-alt:before {
  content: "\e032"; }

.glyphicon-lock:before {
  content: "\e033"; }

.glyphicon-flag:before {
  content: "\e034"; }

.glyphicon-headphones:before {
  content: "\e035"; }

.glyphicon-volume-off:before {
  content: "\e036"; }

.glyphicon-volume-down:before {
  content: "\e037"; }

.glyphicon-volume-up:before {
  content: "\e038"; }

.glyphicon-qrcode:before {
  content: "\e039"; }

.glyphicon-barcode:before {
  content: "\e040"; }

.glyphicon-tag:before {
  content: "\e041"; }

.glyphicon-tags:before {
  content: "\e042"; }

.glyphicon-book:before {
  content: "\e043"; }

.glyphicon-bookmark:before {
  content: "\e044"; }

.glyphicon-print:before {
  content: "\e045"; }

.glyphicon-camera:before {
  content: "\e046"; }

.glyphicon-font:before {
  content: "\e047"; }

.glyphicon-bold:before {
  content: "\e048"; }

.glyphicon-italic:before {
  content: "\e049"; }

.glyphicon-text-height:before {
  content: "\e050"; }

.glyphicon-text-width:before {
  content: "\e051"; }

.glyphicon-align-left:before {
  content: "\e052"; }

.glyphicon-align-center:before {
  content: "\e053"; }

.glyphicon-align-right:before {
  content: "\e054"; }

.glyphicon-align-justify:before {
  content: "\e055"; }

.glyphicon-list:before {
  content: "\e056"; }

.glyphicon-indent-left:before {
  content: "\e057"; }

.glyphicon-indent-right:before {
  content: "\e058"; }

.glyphicon-facetime-video:before {
  content: "\e059"; }

.glyphicon-picture:before {
  content: "\e060"; }

.glyphicon-map-marker:before {
  content: "\e062"; }

.glyphicon-adjust:before {
  content: "\e063"; }

.glyphicon-tint:before {
  content: "\e064"; }

.glyphicon-edit:before {
  content: "\e065"; }

.glyphicon-share:before {
  content: "\e066"; }

.glyphicon-check:before {
  content: "\e067"; }

.glyphicon-move:before {
  content: "\e068"; }

.glyphicon-step-backward:before {
  content: "\e069"; }

.glyphicon-fast-backward:before {
  content: "\e070"; }

.glyphicon-backward:before {
  content: "\e071"; }

.glyphicon-play:before {
  content: "\e072"; }

.glyphicon-pause:before {
  content: "\e073"; }

.glyphicon-stop:before {
  content: "\e074"; }

.glyphicon-forward:before {
  content: "\e075"; }

.glyphicon-fast-forward:before {
  content: "\e076"; }

.glyphicon-step-forward:before {
  content: "\e077"; }

.glyphicon-eject:before {
  content: "\e078"; }

.glyphicon-chevron-left:before {
  content: "\e079"; }

.glyphicon-chevron-right:before {
  content: "\e080"; }

.glyphicon-plus-sign:before {
  content: "\e081"; }

.glyphicon-minus-sign:before {
  content: "\e082"; }

.glyphicon-remove-sign:before {
  content: "\e083"; }

.glyphicon-ok-sign:before {
  content: "\e084"; }

.glyphicon-question-sign:before {
  content: "\e085"; }

.glyphicon-info-sign:before {
  content: "\e086"; }

.glyphicon-screenshot:before {
  content: "\e087"; }

.glyphicon-remove-circle:before {
  content: "\e088"; }

.glyphicon-ok-circle:before {
  content: "\e089"; }

.glyphicon-ban-circle:before {
  content: "\e090"; }

.glyphicon-arrow-left:before {
  content: "\e091"; }

.glyphicon-arrow-right:before {
  content: "\e092"; }

.glyphicon-arrow-up:before {
  content: "\e093"; }

.glyphicon-arrow-down:before {
  content: "\e094"; }

.glyphicon-share-alt:before {
  content: "\e095"; }

.glyphicon-resize-full:before {
  content: "\e096"; }

.glyphicon-resize-small:before {
  content: "\e097"; }

.glyphicon-exclamation-sign:before {
  content: "\e101"; }

.glyphicon-gift:before {
  content: "\e102"; }

.glyphicon-leaf:before {
  content: "\e103"; }

.glyphicon-fire:before {
  content: "\e104"; }

.glyphicon-eye-open:before {
  content: "\e105"; }

.glyphicon-eye-close:before {
  content: "\e106"; }

.glyphicon-warning-sign:before {
  content: "\e107"; }

.glyphicon-plane:before {
  content: "\e108"; }

.glyphicon-calendar:before {
  content: "\e109"; }

.glyphicon-random:before {
  content: "\e110"; }

.glyphicon-comment:before {
  content: "\e111"; }

.glyphicon-magnet:before {
  content: "\e112"; }

.glyphicon-chevron-up:before {
  content: "\e113"; }

.glyphicon-chevron-down:before {
  content: "\e114"; }

.glyphicon-retweet:before {
  content: "\e115"; }

.glyphicon-shopping-cart:before {
  content: "\e116"; }

.glyphicon-folder-close:before {
  content: "\e117"; }

.glyphicon-folder-open:before {
  content: "\e118"; }

.glyphicon-resize-vertical:before {
  content: "\e119"; }

.glyphicon-resize-horizontal:before {
  content: "\e120"; }

.glyphicon-hdd:before {
  content: "\e121"; }

.glyphicon-bullhorn:before {
  content: "\e122"; }

.glyphicon-bell:before {
  content: "\e123"; }

.glyphicon-certificate:before {
  content: "\e124"; }

.glyphicon-thumbs-up:before {
  content: "\e125"; }

.glyphicon-thumbs-down:before {
  content: "\e126"; }

.glyphicon-hand-right:before {
  content: "\e127"; }

.glyphicon-hand-left:before {
  content: "\e128"; }

.glyphicon-hand-up:before {
  content: "\e129"; }

.glyphicon-hand-down:before {
  content: "\e130"; }

.glyphicon-circle-arrow-right:before {
  content: "\e131"; }

.glyphicon-circle-arrow-left:before {
  content: "\e132"; }

.glyphicon-circle-arrow-up:before {
  content: "\e133"; }

.glyphicon-circle-arrow-down:before {
  content: "\e134"; }

.glyphicon-globe:before {
  content: "\e135"; }

.glyphicon-wrench:before {
  content: "\e136"; }

.glyphicon-tasks:before {
  content: "\e137"; }

.glyphicon-filter:before {
  content: "\e138"; }

.glyphicon-briefcase:before {
  content: "\e139"; }

.glyphicon-fullscreen:before {
  content: "\e140"; }

.glyphicon-dashboard:before {
  content: "\e141"; }

.glyphicon-paperclip:before {
  content: "\e142"; }

.glyphicon-heart-empty:before {
  content: "\e143"; }

.glyphicon-link:before {
  content: "\e144"; }

.glyphicon-phone:before {
  content: "\e145"; }

.glyphicon-pushpin:before {
  content: "\e146"; }

.glyphicon-usd:before {
  content: "\e148"; }

.glyphicon-gbp:before {
  content: "\e149"; }

.glyphicon-sort:before {
  content: "\e150"; }

.glyphicon-sort-by-alphabet:before {
  content: "\e151"; }

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152"; }

.glyphicon-sort-by-order:before {
  content: "\e153"; }

.glyphicon-sort-by-order-alt:before {
  content: "\e154"; }

.glyphicon-sort-by-attributes:before {
  content: "\e155"; }

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156"; }

.glyphicon-unchecked:before {
  content: "\e157"; }

.glyphicon-expand:before {
  content: "\e158"; }

.glyphicon-collapse-down:before {
  content: "\e159"; }

.glyphicon-collapse-up:before {
  content: "\e160"; }

.glyphicon-log-in:before {
  content: "\e161"; }

.glyphicon-flash:before {
  content: "\e162"; }

.glyphicon-log-out:before {
  content: "\e163"; }

.glyphicon-new-window:before {
  content: "\e164"; }

.glyphicon-record:before {
  content: "\e165"; }

.glyphicon-save:before {
  content: "\e166"; }

.glyphicon-open:before {
  content: "\e167"; }

.glyphicon-saved:before {
  content: "\e168"; }

.glyphicon-import:before {
  content: "\e169"; }

.glyphicon-export:before {
  content: "\e170"; }

.glyphicon-send:before {
  content: "\e171"; }

.glyphicon-floppy-disk:before {
  content: "\e172"; }

.glyphicon-floppy-saved:before {
  content: "\e173"; }

.glyphicon-floppy-remove:before {
  content: "\e174"; }

.glyphicon-floppy-save:before {
  content: "\e175"; }

.glyphicon-floppy-open:before {
  content: "\e176"; }

.glyphicon-credit-card:before {
  content: "\e177"; }

.glyphicon-transfer:before {
  content: "\e178"; }

.glyphicon-cutlery:before {
  content: "\e179"; }

.glyphicon-header:before {
  content: "\e180"; }

.glyphicon-compressed:before {
  content: "\e181"; }

.glyphicon-earphone:before {
  content: "\e182"; }

.glyphicon-phone-alt:before {
  content: "\e183"; }

.glyphicon-tower:before {
  content: "\e184"; }

.glyphicon-stats:before {
  content: "\e185"; }

.glyphicon-sd-video:before {
  content: "\e186"; }

.glyphicon-hd-video:before {
  content: "\e187"; }

.glyphicon-subtitles:before {
  content: "\e188"; }

.glyphicon-sound-stereo:before {
  content: "\e189"; }

.glyphicon-sound-dolby:before {
  content: "\e190"; }

.glyphicon-sound-5-1:before {
  content: "\e191"; }

.glyphicon-sound-6-1:before {
  content: "\e192"; }

.glyphicon-sound-7-1:before {
  content: "\e193"; }

.glyphicon-copyright-mark:before {
  content: "\e194"; }

.glyphicon-registration-mark:before {
  content: "\e195"; }

.glyphicon-cloud-download:before {
  content: "\e197"; }

.glyphicon-cloud-upload:before {
  content: "\e198"; }

.glyphicon-tree-conifer:before {
  content: "\e199"; }

.glyphicon-tree-deciduous:before {
  content: "\e200"; }

.glyphicon-cd:before {
  content: "\e201"; }

.glyphicon-save-file:before {
  content: "\e202"; }

.glyphicon-open-file:before {
  content: "\e203"; }

.glyphicon-level-up:before {
  content: "\e204"; }

.glyphicon-copy:before {
  content: "\e205"; }

.glyphicon-paste:before {
  content: "\e206"; }

.glyphicon-alert:before {
  content: "\e209"; }

.glyphicon-equalizer:before {
  content: "\e210"; }

.glyphicon-king:before {
  content: "\e211"; }

.glyphicon-queen:before {
  content: "\e212"; }

.glyphicon-pawn:before {
  content: "\e213"; }

.glyphicon-bishop:before {
  content: "\e214"; }

.glyphicon-knight:before {
  content: "\e215"; }

.glyphicon-baby-formula:before {
  content: "\e216"; }

.glyphicon-tent:before {
  content: "\26fa"; }

.glyphicon-blackboard:before {
  content: "\e218"; }

.glyphicon-bed:before {
  content: "\e219"; }

.glyphicon-apple:before {
  content: "\f8ff"; }

.glyphicon-erase:before {
  content: "\e221"; }

.glyphicon-hourglass:before {
  content: "\231b"; }

.glyphicon-lamp:before {
  content: "\e223"; }

.glyphicon-duplicate:before {
  content: "\e224"; }

.glyphicon-piggy-bank:before {
  content: "\e225"; }

.glyphicon-scissors:before {
  content: "\e226"; }

.glyphicon-bitcoin:before {
  content: "\e227"; }

.glyphicon-btc:before {
  content: "\e227"; }

.glyphicon-xbt:before {
  content: "\e227"; }

.glyphicon-yen:before {
  content: "\00a5"; }

.glyphicon-jpy:before {
  content: "\00a5"; }

.glyphicon-ruble:before {
  content: "\20bd"; }

.glyphicon-rub:before {
  content: "\20bd"; }

.glyphicon-scale:before {
  content: "\e230"; }

.glyphicon-ice-lolly:before {
  content: "\e231"; }

.glyphicon-ice-lolly-tasted:before {
  content: "\e232"; }

.glyphicon-education:before {
  content: "\e233"; }

.glyphicon-option-horizontal:before {
  content: "\e234"; }

.glyphicon-option-vertical:before {
  content: "\e235"; }

.glyphicon-menu-hamburger:before {
  content: "\e236"; }

.glyphicon-modal-window:before {
  content: "\e237"; }

.glyphicon-oil:before {
  content: "\e238"; }

.glyphicon-grain:before {
  content: "\e239"; }

.glyphicon-sunglasses:before {
  content: "\e240"; }

.glyphicon-text-size:before {
  content: "\e241"; }

.glyphicon-text-color:before {
  content: "\e242"; }

.glyphicon-text-background:before {
  content: "\e243"; }

.glyphicon-object-align-top:before {
  content: "\e244"; }

.glyphicon-object-align-bottom:before {
  content: "\e245"; }

.glyphicon-object-align-horizontal:before {
  content: "\e246"; }

.glyphicon-object-align-left:before {
  content: "\e247"; }

.glyphicon-object-align-vertical:before {
  content: "\e248"; }

.glyphicon-object-align-right:before {
  content: "\e249"; }

.glyphicon-triangle-right:before {
  content: "\e250"; }

.glyphicon-triangle-left:before {
  content: "\e251"; }

.glyphicon-triangle-bottom:before {
  content: "\e252"; }

.glyphicon-triangle-top:before {
  content: "\e253"; }

.glyphicon-console:before {
  content: "\e254"; }

.glyphicon-superscript:before {
  content: "\e255"; }

.glyphicon-subscript:before {
  content: "\e256"; }

.glyphicon-menu-left:before {
  content: "\e257"; }

.glyphicon-menu-right:before {
  content: "\e258"; }

.glyphicon-menu-down:before {
  content: "\e259"; }

.glyphicon-menu-up:before {
  content: "\e260"; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff; }

button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  color: #337ab7;
  text-decoration: none; }

a:focus, a:hover {
  color: #23527c;
  text-decoration: underline; }

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

figure {
  margin: 0; }

img {
  vertical-align: middle; }

.carousel-inner > .item > a > img, .carousel-inner > .item > img, .img-responsive, .thumbnail a > img, .thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto; }

.img-rounded {
  border-radius: 6px; }

.img-thumbnail {
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }

.img-circle {
  border-radius: 50%; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

[role=button] {
  cursor: pointer; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

.h1 .small, .h1 small, .h2 .small, .h2 small, .h3 .small, .h3 small, .h4 .small, .h4 small, .h5 .small, .h5 small, .h6 .small, .h6 small, h1 .small, h1 small, h2 .small, h2 small, h3 .small, h3 small, h4 .small, h4 small, h5 .small, h5 small, h6 .small, h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777; }

.h1, .h2, .h3, h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px; }

.h1 .small, .h1 small, .h2 .small, .h2 small, .h3 .small, .h3 small, h1 .small, h1 small, h2 .small, h2 small, h3 .small, h3 small {
  font-size: 65%; }

.h4, .h5, .h6, h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px; }

.h4 .small, .h4 small, .h5 .small, .h5 small, .h6 .small, .h6 small, h4 .small, h4 small, h5 .small, h5 small, h6 .small, h6 small {
  font-size: 75%; }

.h1, h1 {
  font-size: 36px; }

.h2, h2 {
  font-size: 30px; }

.h3, h3 {
  font-size: 24px; }

.h4, h4 {
  font-size: 18px; }

.h5, h5 {
  font-size: 14px; }

.h6, h6 {
  font-size: 12px; }

p {
  margin: 0 0 10px; }

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4; }

@media (min-width: 768px) {
  .lead {
    font-size: 21px; } }

.small, small {
  font-size: 85%; }

.mark, mark {
  padding: .2em;
  background-color: #fcf8e3; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-muted {
  color: #777; }

.text-primary {
  color: #337ab7; }

a.text-primary:focus, a.text-primary:hover {
  color: #286090; }

.text-success {
  color: #3c763d; }

a.text-success:focus, a.text-success:hover {
  color: #2b542c; }

.text-info {
  color: #31708f; }

a.text-info:focus, a.text-info:hover {
  color: #245269; }

.text-warning {
  color: #8a6d3b; }

a.text-warning:focus, a.text-warning:hover {
  color: #66512c; }

.text-danger {
  color: #a94442; }

a.text-danger:focus, a.text-danger:hover {
  color: #843534; }

.bg-primary {
  color: #fff;
  background-color: #337ab7; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #286090; }

.bg-success {
  background-color: #dff0d8; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #c1e2b3; }

.bg-info {
  background-color: #d9edf7; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #afd9ee; }

.bg-warning {
  background-color: #fcf8e3; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #f7ecb5; }

.bg-danger {
  background-color: #f2dede; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #e4b9b9; }

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee; }

ol, ul {
  margin-top: 0;
  margin-bottom: 10px; }

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none; }

.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px; }

dl {
  margin-top: 0;
  margin-bottom: 20px; }

dd, dt {
  line-height: 1.42857143; }

dt {
  font-weight: 700; }

dd {
  margin-left: 0; }

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .dl-horizontal dd {
    margin-left: 180px; } }

abbr[data-original-title], abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #777; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee; }

blockquote ol:last-child, blockquote p:last-child, blockquote ul:last-child {
  margin-bottom: 0; }

blockquote .small, blockquote footer, blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777; }

blockquote .small:before, blockquote footer:before, blockquote small:before {
  content: '\2014 \00A0'; }

.blockquote-reverse, blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0; }

.blockquote-reverse .small:before, .blockquote-reverse footer:before, .blockquote-reverse small:before, blockquote.pull-right .small:before, blockquote.pull-right footer:before, blockquote.pull-right small:before {
  content: ''; }

.blockquote-reverse .small:after, .blockquote-reverse footer:after, .blockquote-reverse small:after, blockquote.pull-right .small:after, blockquote.pull-right footer:after, blockquote.pull-right small:after {
  content: '\00A0 \2014'; }

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143; }

code, kbd, pre, samp {
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace; }

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px; }

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  -webkit-box-shadow: none;
  box-shadow: none; }

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px; }

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 768px) {
  .container {
    width: 750px; } }

@media (min-width: 992px) {
  .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px; } }

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  margin-right: -15px;
  margin-left: -15px; }

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66666667%; }

.col-xs-10 {
  width: 83.33333333%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66666667%; }

.col-xs-7 {
  width: 58.33333333%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66666667%; }

.col-xs-4 {
  width: 33.33333333%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.66666667%; }

.col-xs-1 {
  width: 8.33333333%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-pull-11 {
  right: 91.66666667%; }

.col-xs-pull-10 {
  right: 83.33333333%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-8 {
  right: 66.66666667%; }

.col-xs-pull-7 {
  right: 58.33333333%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-5 {
  right: 41.66666667%; }

.col-xs-pull-4 {
  right: 33.33333333%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-2 {
  right: 16.66666667%; }

.col-xs-pull-1 {
  right: 8.33333333%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-push-11 {
  left: 91.66666667%; }

.col-xs-push-10 {
  left: 83.33333333%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-8 {
  left: 66.66666667%; }

.col-xs-push-7 {
  left: 58.33333333%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-5 {
  left: 41.66666667%; }

.col-xs-push-4 {
  left: 33.33333333%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-2 {
  left: 16.66666667%; }

.col-xs-push-1 {
  left: 8.33333333%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-offset-4 {
  margin-left: 33.33333333%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-offset-0 {
  margin-left: 0; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-pull-11 {
    right: 91.66666667%; }
  .col-sm-pull-10 {
    right: 83.33333333%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-8 {
    right: 66.66666667%; }
  .col-sm-pull-7 {
    right: 58.33333333%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-5 {
    right: 41.66666667%; }
  .col-sm-pull-4 {
    right: 33.33333333%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-2 {
    right: 16.66666667%; }
  .col-sm-pull-1 {
    right: 8.33333333%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-push-11 {
    left: 91.66666667%; }
  .col-sm-push-10 {
    left: 83.33333333%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-8 {
    left: 66.66666667%; }
  .col-sm-push-7 {
    left: 58.33333333%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-5 {
    left: 41.66666667%; }
  .col-sm-push-4 {
    left: 33.33333333%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-2 {
    left: 16.66666667%; }
  .col-sm-push-1 {
    left: 8.33333333%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .col-sm-offset-11 {
    margin-left: 91.66666667%; }
  .col-sm-offset-10 {
    margin-left: 83.33333333%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-8 {
    margin-left: 66.66666667%; }
  .col-sm-offset-7 {
    margin-left: 58.33333333%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-5 {
    margin-left: 41.66666667%; }
  .col-sm-offset-4 {
    margin-left: 33.33333333%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-2 {
    margin-left: 16.66666667%; }
  .col-sm-offset-1 {
    margin-left: 8.33333333%; }
  .col-sm-offset-0 {
    margin-left: 0; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    float: left; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-pull-11 {
    right: 91.66666667%; }
  .col-md-pull-10 {
    right: 83.33333333%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-8 {
    right: 66.66666667%; }
  .col-md-pull-7 {
    right: 58.33333333%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-5 {
    right: 41.66666667%; }
  .col-md-pull-4 {
    right: 33.33333333%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-2 {
    right: 16.66666667%; }
  .col-md-pull-1 {
    right: 8.33333333%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-push-11 {
    left: 91.66666667%; }
  .col-md-push-10 {
    left: 83.33333333%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-8 {
    left: 66.66666667%; }
  .col-md-push-7 {
    left: 58.33333333%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-5 {
    left: 41.66666667%; }
  .col-md-push-4 {
    left: 33.33333333%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-2 {
    left: 16.66666667%; }
  .col-md-push-1 {
    left: 8.33333333%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .col-md-offset-11 {
    margin-left: 91.66666667%; }
  .col-md-offset-10 {
    margin-left: 83.33333333%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-8 {
    margin-left: 66.66666667%; }
  .col-md-offset-7 {
    margin-left: 58.33333333%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-5 {
    margin-left: 41.66666667%; }
  .col-md-offset-4 {
    margin-left: 33.33333333%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-2 {
    margin-left: 16.66666667%; }
  .col-md-offset-1 {
    margin-left: 8.33333333%; }
  .col-md-offset-0 {
    margin-left: 0; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
    float: left; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666667%; }
  .col-lg-10 {
    width: 83.33333333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666667%; }
  .col-lg-7 {
    width: 58.33333333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666667%; }
  .col-lg-4 {
    width: 33.33333333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66666667%; }
  .col-lg-1 {
    width: 8.33333333%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-pull-11 {
    right: 91.66666667%; }
  .col-lg-pull-10 {
    right: 83.33333333%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-8 {
    right: 66.66666667%; }
  .col-lg-pull-7 {
    right: 58.33333333%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-5 {
    right: 41.66666667%; }
  .col-lg-pull-4 {
    right: 33.33333333%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-2 {
    right: 16.66666667%; }
  .col-lg-pull-1 {
    right: 8.33333333%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-push-11 {
    left: 91.66666667%; }
  .col-lg-push-10 {
    left: 83.33333333%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-8 {
    left: 66.66666667%; }
  .col-lg-push-7 {
    left: 58.33333333%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-5 {
    left: 41.66666667%; }
  .col-lg-push-4 {
    left: 33.33333333%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-2 {
    left: 16.66666667%; }
  .col-lg-push-1 {
    left: 8.33333333%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .col-lg-offset-11 {
    margin-left: 91.66666667%; }
  .col-lg-offset-10 {
    margin-left: 83.33333333%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-8 {
    margin-left: 66.66666667%; }
  .col-lg-offset-7 {
    margin-left: 58.33333333%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-5 {
    margin-left: 41.66666667%; }
  .col-lg-offset-4 {
    margin-left: 33.33333333%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-2 {
    margin-left: 16.66666667%; }
  .col-lg-offset-1 {
    margin-left: 8.33333333%; }
  .col-lg-offset-0 {
    margin-left: 0; } }

table {
  background-color: transparent; }

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left; }

th {
  text-align: left; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px; }

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd; }

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd; }

.table > caption + thead > tr:first-child > td, .table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > td, .table > thead:first-child > tr:first-child > th {
  border-top: 0; }

.table > tbody + tbody {
  border-top: 2px solid #ddd; }

.table .table {
  background-color: #fff; }

.table-condensed > tbody > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > thead > tr > th {
  padding: 5px; }

.table-bordered {
  border: 1px solid #ddd; }

.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
  border: 1px solid #ddd; }

.table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
  border-bottom-width: 2px; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9; }

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5; }

table col[class*=col-] {
  position: static;
  display: table-column;
  float: none; }

table td[class*=col-], table th[class*=col-] {
  position: static;
  display: table-cell;
  float: none; }

.table > tbody > tr.active > td, .table > tbody > tr.active > th, .table > tbody > tr > td.active, .table > tbody > tr > th.active, .table > tfoot > tr.active > td, .table > tfoot > tr.active > th, .table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th, .table > thead > tr > td.active, .table > thead > tr > th.active {
  background-color: #f5f5f5; }

.table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr.active:hover > th, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover {
  background-color: #e8e8e8; }

.table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tbody > tr > td.success, .table > tbody > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th, .table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th, .table > thead > tr > td.success, .table > thead > tr > th.success {
  background-color: #dff0d8; }

.table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr.success:hover > th, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover {
  background-color: #d0e9c6; }

.table > tbody > tr.info > td, .table > tbody > tr.info > th, .table > tbody > tr > td.info, .table > tbody > tr > th.info, .table > tfoot > tr.info > td, .table > tfoot > tr.info > th, .table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th, .table > thead > tr > td.info, .table > thead > tr > th.info {
  background-color: #d9edf7; }

.table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr.info:hover > th, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover {
  background-color: #c4e3f3; }

.table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .table > tbody > tr > td.warning, .table > tbody > tr > th.warning, .table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th, .table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th, .table > thead > tr > td.warning, .table > thead > tr > th.warning {
  background-color: #fcf8e3; }

.table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr.warning:hover > th, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover {
  background-color: #faf2cc; }

.table > tbody > tr.danger > td, .table > tbody > tr.danger > th, .table > tbody > tr > td.danger, .table > tbody > tr > th.danger, .table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th, .table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th, .table > thead > tr > td.danger, .table > thead > tr > th.danger {
  background-color: #f2dede; }

.table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr.danger:hover > th, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover {
  background-color: #ebcccc; }

.table-responsive {
  min-height: .01%;
  overflow-x: auto; }

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd; }
  .table-responsive > .table {
    margin-bottom: 0; }
  .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > td, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > thead > tr > th {
    white-space: nowrap; }
  .table-responsive > .table-bordered {
    border: 0; }
  .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > thead > tr > th:first-child {
    border-left: 0; }
  .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > thead > tr > th:last-child {
    border-right: 0; }
  .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0; } }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700; }

input[type=search] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type=checkbox], input[type=radio] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal; }

input[type=file] {
  display: block; }

input[type=range] {
  display: block;
  width: 100%; }

select[multiple], select[size] {
  height: auto; }

input[type=file]:focus, input[type=checkbox]:focus, input[type=radio]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s; }

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #999; }

.form-control::-webkit-input-placeholder {
  color: #999; }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1; }

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: not-allowed; }

textarea.form-control {
  height: auto; }

input[type=search] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type=date].form-control, input[type=time].form-control, input[type=datetime-local].form-control, input[type=month].form-control {
    line-height: 34px; }
  .input-group-sm input[type=date], .input-group-sm input[type=time], .input-group-sm input[type=datetime-local], .input-group-sm input[type=month], input[type=date].input-sm, input[type=time].input-sm, input[type=datetime-local].input-sm, input[type=month].input-sm {
    line-height: 30px; }
  .input-group-lg input[type=date], .input-group-lg input[type=time], .input-group-lg input[type=datetime-local], .input-group-lg input[type=month], input[type=date].input-lg, input[type=time].input-lg, input[type=datetime-local].input-lg, input[type=month].input-lg {
    line-height: 46px; } }

.form-group {
  margin-bottom: 15px; }

.checkbox, .radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }

.checkbox label, .radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer; }

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
  position: absolute;
  margin-top: 4px\9;
  margin-left: -20px; }

.checkbox + .checkbox, .radio + .radio {
  margin-top: -5px; }

.checkbox-inline, .radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer; }

.checkbox-inline + .checkbox-inline, .radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 10px; }

fieldset[disabled] input[type=checkbox], fieldset[disabled] input[type=radio], input[type=checkbox].disabled, input[type=checkbox][disabled], input[type=radio].disabled, input[type=radio][disabled] {
  cursor: not-allowed; }

.checkbox-inline.disabled, .radio-inline.disabled, fieldset[disabled] .checkbox-inline, fieldset[disabled] .radio-inline {
  cursor: not-allowed; }

.checkbox.disabled label, .radio.disabled label, fieldset[disabled] .checkbox label, fieldset[disabled] .radio label {
  cursor: not-allowed; }

.form-control-static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0; }

.form-control-static.input-lg, .form-control-static.input-sm {
  padding-right: 0;
  padding-left: 0; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

select.input-sm {
  height: 30px;
  line-height: 30px; }

select[multiple].input-sm, textarea.input-sm {
  height: auto; }

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

.form-group-sm select[multiple].form-control, .form-group-sm textarea.form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5; }

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

select.input-lg {
  height: 46px;
  line-height: 46px; }

select[multiple].input-lg, textarea.input-lg {
  height: auto; }

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

.form-group-lg select[multiple].form-control, .form-group-lg textarea.form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

.has-feedback {
  position: relative; }

.has-feedback .form-control {
  padding-right: 42.5px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

.form-group-lg .form-control + .form-control-feedback, .input-group-lg + .form-control-feedback, .input-lg + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.form-group-sm .form-control + .form-control-feedback, .input-group-sm + .form-control-feedback, .input-sm + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.has-success .checkbox, .has-success .checkbox-inline, .has-success .control-label, .has-success .help-block, .has-success .radio, .has-success .radio-inline, .has-success.checkbox label, .has-success.checkbox-inline label, .has-success.radio label, .has-success.radio-inline label {
  color: #3c763d; }

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .checkbox, .has-warning .checkbox-inline, .has-warning .control-label, .has-warning .help-block, .has-warning .radio, .has-warning .radio-inline, .has-warning.checkbox label, .has-warning.checkbox-inline label, .has-warning.radio label, .has-warning.radio-inline label {
  color: #8a6d3b; }

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b; }

.has-warning .form-control-feedback {
  color: #8a6d3b; }

.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label {
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442; }

.has-error .form-control-feedback {
  color: #a94442; }

.has-feedback label ~ .form-control-feedback {
  top: 25px; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-static {
    display: inline-block; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
  .form-inline .input-group .form-control, .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn {
    width: auto; }
  .form-inline .input-group > .form-control {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .checkbox, .form-inline .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .checkbox label, .form-inline .radio label {
    padding-left: 0; }
  .form-inline .checkbox input[type=checkbox], .form-inline .radio input[type=radio] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .checkbox, .form-horizontal .checkbox-inline, .form-horizontal .radio, .form-horizontal .radio-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0; }

.form-horizontal .checkbox, .form-horizontal .radio {
  min-height: 27px; }

.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px; }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right; } }

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px; }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px; } }

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px; }

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.btn.focus, .btn:focus, .btn:hover {
  color: #333;
  text-decoration: none; }

.btn.active, .btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65; }

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }

.btn-default.focus, .btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c; }

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad; }

.btn-default.active, .btn-default:active, .open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad; }

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c; }

.btn-default.active, .btn-default:active, .open > .dropdown-toggle.btn-default {
  background-image: none; }

.btn-default.disabled.focus, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled].focus, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc; }

.btn-default .badge {
  color: #fff;
  background-color: #333; }

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4; }

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40; }

.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74; }

.btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74; }

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40; }

.btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
  background-image: none; }

.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled].focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
  background-color: #337ab7;
  border-color: #2e6da4; }

.btn-primary .badge {
  color: #337ab7;
  background-color: #fff; }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c; }

.btn-success.focus, .btn-success:focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625; }

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439; }

.btn-success.active, .btn-success:active, .open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439; }

.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success:active.focus, .btn-success:active:focus, .btn-success:active:hover, .open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #398439;
  border-color: #255625; }

.btn-success.active, .btn-success:active, .open > .dropdown-toggle.btn-success {
  background-image: none; }

.btn-success.disabled.focus, .btn-success.disabled:focus, .btn-success.disabled:hover, .btn-success[disabled].focus, .btn-success[disabled]:focus, .btn-success[disabled]:hover, fieldset[disabled] .btn-success.focus, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:hover {
  background-color: #5cb85c;
  border-color: #4cae4c; }

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff; }

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da; }

.btn-info.focus, .btn-info:focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85; }

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc; }

.btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc; }

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info:active.focus, .btn-info:active:focus, .btn-info:active:hover, .open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85; }

.btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
  background-image: none; }

.btn-info.disabled.focus, .btn-info.disabled:focus, .btn-info.disabled:hover, .btn-info[disabled].focus, .btn-info[disabled]:focus, .btn-info[disabled]:hover, fieldset[disabled] .btn-info.focus, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:hover {
  background-color: #5bc0de;
  border-color: #46b8da; }

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff; }

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236; }

.btn-warning.focus, .btn-warning:focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d; }

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512; }

.btn-warning.active, .btn-warning:active, .open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512; }

.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning:active.focus, .btn-warning:active:focus, .btn-warning:active:hover, .open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d; }

.btn-warning.active, .btn-warning:active, .open > .dropdown-toggle.btn-warning {
  background-image: none; }

.btn-warning.disabled.focus, .btn-warning.disabled:focus, .btn-warning.disabled:hover, .btn-warning[disabled].focus, .btn-warning[disabled]:focus, .btn-warning[disabled]:hover, fieldset[disabled] .btn-warning.focus, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:hover {
  background-color: #f0ad4e;
  border-color: #eea236; }

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff; }

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; }

.btn-danger.focus, .btn-danger:focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19; }

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925; }

.btn-danger.active, .btn-danger:active, .open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925; }

.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger:active.focus, .btn-danger:active:focus, .btn-danger:active:hover, .open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19; }

.btn-danger.active, .btn-danger:active, .open > .dropdown-toggle.btn-danger {
  background-image: none; }

.btn-danger.disabled.focus, .btn-danger.disabled:focus, .btn-danger.disabled:hover, .btn-danger[disabled].focus, .btn-danger[disabled]:focus, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger.focus, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:hover {
  background-color: #d9534f;
  border-color: #d43f3a; }

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff; }

.btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0; }

.btn-link, .btn-link.active, .btn-link:active, .btn-link[disabled], fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-link, .btn-link:active, .btn-link:focus, .btn-link:hover {
  border-color: transparent; }

.btn-link:focus, .btn-link:hover {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent; }

.btn-link[disabled]:focus, .btn-link[disabled]:hover, fieldset[disabled] .btn-link:focus, fieldset[disabled] .btn-link:hover {
  color: #777;
  text-decoration: none; }

.btn-group-lg > .btn, .btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

.btn-group-sm > .btn, .btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.btn-group-xs > .btn, .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 5px; }

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear; }

.fade.in {
  opacity: 1; }

.collapse {
  display: none; }

.collapse.in {
  display: block; }

tr.collapse.in {
  display: table-row; }

tbody.collapse.in {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .35s;
  -o-transition-duration: .35s;
  transition-duration: .35s;
  -webkit-transition-property: height,visibility;
  -o-transition-property: height,visibility;
  transition-property: height,visibility; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.dropdown, .dropup {
  position: relative; }

.dropdown-toggle:focus {
  outline: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

.dropdown-menu.pull-right {
  right: 0;
  left: auto; }

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5; }

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap; }

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5; }

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0; }

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:focus, .dropdown-menu > .disabled > a:hover {
  color: #777; }

.dropdown-menu > .disabled > a:focus, .dropdown-menu > .disabled > a:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false); }

.open > .dropdown-menu {
  display: block; }

.open > a {
  outline: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap; }

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990; }

.pull-right > .dropdown-menu {
  right: 0;
  left: auto; }

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9; }

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px; }

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto; }
  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0; } }

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  float: left; }

.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
  z-index: 2; }

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  margin-left: -1px; }

.btn-toolbar {
  margin-left: -5px; }

.btn-toolbar .btn, .btn-toolbar .btn-group, .btn-toolbar .input-group {
  float: left; }

.btn-toolbar > .btn, .btn-toolbar > .btn-group, .btn-toolbar > .input-group {
  margin-left: 5px; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
  outline: 0; }

.btn-group > .btn + .dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px; }

.btn-group > .btn-lg + .dropdown-toggle {
  padding-right: 12px;
  padding-left: 12px; }

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn .caret {
  margin-left: 0; }

.btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0; }

.dropup .btn-lg .caret {
  border-width: 0 5px 5px; }

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group, .btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%; }

.btn-group-vertical > .btn-group > .btn {
  float: none; }

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; }

.btn-group-justified > .btn, .btn-group-justified > .btn-group {
  display: table-cell;
  float: none;
  width: 1%; }

.btn-group-justified > .btn-group .btn {
  width: 100%; }

.btn-group-justified > .btn-group .dropdown-menu {
  left: auto; }

[data-toggle=buttons] > .btn input[type=checkbox], [data-toggle=buttons] > .btn input[type=radio], [data-toggle=buttons] > .btn-group > .btn input[type=checkbox], [data-toggle=buttons] > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: table;
  border-collapse: separate; }

.input-group[class*=col-] {
  float: none;
  padding-right: 0;
  padding-left: 0; }

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0; }

.input-group .form-control:focus {
  z-index: 3; }

.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

select.input-group-lg > .form-control, select.input-group-lg > .input-group-addon, select.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  line-height: 46px; }

select[multiple].input-group-lg > .form-control, select[multiple].input-group-lg > .input-group-addon, select[multiple].input-group-lg > .input-group-btn > .btn, textarea.input-group-lg > .form-control, textarea.input-group-lg > .input-group-addon, textarea.input-group-lg > .input-group-btn > .btn {
  height: auto; }

.input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

select.input-group-sm > .form-control, select.input-group-sm > .input-group-addon, select.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  line-height: 30px; }

select[multiple].input-group-sm > .form-control, select[multiple].input-group-sm > .input-group-addon, select[multiple].input-group-sm > .input-group-btn > .btn, textarea.input-group-sm > .form-control, textarea.input-group-sm > .input-group-addon, textarea.input-group-sm > .input-group-btn > .btn {
  height: auto; }

.input-group .form-control, .input-group-addon, .input-group-btn {
  display: table-cell; }

.input-group .form-control:not(:first-child):not(:last-child), .input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; }

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px; }

.input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px; }

.input-group-addon.input-lg {
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 6px; }

.input-group-addon input[type=checkbox], .input-group-addon input[type=radio] {
  margin-top: 0; }

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn-group:not(:last-child) > .btn, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:first-child {
  border-right: 0; }

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:first-child > .btn-group:not(:first-child) > .btn, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-addon:last-child {
  border-left: 0; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }

.input-group-btn > .btn {
  position: relative; }

.input-group-btn > .btn + .btn {
  margin-left: -1px; }

.input-group-btn > .btn:active, .input-group-btn > .btn:focus, .input-group-btn > .btn:hover {
  z-index: 2; }

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group {
  margin-right: -1px; }

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px; }

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav > li {
  position: relative;
  display: block; }

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px; }

.nav > li > a:focus, .nav > li > a:hover {
  text-decoration: none;
  background-color: #eee; }

.nav > li.disabled > a {
  color: #777; }

.nav > li.disabled > a:focus, .nav > li.disabled > a:hover {
  color: #777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent; }

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
  background-color: #eee;
  border-color: #337ab7; }

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5; }

.nav > li > a > img {
  max-width: none; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }

.nav-tabs > li {
  float: left;
  margin-bottom: -1px; }

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0; }

.nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent; }

.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0; }

.nav-tabs.nav-justified > li {
  float: none; }

.nav-tabs.nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center; }

.nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto; }

@media (min-width: 768px) {
  .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%; }
  .nav-tabs.nav-justified > li > a {
    margin-bottom: 0; } }

.nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 4px; }

.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:hover {
  border: 1px solid #ddd; }

@media (min-width: 768px) {
  .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0; }
  .nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:hover {
    border-bottom-color: #fff; } }

.nav-pills > li {
  float: left; }

.nav-pills > li > a {
  border-radius: 4px; }

.nav-pills > li + li {
  margin-left: 2px; }

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  color: #fff;
  background-color: #337ab7; }

.nav-stacked > li {
  float: none; }

.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0; }

.nav-justified {
  width: 100%; }

.nav-justified > li {
  float: none; }

.nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center; }

.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto; }

@media (min-width: 768px) {
  .nav-justified > li {
    display: table-cell;
    width: 1%; }
  .nav-justified > li > a {
    margin-bottom: 0; } }

.nav-tabs-justified {
  border-bottom: 0; }

.nav-tabs-justified > li > a {
  margin-right: 0;
  border-radius: 4px; }

.nav-tabs-justified > .active > a, .nav-tabs-justified > .active > a:focus, .nav-tabs-justified > .active > a:hover {
  border: 1px solid #ddd; }

@media (min-width: 768px) {
  .nav-tabs-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0; }
  .nav-tabs-justified > .active > a, .nav-tabs-justified > .active > a:focus, .nav-tabs-justified > .active > a:hover {
    border-bottom-color: #fff; } }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent; }

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px; } }

@media (min-width: 768px) {
  .navbar-header {
    float: left; } }

.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }

.navbar-collapse.in {
  overflow-y: auto; }

@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important; }
  .navbar-collapse.in {
    overflow-y: visible; }
  .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse {
    padding-right: 0;
    padding-left: 0; } }

.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
  max-height: 340px; }

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
    max-height: 200px; } }

.container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
  margin-right: -15px;
  margin-left: -15px; }

@media (min-width: 768px) {
  .container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
    margin-right: 0;
    margin-left: 0; } }

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px; }

@media (min-width: 768px) {
  .navbar-static-top {
    border-radius: 0; } }

.navbar-fixed-bottom, .navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030; }

@media (min-width: 768px) {
  .navbar-fixed-bottom, .navbar-fixed-top {
    border-radius: 0; } }

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px; }

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0; }

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px; }

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none; }

.navbar-brand > img {
  display: block; }

@media (min-width: 768px) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: -15px; } }

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px; }

.navbar-toggle:focus {
  outline: 0; }

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px; }

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px; }

@media (min-width: 768px) {
  .navbar-toggle {
    display: none; } }

.navbar-nav {
  margin: 7.5px -15px; }

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px; }

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 15px 5px 25px; }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px; }
  .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-nav .open .dropdown-menu > li > a:hover {
    background-image: none; } }

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0; }
  .navbar-nav > li {
    float: left; }
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px; } }

.navbar-form {
  padding: 10px 15px;
  margin-top: 8px;
  margin-right: -15px;
  margin-bottom: 8px;
  margin-left: -15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1); }

@media (min-width: 768px) {
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .navbar-form .form-control-static {
    display: inline-block; }
  .navbar-form .input-group {
    display: inline-table;
    vertical-align: middle; }
  .navbar-form .input-group .form-control, .navbar-form .input-group .input-group-addon, .navbar-form .input-group .input-group-btn {
    width: auto; }
  .navbar-form .input-group > .form-control {
    width: 100%; }
  .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .navbar-form .checkbox, .navbar-form .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
  .navbar-form .checkbox label, .navbar-form .radio label {
    padding-left: 0; }
  .navbar-form .checkbox input[type=checkbox], .navbar-form .radio input[type=radio] {
    position: relative;
    margin-left: 0; }
  .navbar-form .has-feedback .form-control-feedback {
    top: 0; } }

@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px; }
  .navbar-form .form-group:last-child {
    margin-bottom: 0; } }

@media (min-width: 768px) {
  .navbar-form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; } }

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px; }

.navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px; }

.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px; }

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px; }

@media (min-width: 768px) {
  .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px; } }

@media (min-width: 768px) {
  .navbar-left {
    float: left !important; }
  .navbar-right {
    float: right !important;
    margin-right: -15px; }
  .navbar-right ~ .navbar-right {
    margin-right: 0; } }

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7; }

.navbar-default .navbar-brand {
  color: #777; }

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color: #5e5e5e;
  background-color: transparent; }

.navbar-default .navbar-text {
  color: #777; }

.navbar-default .navbar-nav > li > a {
  color: #777; }

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
  color: #333;
  background-color: transparent; }

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
  color: #555;
  background-color: #e7e7e7; }

.navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:focus, .navbar-default .navbar-nav > .disabled > a:hover {
  color: #ccc;
  background-color: transparent; }

.navbar-default .navbar-toggle {
  border-color: #ddd; }

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: #ddd; }

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888; }

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #e7e7e7; }

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
  color: #555;
  background-color: #e7e7e7; }

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777; }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #333;
    background-color: transparent; }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #555;
    background-color: #e7e7e7; }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #ccc;
    background-color: transparent; } }

.navbar-default .navbar-link {
  color: #777; }

.navbar-default .navbar-link:hover {
  color: #333; }

.navbar-default .btn-link {
  color: #777; }

.navbar-default .btn-link:focus, .navbar-default .btn-link:hover {
  color: #333; }

.navbar-default .btn-link[disabled]:focus, .navbar-default .btn-link[disabled]:hover, fieldset[disabled] .navbar-default .btn-link:focus, fieldset[disabled] .navbar-default .btn-link:hover {
  color: #ccc; }

.navbar-inverse {
  background-color: #222;
  border-color: #080808; }

.navbar-inverse .navbar-brand {
  color: #9d9d9d; }

.navbar-inverse .navbar-brand:focus, .navbar-inverse .navbar-brand:hover {
  color: #fff;
  background-color: transparent; }

.navbar-inverse .navbar-text {
  color: #9d9d9d; }

.navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d; }

.navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover {
  color: #fff;
  background-color: transparent; }

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:focus, .navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: #080808; }

.navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:focus, .navbar-inverse .navbar-nav > .disabled > a:hover {
  color: #444;
  background-color: transparent; }

.navbar-inverse .navbar-toggle {
  border-color: #333; }

.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
  background-color: #333; }

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff; }

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: #101010; }

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
  color: #fff;
  background-color: #080808; }

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #080808; }
  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #080808; }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #9d9d9d; }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #fff;
    background-color: transparent; }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #fff;
    background-color: #080808; }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #444;
    background-color: transparent; } }

.navbar-inverse .navbar-link {
  color: #9d9d9d; }

.navbar-inverse .navbar-link:hover {
  color: #fff; }

.navbar-inverse .btn-link {
  color: #9d9d9d; }

.navbar-inverse .btn-link:focus, .navbar-inverse .btn-link:hover {
  color: #fff; }

.navbar-inverse .btn-link[disabled]:focus, .navbar-inverse .btn-link[disabled]:hover, fieldset[disabled] .navbar-inverse .btn-link:focus, fieldset[disabled] .navbar-inverse .btn-link:hover {
  color: #444; }

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px; }

.breadcrumb > li {
  display: inline-block; }

.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0"; }

.breadcrumb > .active {
  color: #777; }

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px; }

.pagination > li {
  display: inline; }

.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd; }

.pagination > li:first-child > a, .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd; }

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7; }

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd; }

.pagination-lg > li > a, .pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }

.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; }

.pagination-sm > li > a, .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5; }

.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.pagination-sm > li:last-child > a, .pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none; }

.pager li {
  display: inline; }

.pager li > a, .pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px; }

.pager li > a:focus, .pager li > a:hover {
  text-decoration: none;
  background-color: #eee; }

.pager .next > a, .pager .next > span {
  float: right; }

.pager .previous > a, .pager .previous > span {
  float: left; }

.pager .disabled > a, .pager .disabled > a:focus, .pager .disabled > a:hover, .pager .disabled > span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff; }

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em; }

a.label:focus, a.label:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

.label:empty {
  display: none; }

.btn .label {
  position: relative;
  top: -1px; }

.label-default {
  background-color: #777; }

.label-default[href]:focus, .label-default[href]:hover {
  background-color: #5e5e5e; }

.label-primary {
  background-color: #337ab7; }

.label-primary[href]:focus, .label-primary[href]:hover {
  background-color: #286090; }

.label-success {
  background-color: #5cb85c; }

.label-success[href]:focus, .label-success[href]:hover {
  background-color: #449d44; }

.label-info {
  background-color: #5bc0de; }

.label-info[href]:focus, .label-info[href]:hover {
  background-color: #31b0d5; }

.label-warning {
  background-color: #f0ad4e; }

.label-warning[href]:focus, .label-warning[href]:hover {
  background-color: #ec971f; }

.label-danger {
  background-color: #d9534f; }

.label-danger[href]:focus, .label-danger[href]:hover {
  background-color: #c9302c; }

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.btn-group-xs > .btn .badge, .btn-xs .badge {
  top: 0;
  padding: 1px 5px; }

a.badge:focus, a.badge:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

.list-group-item.active > .badge, .nav-pills > .active > a > .badge {
  color: #337ab7;
  background-color: #fff; }

.list-group-item > .badge {
  float: right; }

.list-group-item > .badge + .badge {
  margin-right: 5px; }

.nav-pills > li > a > .badge {
  margin-left: 3px; }

.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eee; }

.jumbotron .h1, .jumbotron h1 {
  color: inherit; }

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200; }

.jumbotron > hr {
  border-top-color: #d5d5d5; }

.container .jumbotron, .container-fluid .jumbotron {
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 6px; }

.jumbotron .container {
  max-width: 100%; }

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px; }
  .container .jumbotron, .container-fluid .jumbotron {
    padding-right: 60px;
    padding-left: 60px; }
  .jumbotron .h1, .jumbotron h1 {
    font-size: 63px; } }

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border .2s ease-in-out;
  -o-transition: border .2s ease-in-out;
  transition: border .2s ease-in-out; }

.thumbnail a > img, .thumbnail > img {
  margin-right: auto;
  margin-left: auto; }

a.thumbnail.active, a.thumbnail:focus, a.thumbnail:hover {
  border-color: #337ab7; }

.thumbnail .caption {
  padding: 9px;
  color: #333; }

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px; }

.alert h4 {
  margin-top: 0;
  color: inherit; }

.alert .alert-link {
  font-weight: 700; }

.alert > p, .alert > ul {
  margin-bottom: 0; }

.alert > p + p {
  margin-top: 5px; }

.alert-dismissable, .alert-dismissible {
  padding-right: 35px; }

.alert-dismissable .close, .alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit; }

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6; }

.alert-success hr {
  border-top-color: #c9e2b3; }

.alert-success .alert-link {
  color: #2b542c; }

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }

.alert-info hr {
  border-top-color: #a6e1ec; }

.alert-info .alert-link {
  color: #245269; }

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; }

.alert-warning hr {
  border-top-color: #f7e1b5; }

.alert-warning .alert-link {
  color: #66512c; }

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1; }

.alert-danger hr {
  border-top-color: #e4b9c0; }

.alert-danger .alert-link {
  color: #843534; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease; }

.progress-bar-striped, .progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px; }

.progress-bar.active, .progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite; }

.progress-bar-success {
  background-color: #5cb85c; }

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-info {
  background-color: #5bc0de; }

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-warning {
  background-color: #f0ad4e; }

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-danger {
  background-color: #d9534f; }

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.media {
  margin-top: 15px; }

.media:first-child {
  margin-top: 0; }

.media, .media-body {
  overflow: hidden;
  zoom: 1; }

.media-body {
  width: 10000px; }

.media-object {
  display: block; }

.media-object.img-thumbnail {
  max-width: none; }

.media-right, .media > .pull-right {
  padding-left: 10px; }

.media-left, .media > .pull-left {
  padding-right: 10px; }

.media-body, .media-left, .media-right {
  display: table-cell;
  vertical-align: top; }

.media-middle {
  vertical-align: middle; }

.media-bottom {
  vertical-align: bottom; }

.media-heading {
  margin-top: 0;
  margin-bottom: 5px; }

.media-list {
  padding-left: 0;
  list-style: none; }

.list-group {
  padding-left: 0;
  margin-bottom: 20px; }

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd; }

.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

a.list-group-item, button.list-group-item {
  color: #555; }

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
  color: #333; }

a.list-group-item:focus, a.list-group-item:hover, button.list-group-item:focus, button.list-group-item:hover {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5; }

button.list-group-item {
  width: 100%;
  text-align: left; }

.list-group-item.disabled, .list-group-item.disabled:focus, .list-group-item.disabled:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #eee; }

.list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading {
  color: inherit; }

.list-group-item.disabled .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text {
  color: #777; }

.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7; }

.list-group-item.active .list-group-item-heading, .list-group-item.active .list-group-item-heading > .small, .list-group-item.active .list-group-item-heading > small, .list-group-item.active:focus .list-group-item-heading, .list-group-item.active:focus .list-group-item-heading > .small, .list-group-item.active:focus .list-group-item-heading > small, .list-group-item.active:hover .list-group-item-heading, .list-group-item.active:hover .list-group-item-heading > .small, .list-group-item.active:hover .list-group-item-heading > small {
  color: inherit; }

.list-group-item.active .list-group-item-text, .list-group-item.active:focus .list-group-item-text, .list-group-item.active:hover .list-group-item-text {
  color: #c7ddef; }

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8; }

a.list-group-item-success, button.list-group-item-success {
  color: #3c763d; }

a.list-group-item-success .list-group-item-heading, button.list-group-item-success .list-group-item-heading {
  color: inherit; }

a.list-group-item-success:focus, a.list-group-item-success:hover, button.list-group-item-success:focus, button.list-group-item-success:hover {
  color: #3c763d;
  background-color: #d0e9c6; }

a.list-group-item-success.active, a.list-group-item-success.active:focus, a.list-group-item-success.active:hover, button.list-group-item-success.active, button.list-group-item-success.active:focus, button.list-group-item-success.active:hover {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d; }

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7; }

a.list-group-item-info, button.list-group-item-info {
  color: #31708f; }

a.list-group-item-info .list-group-item-heading, button.list-group-item-info .list-group-item-heading {
  color: inherit; }

a.list-group-item-info:focus, a.list-group-item-info:hover, button.list-group-item-info:focus, button.list-group-item-info:hover {
  color: #31708f;
  background-color: #c4e3f3; }

a.list-group-item-info.active, a.list-group-item-info.active:focus, a.list-group-item-info.active:hover, button.list-group-item-info.active, button.list-group-item-info.active:focus, button.list-group-item-info.active:hover {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f; }

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3; }

a.list-group-item-warning, button.list-group-item-warning {
  color: #8a6d3b; }

a.list-group-item-warning .list-group-item-heading, button.list-group-item-warning .list-group-item-heading {
  color: inherit; }

a.list-group-item-warning:focus, a.list-group-item-warning:hover, button.list-group-item-warning:focus, button.list-group-item-warning:hover {
  color: #8a6d3b;
  background-color: #faf2cc; }

a.list-group-item-warning.active, a.list-group-item-warning.active:focus, a.list-group-item-warning.active:hover, button.list-group-item-warning.active, button.list-group-item-warning.active:focus, button.list-group-item-warning.active:hover {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b; }

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede; }

a.list-group-item-danger, button.list-group-item-danger {
  color: #a94442; }

a.list-group-item-danger .list-group-item-heading, button.list-group-item-danger .list-group-item-heading {
  color: inherit; }

a.list-group-item-danger:focus, a.list-group-item-danger:hover, button.list-group-item-danger:focus, button.list-group-item-danger:hover {
  color: #a94442;
  background-color: #ebcccc; }

a.list-group-item-danger.active, a.list-group-item-danger.active:focus, a.list-group-item-danger.active:hover, button.list-group-item-danger.active, button.list-group-item-danger.active:focus, button.list-group-item-danger.active:hover {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442; }

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px; }

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3; }

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.panel-body {
  padding: 15px; }

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit; }

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit; }

.panel-title > .small, .panel-title > .small > a, .panel-title > a, .panel-title > small, .panel-title > small > a {
  color: inherit; }

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.panel > .list-group, .panel > .panel-collapse > .list-group {
  margin-bottom: 0; }

.panel > .list-group .list-group-item, .panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0; }

.panel > .list-group:first-child .list-group-item:first-child, .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.panel > .list-group:last-child .list-group-item:last-child, .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0; }

.list-group + .panel-footer {
  border-top-width: 0; }

.panel > .panel-collapse > .table, .panel > .table, .panel > .table-responsive > .table {
  margin-bottom: 0; }

.panel > .panel-collapse > .table caption, .panel > .table caption, .panel > .table-responsive > .table caption {
  padding-right: 15px;
  padding-left: 15px; }

.panel > .table-responsive:first-child > .table:first-child, .panel > .table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child, .panel > .table:first-child > thead:first-child > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child, .panel > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table:first-child > thead:first-child > tr:first-child th:first-child {
  border-top-left-radius: 3px; }

.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child, .panel > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table:first-child > thead:first-child > tr:first-child th:last-child {
  border-top-right-radius: 3px; }

.panel > .table-responsive:last-child > .table:last-child, .panel > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child, .panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px; }

.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px; }

.panel > .panel-body + .table, .panel > .panel-body + .table-responsive, .panel > .table + .panel-body, .panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd; }

.panel > .table > tbody:first-child > tr:first-child td, .panel > .table > tbody:first-child > tr:first-child th {
  border-top: 0; }

.panel > .table-bordered, .panel > .table-responsive > .table-bordered {
  border: 0; }

.panel > .table-bordered > tbody > tr > td:first-child, .panel > .table-bordered > tbody > tr > th:first-child, .panel > .table-bordered > tfoot > tr > td:first-child, .panel > .table-bordered > tfoot > tr > th:first-child, .panel > .table-bordered > thead > tr > td:first-child, .panel > .table-bordered > thead > tr > th:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child, .panel > .table-responsive > .table-bordered > thead > tr > td:first-child, .panel > .table-responsive > .table-bordered > thead > tr > th:first-child {
  border-left: 0; }

.panel > .table-bordered > tbody > tr > td:last-child, .panel > .table-bordered > tbody > tr > th:last-child, .panel > .table-bordered > tfoot > tr > td:last-child, .panel > .table-bordered > tfoot > tr > th:last-child, .panel > .table-bordered > thead > tr > td:last-child, .panel > .table-bordered > thead > tr > th:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child, .panel > .table-responsive > .table-bordered > thead > tr > td:last-child, .panel > .table-responsive > .table-bordered > thead > tr > th:last-child {
  border-right: 0; }

.panel > .table-bordered > tbody > tr:first-child > td, .panel > .table-bordered > tbody > tr:first-child > th, .panel > .table-bordered > thead > tr:first-child > td, .panel > .table-bordered > thead > tr:first-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th, .panel > .table-responsive > .table-bordered > thead > tr:first-child > td, .panel > .table-responsive > .table-bordered > thead > tr:first-child > th {
  border-bottom: 0; }

.panel > .table-bordered > tbody > tr:last-child > td, .panel > .table-bordered > tbody > tr:last-child > th, .panel > .table-bordered > tfoot > tr:last-child > td, .panel > .table-bordered > tfoot > tr:last-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0; }

.panel > .table-responsive {
  margin-bottom: 0;
  border: 0; }

.panel-group {
  margin-bottom: 20px; }

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px; }

.panel-group .panel + .panel {
  margin-top: 5px; }

.panel-group .panel-heading {
  border-bottom: 0; }

.panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 1px solid #ddd; }

.panel-group .panel-footer {
  border-top: 0; }

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd; }

.panel-default {
  border-color: #ddd; }

.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd; }

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd; }

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333; }

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd; }

.panel-primary {
  border-color: #337ab7; }

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7; }

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #337ab7; }

.panel-primary > .panel-heading .badge {
  color: #337ab7;
  background-color: #fff; }

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #337ab7; }

.panel-success {
  border-color: #d6e9c6; }

.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6; }

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6; }

.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d; }

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6; }

.panel-info {
  border-color: #bce8f1; }

.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1; }

.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f; }

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1; }

.panel-warning {
  border-color: #faebcc; }

.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; }

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc; }

.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b; }

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc; }

.panel-danger {
  border-color: #ebccd1; }

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1; }

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1; }

.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442; }

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-16by9 {
  padding-bottom: 56.25%; }

.embed-responsive-4by3 {
  padding-bottom: 75%; }

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }

.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15); }

.well-lg {
  padding: 24px;
  border-radius: 6px; }

.well-sm {
  padding: 9px;
  border-radius: 3px; }

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2; }

.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: .5; }

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0; }

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%); }

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0; }

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .5; }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5; }

.modal-header .close {
  margin-top: -2px; }

.modal-title {
  margin: 0;
  line-height: 1.42857143; }

.modal-body {
  position: relative;
  padding: 15px; }

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5; }

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px; }

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px; }

.modal-footer .btn-block + .btn-block {
  margin-left: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto; }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 900px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity=0);
  opacity: 0;
  line-break: auto; }

.tooltip.in {
  filter: alpha(opacity=90);
  opacity: .9; }

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px; }

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px; }

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px; }

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px; }

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000; }

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000; }

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto; }

.popover.top {
  margin-top: -10px; }

.popover.right {
  margin-left: 10px; }

.popover.bottom {
  margin-top: 10px; }

.popover.left {
  margin-left: -10px; }

.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0; }

.popover-content {
  padding: 9px 14px; }

.popover > .arrow, .popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.popover > .arrow {
  border-width: 11px; }

.popover > .arrow:after {
  content: "";
  border-width: 10px; }

.popover.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0; }

.popover.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0; }

.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0; }

.popover.right > .arrow:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: #fff;
  border-left-width: 0; }

.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.popover.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff; }

.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25); }

.popover.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #fff; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: .6s ease-in-out left;
  -o-transition: .6s ease-in-out left;
  transition: .6s ease-in-out left; }

.carousel-inner > .item > a > img, .carousel-inner > .item > img {
  line-height: 1; }

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
    -o-transition: -o-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px; }
  .carousel-inner > .item.active.right, .carousel-inner > .item.next {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .carousel-inner > .item.active.left, .carousel-inner > .item.prev {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .carousel-inner > .item.active, .carousel-inner > .item.next.left, .carousel-inner > .item.prev.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
  display: block; }

.carousel-inner > .active {
  left: 0; }

.carousel-inner > .next, .carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%; }

.carousel-inner > .next {
  left: 100%; }

.carousel-inner > .prev {
  left: -100%; }

.carousel-inner > .next.left, .carousel-inner > .prev.right {
  left: 0; }

.carousel-inner > .active.left {
  left: -100%; }

.carousel-inner > .active.right {
  left: 100%; }

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
  filter: alpha(opacity=50);
  opacity: .5; }

.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x; }

.carousel-control.right {
  right: 0;
  left: auto;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x; }

.carousel-control:focus, .carousel-control:hover {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: .9; }

.carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px; }

.carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
  left: 50%;
  margin-left: -10px; }

.carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
  right: 50%;
  margin-right: -10px; }

.carousel-control .icon-next, .carousel-control .icon-prev {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1; }

.carousel-control .icon-prev:before {
  content: '\2039'; }

.carousel-control .icon-next:before {
  content: '\203a'; }

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none; }

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000\9;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px; }

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }

.carousel-caption .btn {
  text-shadow: none; }

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px; }
  .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    margin-left: -10px; }
  .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-right: -10px; }
  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px; }
  .carousel-indicators {
    bottom: 20px; } }

.btn-group-vertical > .btn-group:after, .btn-group-vertical > .btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .modal-footer:after, .modal-footer:before, .modal-header:after, .modal-header:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
  display: table;
  content: " "; }

.btn-group-vertical > .btn-group:after, .btn-toolbar:after, .clearfix:after, .container-fluid:after, .container:after, .dl-horizontal dd:after, .form-horizontal .form-group:after, .modal-footer:after, .modal-header:after, .nav:after, .navbar-collapse:after, .navbar-header:after, .navbar:after, .pager:after, .panel-body:after, .row:after {
  clear: both; }

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.visible-lg, .visible-md, .visible-sm, .visible-xs {
  display: none !important; }

.visible-lg-block, .visible-lg-inline, .visible-lg-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  td.visible-xs, th.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  td.visible-sm, th.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  td.visible-md, th.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  td.visible-lg, th.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  td.visible-print, th.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }

@media print {
  .visible-print-block {
    display: block !important; } }

.visible-print-inline {
  display: none !important; }

@media print {
  .visible-print-inline {
    display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }

@media print {
  .visible-print-inline-block {
    display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

/*!
 * fullPage 3.0.8
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
.fp-enabled body, html.fp-enabled {
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.fp-slide {
  float: left; }

.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block; }

.fp-slides {
  z-index: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }

.fp-section.fp-table, .fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%; }

.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.fp-slidesContainer {
  float: left;
  position: relative; }

.fp-controlArrow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  position: absolute;
  z-index: 4;
  top: 50%;
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  margin-top: -38px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fp-controlArrow.fp-prev {
  left: 15px;
  width: 0;
  border-width: 38.5px 34px 38.5px 0;
  border-color: transparent #fff transparent transparent; }

.fp-controlArrow.fp-next {
  right: 15px;
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent #fff; }

.fp-scrollable {
  overflow: hidden;
  position: relative; }

.fp-scroller {
  overflow: hidden; }

.iScrollIndicator {
  border: 0 !important; }

.fp-notransition {
  -webkit-transition: none !important;
  transition: none !important; }

#fp-nav {
  position: fixed;
  z-index: 100;
  margin-top: -32px;
  top: 50%;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0); }

#fp-nav.fp-right {
  right: 17px; }

#fp-nav.fp-left {
  left: 17px; }

.fp-slidesNav {
  position: absolute;
  z-index: 4;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important; }

.fp-slidesNav.fp-bottom {
  bottom: 17px; }

.fp-slidesNav.fp-top {
  top: 17px; }

#fp-nav ul, .fp-slidesNav ul {
  margin: 0;
  padding: 0; }

#fp-nav ul li, .fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative; }

.fp-slidesNav ul li {
  display: inline-block; }

#fp-nav ul li a, .fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none; }

#fp-nav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li a.active span, .fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%; }

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out; }

#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px; }

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial,helvetica,sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer; }

#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
  width: auto;
  opacity: 1; }

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px; }

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px; }

.fp-auto-height .fp-slide, .fp-auto-height .fp-tableCell, .fp-auto-height.fp-section {
  height: auto !important; }

.fp-responsive .fp-auto-height-responsive .fp-slide, .fp-responsive .fp-auto-height-responsive .fp-tableCell, .fp-responsive .fp-auto-height-responsive.fp-section {
  height: auto !important; }

.fp-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/*# sourceMappingURL=fullpage.min.css.map */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

.flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand; }

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

@keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip; }

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY; }

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight; }

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: hinge;
  animation-name: hinge; }

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

.jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox; }

@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

@-webkit-keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

@keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight; }

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp; }

@-webkit-keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown; }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft; }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight; }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp; }

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown; }

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav {
  margin-top: 10px; }

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

/*Lib*/
/*$color-alternatifbank:				#9E0D49;*/
html {
  min-height: 100%;
  min-width: 100%; }

body {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  min-width: 100%;
  background: #FAFAFA; }

/*reset erd*/
h1,
h2,
h3,
h4,
h5,
h6,
th,
p,
label,
address,
figure {
  font-weight: normal;
  margin: 0; }

a {
  text-decoration: none !important; }

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0; }

input,
select,
textarea,
button,
a {
  outline: none !important;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0; }

i {
  display: inline-block;
  vertical-align: middle;
  font-style: normal; }

nav {
  background: none;
  border: none;
  margin: 0;
  min-height: auto !important; }
  nav button.navbar-toggle {
    background: none !important;
    border: none !important; }
  nav ul li a {
    padding: 0 !important; }

.navbar-collapse {
  border: none !important;
  box-shadow: 0 0 0 0 !important;
  padding: 0; }

.navbar-brand {
  padding: 0;
  height: auto; }

.dropdown > button {
  box-shadow: 0 0 0 0 !important; }

.owl-carousel {
  touch-action: none; }

/*reset erd*/
/*-------------------------------page-selection-------------------------------*/
::selection {
  background: #0000E6;
  color: #FFFFFF; }

/*-------------------------------page-selection-------------------------------*/
/*-------------------------------navbar-media-------------------------------*/
@media (max-width: 991px) {
  header {
    height: auto;
    min-width: 320px; }
    header .navbar-header {
      float: none; }
    header .navbar-toggle {
      display: block; }
    header .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
      max-width: 100% !important; }
      header .navbar-collapse.collapse {
        display: none !important;
        overflow-y: auto !important; }
    header .navbar-nav {
      float: none !important;
      margin: 0; }
      header .navbar-nav > li {
        float: none; }
        header .navbar-nav > li > a {
          padding-top: 10px;
          padding-bottom: 10px; }
    header .navbar-text {
      float: none;
      margin: 15px 0; }
    header .navbar-collapse.collapse.in {
      display: block !important; }
    header .collapsing {
      overflow: hidden !important; } }

.navbar-inverse {
  border: none;
  border-radius: 0;
  background: none;
  margin: 0; }
  .navbar-inverse button.navbar-toggle {
    margin: 0; }
  .navbar-inverse li.active a {
    background: none !important; }

/*-------------------------------navbar-media-------------------------------*/
/*-------------------------------carousel-fade-------------------------------*/
.carousel-fade .carousel-inner .item {
  transition-property: opacity; }

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0; }

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1; }

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0); }

.carousel-fade .carousel-control {
  z-index: 2; }

/*-------------------------------carousel-fade-------------------------------*/
/*-------------------------------figure-bg-class-------------------------------*/
.back {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important; }

/*-------------------------------figure-bg-class-------------------------------*/
/*-------------------------------center-------------------------------*/
.center {
  max-width: 1180px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }
  @media (max-width: 1400px) {
    .center {
      max-width: 1050px; } }

/*-------------------------------center-------------------------------*/
/*-------------------------------transition-------------------------------*/
/*-------------------------------transition-------------------------------*/
/*-------------------------------styleguide-------------------------------*/
.hero {
  font-family: Metric-Bold;
  font-size: 8rem;
  line-height: 9.6rem; }

h1 {
  font-size: 7rem;
  line-height: 8.4rem; }
  @media (max-width: 1249.98px) {
    h1 {
      font-size: 5rem;
      line-height: 6.4rem; } }
  @media (max-width: 991.98px) {
    h1 {
      font-size: 3.8rem;
      line-height: 4.6rem; } }

h2 {
  font-size: 5.6rem;
  line-height: 6.7rem;
  /*@media (max-width: $sm){
      @include font-size(38,46)
    }*/ }

h3 {
  font-size: 4.4rem;
  line-height: 5.3rem; }

h4 {
  font-size: 2.4rem;
  line-height: 2.9rem; }
  @media (max-width: 991.98px) {
    h4 {
      font-size: 2rem;
      line-height: 2.4rem; } }

h5 {
  font-size: 2rem;
  line-height: 1.6rem; }

p {
  font-size: 1.6rem;
  line-height: 1.9rem; }

.tagline {
  font-size: 2rem;
  line-height: 1.6rem; }

.notes {
  font-size: 1.4rem;
  line-height: 1.7rem; }

/*-------------------------------styleguide-------------------------------*/
/*-------------------------------noise-------------------------------*/
@keyframes noiseAnimation {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0); }
  10% {
    -webkit-transform: translate(-5%, -5%);
    transform: translate(-5%, -5%); }
  20% {
    -webkit-transform: translate(-10%, 5%);
    transform: translate(-10%, 5%); }
  30% {
    -webkit-transform: translate(5%, -10%);
    transform: translate(5%, -10%); }
  40% {
    -webkit-transform: translate(-5%, 15%);
    transform: translate(-5%, 15%); }
  50% {
    -webkit-transform: translate(-10%, 5%);
    transform: translate(-10%, 5%); }
  60% {
    -webkit-transform: translate(15%);
    transform: translate(15%); }
  70% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%); }
  80% {
    -webkit-transform: translate(-15%);
    transform: translate(-15%); }
  90% {
    -webkit-transform: translate(10%, 5%);
    transform: translate(10%, 5%); }
  to {
    -webkit-transform: translate(5%);
    transform: translate(5%); } }

/*-------------------------------noise-------------------------------*/
@font-face {
  font-family: 'Metric-Bold';
  src: url("../fonts/metric-bold.eot");
  src: url("../fonts/metric-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/metric-bold.woff2") format("woff2"), url("../fonts/metric-bold.woff") format("woff"), url("../fonts/metric-bold.ttf") format("truetype"), url("../fonts/metric-bold.svg#Metric") format("svg"); }

@font-face {
  font-family: 'Metric-Medium';
  src: url("../fonts/metric-medium.eot");
  src: url("../fonts/metric-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/metric-medium.woff2") format("woff2"), url("../fonts/metric-medium.woff") format("woff"), url("../fonts/metric-medium.ttf") format("truetype"), url("../fonts/metric-medium.svg#Metric") format("svg"); }

@font-face {
  font-family: 'Metric-Regular';
  src: url("../fonts/metric-regular.eot");
  src: url("../fonts/metric-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/metric-regular.woff2") format("woff2"), url("../fonts/metric-regular.woff") format("woff"), url("../fonts/metric-regular.ttf") format("truetype"), url("../fonts/metric-regular.svg#Metric") format("svg"); }

@font-face {
  font-family: 'Metric-Thin';
  src: url("../fonts/metric-thin.eot");
  src: url("../fonts/metric-thin.eot?#iefix") format("embedded-opentype"), url("../fonts/metric-thin.woff2") format("woff2"), url("../fonts/metric-thin.woff") format("woff"), url("../fonts/metric-thin.ttf") format("truetype"), url("../fonts/metric-thin.svg#Metric") format("svg"); }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?t1k8qq");
  src: url("../fonts/icomoon.eot?t1k8qq#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?t1k8qq") format("truetype"), url("../fonts/icomoon.woff?t1k8qq") format("woff"), url("../fonts/icomoon.svg?t1k8qq#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-left:before {
  content: "\e90f"; }

.icon-arrow-right:before {
  content: "\e900"; }

.icon-arrow-top-right:before {
  content: "\e901"; }

.icon-behance:before {
  content: "\e902"; }

.icon-close:before {
  content: "\e903"; }

.icon-dribbble:before {
  content: "\e904"; }

.icon-facebook:before {
  content: "\e905"; }

.icon-figure-1:before {
  content: "\e906"; }

.icon-figure-2:before {
  content: "\e907"; }

.icon-figure-3:before {
  content: "\e908"; }

.icon-instagram:before {
  content: "\e909"; }

.icon-linkedin:before {
  content: "\e90a"; }

.icon-look:before {
  content: "\e90b"; }

.icon-medium:before {
  content: "\e90c"; }

.icon-play-button:before {
  content: "\e90d"; }

.icon-twitter:before {
  content: "\e90e"; }

.noise {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
  .noise:after {
    content: "";
    position: absolute;
    z-index: -1;
    height: 180%;
    left: -40%;
    top: -40%;
    width: 180%;
    pointer-events: none;
    will-change: transform;
    transition: opacity .6s ease-out;
    -webkit-animation: noiseAnimation 1s steps(4) infinite;
    animation: noiseAnimation 1s steps(4) infinite;
    background-image: url(../images/content/look-noise.png);
    opacity: 0.85; }

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000E6;
  z-index: 99;
  transform: translate3d(0, 0, 0);
  display: none; }
  #loader #hello-bar {
    max-width: 460px;
    max-height: 168px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

#testanim {
  max-width: 460px;
  max-height: 168px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: -5%;
  background: #0000E6; }

/*************************header*************************/
header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  -webkit-transition: .3s ease-in-out;
  -moz-transition: .3s ease-in-out;
  -ms-transition: .3s ease-in-out;
  -o-transition: .3s ease-in-out;
  transition: .3s ease-in-out; }
  @media (max-width: 991.98px) {
    header {
      background: #FAFAFA; } }
  @media (max-width: 991.98px) {
    header.detail-header, header.blue-header {
      background: var(--background-color); } }
  header.detail-header:before, header.blue-header:before {
    height: 100%; }
  header.detail-header .wrap > div a.menu-open, header.blue-header .wrap > div a.menu-open {
    color: #FFFFFF; }
    header.detail-header .wrap > div a.menu-open i:before, header.detail-header .wrap > div a.menu-open i:after, header.blue-header .wrap > div a.menu-open i:before, header.blue-header .wrap > div a.menu-open i:after {
      background: #FFFFFF; }
  header.detail-header .wrap > div i.icon-look, header.blue-header .wrap > div i.icon-look {
    color: #FFFFFF; }
  @media (max-width: 991.98px) {
    header.blue-header {
      background: #0000E6; } }
  header .wrap {
    position: relative;
    display: table;
    width: 100%;
    padding: 45px 61px 41px 60px; }
    @media (max-width: 991.98px) {
      header .wrap {
        padding: 5px 20px; } }
    header .wrap > div {
      width: 50%;
      float: left;
      position: relative;
      z-index: 10; }
      header .wrap > div a {
        pointer-events: all; }
        header .wrap > div a.menu-open {
          font-family: Metric-Medium;
          font-size: 2rem;
          line-height: 2.4rem;
          color: #1A1A1A;
          padding-left: 66px;
          position: relative;
          display: inline-block;
          margin-top: 18px; }
          header .wrap > div a.menu-open i {
            pointer-events: none;
            width: 50px;
            height: 18px;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -9px; }
            header .wrap > div a.menu-open i:before, header .wrap > div a.menu-open i:after {
              content: "";
              position: absolute;
              left: 0;
              width: 100%;
              height: 4px;
              border-radius: 3px;
              background: #1A1A1A; }
            header .wrap > div a.menu-open i:before {
              top: 0; }
            header .wrap > div a.menu-open i:after {
              bottom: 0; }
          header .wrap > div a.menu-open span {
            pointer-events: none; }
        header .wrap > div a.header-logo {
          display: inline-block;
          float: right; }
      header .wrap > div i.icon-look {
        font-size: 61px;
        color: #0000E6;
        float: right;
        pointer-events: none; }

.menu-panel {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0000E6;
  left: 0;
  bottom: 100%;
  min-height: 600px;
  z-index: 20; }
  @media (max-width: 991.98px) {
    .menu-panel {
      min-height: auto; } }
  .menu-panel > * {
    opacity: 0; }
  .menu-panel .wrap {
    position: relative;
    display: table;
    width: 100%;
    padding: 45px 61px 41px 60px; }
    @media (max-width: 991.98px) {
      .menu-panel .wrap {
        padding: 5px 20px; } }
    .menu-panel .wrap > div {
      width: 50%;
      float: left; }
      .menu-panel .wrap > div a.menu-close {
        display: inline-block;
        pointer-events: all; }
      .menu-panel .wrap > div a i.icon-close {
        font-size: 36.53px;
        color: #FFFFFF;
        margin-top: 5px;
        pointer-events: none; }
        @media (max-width: 991.98px) {
          .menu-panel .wrap > div a i.icon-close {
            margin-top: 15px;
            font-size: 31px; } }
      .menu-panel .wrap > div i.icon-look.icon-look {
        font-size: 61px;
        color: #0000E6;
        float: right; }
        .menu-panel .wrap > div i.icon-look.icon-look:before {
          color: #FFFFFF; }
  .menu-panel nav {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    padding-left: 200px; }
    @media (max-width: 991.98px) {
      .menu-panel nav {
        padding-left: 20px;
        width: 100%;
        transform: translate(0, 0);
        top: 84px; } }
    .menu-panel nav ul {
      display: table; }
      @media (max-width: 991.98px) {
        .menu-panel nav ul {
          width: 100%; } }
      .menu-panel nav ul li {
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -ms-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: .3s ease-in-out; }
        .menu-panel nav ul li:not(:last-child) {
          padding-bottom: 36px; }
          @media (max-height: 850px) {
            .menu-panel nav ul li:not(:last-child) {
              padding-bottom: 17px !important; } }
          @media (max-height: 768px) {
            .menu-panel nav ul li:not(:last-child) {
              padding-bottom: 0 !important; } }
        .menu-panel nav ul li:nth-child(1) a i:before {
          content: '01'; }
        .menu-panel nav ul li:nth-child(2) a:before {
          /*content:url(../images/menu/what-we-do.png);*/
          content: "";
          position: absolute;
          background: url(../images/menu/what-we-do.png) no-repeat;
          background-size: 100% auto;
          width: 310px;
          height: 370px;
          left: 70%; }
        .menu-panel nav ul li:nth-child(2) a i:before {
          content: '02'; }
        .menu-panel nav ul li:nth-child(3) a:before {
          /*content:url(../images/menu/work.png);*/
          content: "";
          position: absolute;
          background: url(../images/menu/work.png) no-repeat;
          background-size: auto 100%;
          width: 350px;
          height: 370px; }
        .menu-panel nav ul li:nth-child(3) a i:before {
          content: '03'; }
        .menu-panel nav ul li:nth-child(4) a:before {
          /*content:url(../images/menu/team.png);*/
          content: "";
          position: absolute;
          background: url(../images/menu/team.png) no-repeat;
          background-size: 100% auto;
          width: 370px;
          height: 370px; }
        .menu-panel nav ul li:nth-child(4) a i:before {
          content: '04'; }
        .menu-panel nav ul li:nth-child(5) a:before {
          /*content:url(../images/menu/contactt.png);
								top: 100%;
								transform:translate(-20%,-64%);*/
          content: "";
          position: absolute;
          background: url(../images/menu/contact.png) no-repeat;
          background-size: 100% auto;
          width: 400px;
          height: 340px; }
        .menu-panel nav ul li:nth-child(5) a i:before {
          content: '05'; }
        .menu-panel nav ul li a {
          font-family: Metric-Medium;
          font-size: 7rem;
          line-height: 8.4rem;
          -webkit-transition: .3s ease-in-out;
          -moz-transition: .3s ease-in-out;
          -ms-transition: .3s ease-in-out;
          -o-transition: .3s ease-in-out;
          transition: .3s ease-in-out;
          color: #FFFFFF;
          position: relative;
          pointer-events: all; }
          .menu-panel nav ul li a > * {
            pointer-events: none; }
          @media (max-height: 768px) {
            .menu-panel nav ul li a {
              font-size: 6rem;
              line-height: 7.4rem; } }
          @media (max-width: 991.98px) {
            .menu-panel nav ul li a {
              font-size: 5.2rem;
              line-height: 6.2rem;
              display: block; } }
          @media (max-width: 374.98px) {
            .menu-panel nav ul li a {
              font-size: 4rem;
              line-height: 5rem; } }
          .menu-panel nav ul li a span {
            position: relative;
            z-index: 2; }
          .menu-panel nav ul li a:before {
            position: absolute;
            left: 100%;
            top: 100%;
            transform: translate(-20%, -50%);
            z-index: -1;
            opacity: 0;
            -webkit-transition: 0.4s ease-in-out;
            -moz-transition: 0.4s ease-in-out;
            -ms-transition: 0.4s ease-in-out;
            -o-transition: 0.4s ease-in-out;
            transition: 0.4s ease-in-out; }
            @media (max-width: 991.98px) {
              .menu-panel nav ul li a:before {
                content: none !important; } }
          .menu-panel nav ul li a i {
            display: inline-block;
            margin-right: 30px;
            line-height: 0;
            width: 20px;
            height: 20px;
            position: relative;
            overflow: hidden; }
            @media (max-width: 991.98px) {
              .menu-panel nav ul li a i {
                right: 20px;
                top: 50%;
                margin-top: -10px;
                position: absolute;
                margin-right: 0; } }
            .menu-panel nav ul li a i:before {
              font-size: 2rem;
              line-height: 2.4rem;
              position: absolute;
              top: 100%;
              -webkit-transition: 0.4s ease-in-out;
              -moz-transition: 0.4s ease-in-out;
              -ms-transition: 0.4s ease-in-out;
              -o-transition: 0.4s ease-in-out;
              transition: 0.4s ease-in-out; }
              @media (max-width: 991.98px) {
                .menu-panel nav ul li a i:before {
                  top: 0; } }
          .menu-panel nav ul li a:hover:before {
            top: 50%;
            opacity: 1; }
          .menu-panel nav ul li a:hover i:before {
            top: 0; }
  .menu-panel .bottom {
    padding: 0 60px 60px 60px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%; }
    @media (max-width: 991.98px) {
      .menu-panel .bottom {
        padding: 0 20px 20px 20px; } }
    .menu-panel .bottom > div {
      width: 50%;
      float: left; }
      @media (max-width: 991.98px) {
        .menu-panel .bottom > div {
          width: 100%;
          float: none; } }
      .menu-panel .bottom > div a {
        font-family: Metric-Medium;
        font-size: 2rem;
        line-height: 2.4rem;
        color: #FFFFFF; }
        @media (max-width: 991.98px) {
          .menu-panel .bottom > div a {
            display: table;
            margin: 0 auto; } }
      .menu-panel .bottom > div ul {
        display: table;
        float: right; }
        @media (max-width: 991.98px) {
          .menu-panel .bottom > div ul {
            float: none;
            margin: 80px auto 0 auto; } }
        @media (max-width: 575.98px) {
          .menu-panel .bottom > div ul {
            margin-top: 50px; } }
        .menu-panel .bottom > div ul li {
          display: inline-block;
          float: left; }
          .menu-panel .bottom > div ul li:not(:last-child) {
            padding-right: 30px; }

/*************************header*************************/
/*************************lookPage*************************/
@media (max-width: 991.98px) {
  .section {
    overflow: hidden; } }

.section:first-child {
  padding-top: 147px; }
  @media (max-width: 991.98px) {
    .section:first-child {
      padding-top: 71px; } }
  .section:first-child .fp-tableCell {
    vertical-align: top;
    padding: 0 14px; }
    @media (max-width: 991.98px) {
      .section:first-child .fp-tableCell {
        padding: 0; } }
  @media (max-width: 991.98px) {
    .section:first-child .center {
      /*padding-bottom: 70px;*/
      overflow: hidden; } }

.section:last-child .fp-tableCell {
  vertical-align: bottom; }

@media (max-width: 991.98px) {
  .section .fp-tableCell {
    vertical-align: top; } }

@media (max-width: 991.98px) {
  .section .center {
    padding: 0 20px; } }

@media (max-width: 1249.98px) {
  .center {
    max-width: 950px; } }

@media (max-width: 991.98px) {
  .center {
    max-width: 100%; } }

/*************************lookPage*************************/
/*************************main-slider*************************/
.main-slider {
  min-height: 540px;
  position: relative;
  /*padding-top: 32px;*/ }
  .main-slider .slide-figure {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    display: none; }
    @media (max-width: 991.98px) {
      .main-slider .slide-figure {
        top: 170px;
        right: -50px; } }
    .main-slider .slide-figure figure {
      width: 534px;
      height: auto;
      text-align: right;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: .3s ease-in-out; }
      @media (max-height: 768px) {
        .main-slider .slide-figure figure {
          /*height: 400px;*/
          width: 400px; } }
      @media (max-width: 1100px) {
        .main-slider .slide-figure figure {
          width: 400px; } }
      @media (max-width: 767.98px) {
        .main-slider .slide-figure figure {
          width: 300px; } }
      @media (max-width: 374.98px) {
        .main-slider .slide-figure figure {
          width: 250px; } }
      .main-slider .slide-figure figure img {
        max-width: 100%;
        max-height: 550px; }
    .main-slider .slide-figure i.def-icon-border-bar {
      width: 27px;
      height: 40px;
      background: url(../images/svg/border-bar.svg) no-repeat;
      background-size: cover;
      position: absolute;
      top: 100%;
      margin-top: 12px;
      right: 87px;
      left: auto !important;
      top: auto !important; }
    .main-slider .slide-figure i.def-icon-two-border {
      width: 60px;
      height: 55px;
      background: url(../images/svg/two-border.svg) no-repeat;
      background-size: cover;
      position: absolute;
      top: 100%;
      margin-top: 84px;
      right: 171px;
      left: auto !important;
      top: auto !important; }
    @media (max-width: 991.98px) {
      .main-slider .slide-figure i {
        display: none !important; } }
  .main-slider .slide-caption {
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out; }
    @media (max-height: 850px) {
      .main-slider .slide-caption {
        padding-top: 10px !important; } }
    @media (max-width: 991.98px) {
      .main-slider .slide-caption {
        padding-top: 14px !important; } }
    .main-slider .slide-caption h1 {
      padding-left: 25px;
      font-family: Metric-Bold;
      color: #1A1A1A;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      opacity: 0;
      position: relative; }
      .main-slider .slide-caption h1 span {
        display: table;
        position: relative; }
        @media (max-width: 575.98px) {
          .main-slider .slide-caption h1 span {
            display: inline-block; } }
      @media (max-width: 991.98px) {
        .main-slider .slide-caption h1 {
          font-size: 5.2rem;
          line-height: 6.2rem;
          padding-left: 0; } }
      @media (max-width: 374.98px) {
        .main-slider .slide-caption h1 {
          font-size: 4rem;
          line-height: 4.5rem; } }
    .main-slider .slide-caption i {
      z-index: -1; }
      .main-slider .slide-caption i.def-icon-cube-one {
        width: 50px;
        height: 35px;
        background: #E7C300;
        position: absolute;
        left: -25px;
        top: 5px;
        opacity: 0.5; }
      .main-slider .slide-caption i.def-icon-cube-two {
        width: 33px;
        height: 42px;
        background: #5AD52C;
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0.3; }
        @media (max-width: 767.98px) {
          .main-slider .slide-caption i.def-icon-cube-two {
            left: 0;
            right: auto; } }
      .main-slider .slide-caption i.def-icon-cube-center {
        width: 33px;
        height: 42px;
        background: #5AD52C;
        opacity: 0.5;
        position: absolute;
        bottom: -10px;
        left: 60%;
        margin-left: -16.5px; }
        @media (max-width: 767.98px) {
          .main-slider .slide-caption i.def-icon-cube-center {
            left: 0; } }
  .main-slider .carousel .item:first-child .slide-caption {
    padding-top: 122px; }
  .main-slider .carousel .item:last-child .slide-caption {
    padding-top: 61px; }
  .main-slider .carousel .item.active .slide-caption h1 {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn; }

.ear-figure {
  position: absolute;
  display: table;
  bottom: 77px !important;
  top: auto !important;
  -webkit-transition: .3s ease-in-out;
  -moz-transition: .3s ease-in-out;
  -ms-transition: .3s ease-in-out;
  -o-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  /*width: 494px;
	height: 310px;*/
  width: 584px;
  height: 328px;
  background: url(../images/content/slide-figure.png) no-repeat;
  background-size: 100%;
  /*@media (max-width: $extra_xs){
		display:none;
	}*/ }
  @media (max-width: 1249.98px) {
    .ear-figure {
      width: 400px;
      height: 228px; } }
  @media (max-width: 991.98px) {
    .ear-figure {
      height: 180px;
      width: 312px; } }
  @media (max-width: 575.98px) {
    .ear-figure {
      height: 160px;
      height: 205px; } }
  @media (max-height: 850px) {
    .ear-figure {
      bottom: 20px; } }
  @media (max-width: 991.98px) {
    .ear-figure {
      top: 50%;
      bottom: auto;
      margin-top: -125px; } }
  @media (max-width: 575.98px) {
    .ear-figure {
      display: none;
      top: auto;
      margin: 0;
      bottom: 160px;
      left: -70px; } }

.fixed-element {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%; }
  .fixed-element .def-icon-zigzag {
    display: none;
    width: 52px;
    height: 233px;
    position: absolute;
    left: 14px;
    bottom: 127px;
    background: url(../images/svg/zigzag-icon.svg) no-repeat center;
    background-size: cover; }
    @media (max-height: 768px) {
      .fixed-element .def-icon-zigzag {
        height: 190px;
        width: 43px; } }
    @media (max-width: 991.98px) {
      .fixed-element .def-icon-zigzag {
        width: 30px;
        height: 132px;
        bottom: 68px !important; } }
  .fixed-element a.contact-url {
    font-family: Metric-Medium;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #BBBBBB;
    position: absolute;
    left: 60px;
    bottom: 60px;
    z-index: 9; }
    @media (max-width: 991.98px) {
      .fixed-element a.contact-url {
        left: 20px;
        bottom: 30px; } }

.scroll-text {
  font-family: Metric-Regular;
  color: #1A1A1A;
  font-size: 2rem;
  line-height: 2.4rem;
  transform: rotate(270deg);
  display: table;
  position: absolute;
  right: 55px;
  bottom: 93px; }
  .scroll-text:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 14px;
    height: 1px;
    width: 78px;
    background: #1A1A1A;
    margin-right: 6px; }
  @media (max-width: 991.98px) {
    .scroll-text {
      display: none; } }

/*************************main-slider*************************/
/*************************page-element*************************/
.scroll {
  width: 1px;
  height: 215px;
  background: #1A1A1A;
  position: absolute;
  right: 73px;
  bottom: 0;
  opacity: 0; }
  .scroll span {
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 80%;
    background: #FFFFFF; }
  @media (max-width: 991.98px) {
    .scroll {
      display: none; } }

.web-page-name {
  font-family: Metric-Regular;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #1A1A1A;
  display: inline-block;
  position: absolute;
  left: 60px;
  top: 100%;
  opacity: 0;
  transform: rotate(270deg);
  transform: rotate(270deg) translate(-50%, 0);
  transform-origin: 0 0;
  transform: perspective(1px) translateZ(0px) rotate(270deg); }
  @media (max-width: 991.98px) {
    .web-page-name {
      display: none; } }

.page-name {
  font-family: Metric-Regular;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #1A1A1A;
  display: inline-block;
  position: absolute;
  left: 60px;
  top: 50%;
  transform: rotate(270deg);
  transform: rotate(270deg) translate(-50%, 0);
  transform-origin: 0 0;
  transform: perspective(1px) translateZ(0px) rotate(270deg); }
  @media (max-width: 991.98px) {
    .page-name {
      position: initial;
      transform: rotate(0deg);
      left: 0;
      width: 100%;
      top: auto;
      margin-top: 70px;
      padding-left: 20px;
      margin-bottom: 10px; } }

i.def-icon-narrow-plus {
  width: 35px;
  height: 180px;
  background: url(../images/svg/narrow-plus.svg) no-repeat;
  position: absolute;
  right: 72px;
  bottom: 0;
  opacity: 0; }
  @media (max-width: 991.98px) {
    i.def-icon-narrow-plus {
      display: none; } }

/*************************page-element*************************/
/*************************what-we-do*************************/
.section-what-we-do .page-name {
  display: none; }
  @media (max-width: 991.98px) {
    .section-what-we-do .page-name {
      display: block; } }

.what-we-do {
  padding: 0 98px;
  position: relative;
  display: table; }
  @media (max-width: 991.98px) {
    .what-we-do {
      padding: 0; } }
  .what-we-do .wrap > div {
    float: left; }
    .what-we-do .wrap > div:first-child {
      width: 54%; }
    .what-we-do .wrap > div:last-child {
      width: 46%; }
    @media (max-width: 991.98px) {
      .what-we-do .wrap > div {
        width: 100% !important;
        float: none; } }
  .what-we-do .wrap h2 {
    font-family: Metric-Bold;
    color: #1A1A1A; }
    @media (max-width: 991.98px) {
      .what-we-do .wrap h2 {
        font-size: 3.8rem;
        line-height: 4.6rem; } }
  .what-we-do .wrap p {
    font-family: Metric-Regular;
    margin: 18px 0 33px;
    font-size: 2.4rem;
    line-height: 3.2rem; }
    @media (max-width: 991.98px) {
      .what-we-do .wrap p {
        font-size: 1.8rem;
        line-height: 2.2rem; } }
  .what-we-do .wrap a {
    font-family: Metric-Medium;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #0000E6; }
    @media (max-width: 991.98px) {
      .what-we-do .wrap a {
        font-size: 1.6rem;
        line-height: 1.9rem; } }
  .what-we-do .wrap figure {
    position: absolute;
    right: 0;
    top: 100%;
    opacity: 0;
    transform: translate(0, -50%);
    width: 600px;
    height: 440px;
    background-size: cover !important; }
    @media (max-width: 991.98px) {
      .what-we-do .wrap figure {
        top: 100%;
        transform: translate(15%, -20%);
        z-index: -1; } }
    @media (max-width: 767.98px) {
      .what-we-do .wrap figure {
        width: 450px;
        height: 330px;
        top: 100% !important; } }
    @media (max-width: 374.98px) {
      .what-we-do .wrap figure {
        top: 60% !important; } }
    .what-we-do .wrap figure.v1 {
      background: url(../images/content/strategy.svg) no-repeat center; }
    .what-we-do .wrap figure.v2 {
      background: url(../images/content/design.svg) no-repeat center; }
    .what-we-do .wrap figure.v3 {
      background: url(../images/content/technology.svg) no-repeat center; }

/*************************what-we-do*************************/
/*************************get-to-know-us*************************/
.know-us {
  max-width: 946px;
  width: 100%;
  margin: 0 auto; }
  @media (max-height: 768px) {
    .know-us {
      max-width: 700px; } }
  @media (max-width: 1249.98px) {
    .know-us {
      max-width: 700px; } }
  .know-us .video-player {
    position: relative; }
    @media (max-width: 991.98px) {
      .know-us .video-player {
        margin-top: 50px; } }
    .know-us .video-player i.def-icon-full-plus {
      position: absolute;
      left: -51px;
      top: 100px;
      width: 201px;
      height: 180px;
      background: url(../images/svg/full-plus.svg) no-repeat;
      background-size: cover;
      opacity: 0;
      z-index: -1; }
      @media (max-width: 991.98px) {
        .know-us .video-player i.def-icon-full-plus {
          left: -41px; } }
    .know-us .video-player i.def-icon-arrow-video {
      position: absolute;
      width: 378px;
      height: 173px;
      right: -47px;
      bottom: -127px;
      background: url(../images/content/video-figure.png) no-repeat;
      background-size: cover;
      z-index: -1; }
      @media (max-width: 374.98px) {
        .know-us .video-player i.def-icon-arrow-video {
          display: none; } }
    .know-us .video-player figure {
      height: 543px;
      border-radius: 8px;
      position: relative;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      top: 200px;
      opacity: 0;
      display: none; }
      @media (max-height: 768px) {
        .know-us .video-player figure {
          height: 453px; } }
      @media (max-width: 1249.98px) {
        .know-us .video-player figure {
          height: 453px; } }
      @media (max-width: 991.98px) {
        .know-us .video-player figure {
          display: block; } }
      @media (max-width: 575.98px) {
        .know-us .video-player figure {
          height: 350px; } }
      @media (max-width: 374.98px) {
        .know-us .video-player figure {
          height: 250px; } }
      .know-us .video-player figure:before {
        content: '';
        position: absolute;
        width: 82px;
        height: 82px;
        background: #FFFFFF;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        margin-top: -41px;
        margin-left: -41px; }
      .know-us .video-player figure:after {
        content: '\e90d';
        position: absolute;
        font-family: 'icomoon';
        font-size: 26px;
        color: #0000E6;
        top: 50%;
        left: 50%;
        margin-left: -10px;
        margin-top: -12px;
        line-height: 1; }
  .know-us iframe {
    width: 100%;
    height: 543px;
    /*@media (max-width: $middle_xs){
			height: 350px;
		}
		@media (max-width: $extra_xs){
			height: 250px;
		}*/ }
    @media (max-height: 768px) {
      .know-us iframe {
        height: 453px; } }
    @media (max-width: 1249.98px) {
      .know-us iframe {
        height: 453px; } }
    @media (max-width: 991.98px) {
      .know-us iframe {
        display: none; } }

/*************************get-to-know-us*************************/
/*************************main-works*************************/
.page-name a.all-url-mobile {
  font-family: Metric-Medium;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #0000E6;
  position: absolute;
  right: 20px;
  display: none;
  top: 0; }
  @media (max-width: 991.98px) {
    .page-name a.all-url-mobile {
      display: block; } }
  .page-name a.all-url-mobile i {
    vertical-align: inherit;
    line-height: 0;
    margin-left: 5px; }
    .page-name a.all-url-mobile i:before {
      color: #0000E6;
      font-size: 12px; }

@media (max-width: 767.98px) {
  .work-center {
    padding: 0 !important; } }

.work {
  position: relative; }
  @media (max-width: 1400px) {
    .work {
      max-width: 980px;
      margin: 0 auto; } }
  @media (max-width: 1100px) {
    .work {
      max-width: 830px; } }
  @media (max-height: 768px) {
    .work {
      max-width: 830px; } }
  .work a.all-project {
    font-family: Metric-Medium;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #0000E6;
    position: absolute;
    left: 0;
    bottom: 80px;
    z-index: 1; }
    @media (max-width: 991.98px) {
      .work a.all-project {
        display: none; } }

.work-slide .wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center; }
  @media (max-width: 991.98px) {
    .work-slide .wrap {
      display: block !important; } }
  .work-slide .wrap > div {
    position: relative;
    width: 58%;
    float: left; }
    .work-slide .wrap > div.information {
      width: 41%;
      right: 58%; }
    .work-slide .wrap > div.image {
      width: 58%;
      left: 41%; }
      @media (max-width: 991.98px) {
        .work-slide .wrap > div.image {
          text-align: center; } }
    @media (max-width: 991.98px) {
      .work-slide .wrap > div {
        width: 100% !important;
        left: auto !important;
        right: auto !important;
        float: none !important; } }

.work-slide .owl-carousel .owl-stage-outer {
  padding: 45px 0 80px 0; }
  @media (max-width: 991.98px) {
    .work-slide .owl-carousel .owl-stage-outer {
      padding: 0 0 50px 0; } }
  @media (max-width: 374.98px) {
    .work-slide .owl-carousel .owl-stage-outer {
      padding: 0; } }

@media (max-width: 767.98px) {
  .work-slide .owl-carousel .owl-stage {
    padding: 0 0 0 20px !important; } }

.work-slide .owl-carousel .owl-nav {
  margin: 0;
  max-width: 76px;
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
  height: 31px; }
  @media (max-width: 991.98px) {
    .work-slide .owl-carousel .owl-nav {
      left: 50%;
      margin-left: -100px; } }
  @media (max-width: 374.98px) {
    .work-slide .owl-carousel .owl-nav {
      display: none; } }
  .work-slide .owl-carousel .owl-nav .owl-prev, .work-slide .owl-carousel .owl-nav .owl-next {
    transition: .3s ease-in-out; }
  .work-slide .owl-carousel .owl-nav .owl-prev {
    left: 0; }
    .work-slide .owl-carousel .owl-nav .owl-prev:before {
      content: "\e90f";
      font-family: 'icomoon';
      left: 0;
      color: #0000E6; }
  .work-slide .owl-carousel .owl-nav .owl-next {
    right: 0; }
    .work-slide .owl-carousel .owl-nav .owl-next:before {
      content: "\e900";
      font-family: 'icomoon';
      right: 0;
      color: #0000E6; }

.work-slide .owl-carousel .owl-dots {
  counter-reset: slides-num;
  position: absolute;
  bottom: 40px;
  left: 19px; }
  @media (max-width: 991.98px) {
    .work-slide .owl-carousel .owl-dots {
      left: 50%;
      margin-left: -4px; } }
  @media (max-width: 374.98px) {
    .work-slide .owl-carousel .owl-dots {
      display: none; } }
  .work-slide .owl-carousel .owl-dots:before {
    content: " / ";
    position: absolute;
    left: 0;
    top: 0;
    font-family: Metric-Bold;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #1A1A1A; }
  .work-slide .owl-carousel .owl-dots:after {
    content: counter(slides-num);
    display: inline-block;
    font-size: 2rem;
    line-height: 2.4rem;
    font-family: Metric-Bold;
    color: #1A1A1A;
    padding-left: 12px; }

.work-slide .owl-carousel .owl-dot {
  display: inline-block;
  counter-increment: slides-num;
  position: absolute; }
  .work-slide .owl-carousel .owl-dot span {
    display: none; }
  .work-slide .owl-carousel .owl-dot.active:before {
    content: counter(slides-num);
    display: inline-block;
    position: absolute;
    font-family: Metric-Bold;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #1A1A1A;
    left: -11px;
    top: 0; }

@media (max-width: 991.98px) {
  .work-slide .work-information {
    max-width: 450px;
    margin: 0 auto; } }

@media (max-width: 575.98px) {
  .work-slide .work-information {
    max-width: 320px; } }

@media (max-width: 374.98px) {
  .work-slide .work-information {
    max-width: 240px; } }

.work-slide .work-information span {
  display: block;
  font-family: Metric-Regular;
  color: #1A1A1A;
  pointer-events: none;
  font-size: 2.4rem;
  line-height: 3rem;
  opacity: 0.5; }
  @media (max-width: 991.98px) {
    .work-slide .work-information span {
      font-size: 1.8rem;
      line-height: 3rem; } }

.work-slide .work-information h3 {
  font-family: Metric-Bold;
  font-size: 7rem;
  line-height: 8.4rem;
  color: #1A1A1A;
  margin-bottom: 20px;
  pointer-events: none; }
  @media (max-height: 768px) {
    .work-slide .work-information h3 {
      font-size: 5rem;
      line-height: 6.4rem; } }
  @media (max-width: 1400px) {
    .work-slide .work-information h3 {
      font-size: 6rem;
      line-height: 7.4rem; } }
  @media (max-width: 1100px) {
    .work-slide .work-information h3 {
      font-size: 5rem;
      line-height: 6.4rem; } }
  @media (max-width: 991.98px) {
    .work-slide .work-information h3 {
      font-size: 3rem;
      line-height: 3.6rem; } }
  @media (max-width: 374.98px) {
    .work-slide .work-information h3 {
      font-size: 2rem;
      line-height: 2.6rem; } }

.work-slide .work-information a.detail-url {
  font-family: Metric-Medium;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #0000E6; }
  @media (max-width: 991.98px) {
    .work-slide .work-information a.detail-url {
      display: none; } }

.work-slide .work-information a {
  display: inline-block;
  pointer-events: all; }

.work-slide .banner {
  position: relative;
  height: 653px; }
  @media (max-height: 850px) {
    .work-slide .banner {
      width: 623px;
      height: 623px; } }
  @media (max-width: 1400px) {
    .work-slide .banner {
      width: 550px;
      height: 550px; } }
  @media (max-width: 1100px) {
    .work-slide .banner {
      width: 450px;
      height: 450px; } }
  @media (max-height: 768px) {
    .work-slide .banner {
      width: 450px;
      height: 450px; } }
  @media (max-width: 991.98px) {
    .work-slide .banner {
      display: table;
      margin: 0 auto;
      /*width:100%;
				height:100%;*/ } }
  @media (max-width: 767.98px) {
    .work-slide .banner {
      width: 100%;
      height: auto; } }
  .work-slide .banner i.def-icon-arrow {
    position: absolute;
    left: -43px;
    width: 78px;
    height: 56px;
    background: url(../images/svg/icon-arrow.svg) no-repeat;
    background-size: auto 100%;
    z-index: -1;
    top: 100px;
    opacity: 0; }
  .work-slide .banner i.def-icon-full-plus {
    position: absolute;
    left: -80px;
    bottom: -73px;
    width: 201px;
    height: 180px;
    background: url(../images/svg/full-plus.svg) no-repeat;
    background-size: cover;
    opacity: 1;
    z-index: -1; }
  @media (max-width: 991.98px) {
    .work-slide .banner i {
      display: none; } }
  .work-slide .banner figure {
    width: 653px;
    height: 653px;
    background-size: cover !important;
    border-radius: 8px;
    position: absolute;
    top: 100px;
    pointer-events: none;
    opacity: 0; }
    @media (max-height: 850px) {
      .work-slide .banner figure {
        width: 623px;
        height: 623px; } }
    @media (max-width: 1400px) {
      .work-slide .banner figure {
        width: 550px;
        height: 550px; } }
    @media (max-height: 768px) {
      .work-slide .banner figure {
        width: 450px;
        height: 450px; } }
    @media (max-width: 1100px) {
      .work-slide .banner figure {
        width: 450px;
        height: 450px; } }
    @media (max-width: 991.98px) {
      .work-slide .banner figure {
        position: initial;
        /*width:100%;
				height:100%;*/ } }
    @media (max-width: 767.98px) {
      .work-slide .banner figure {
        width: 100%;
        height: auto; } }
    .work-slide .banner figure > a {
      display: inline-block;
      pointer-events: all; }
    .work-slide .banner figure img {
      pointer-events: none; }
      @media (max-width: 767.98px) {
        .work-slide .banner figure img {
          display: block; } }

/*************************main-works*************************/
/*************************clients*************************/
.clients {
  overflow: hidden; }
  @media (max-width: 1400px) {
    .clients {
      max-width: 980px;
      margin: 0 auto; } }
  @media (max-width: 1100px) {
    .clients {
      max-width: 830px;
      margin: 0 auto; } }
  .clients h3 {
    font-family: Metric-Bold;
    font-size: 6.5rem;
    line-height: 7.9rem;
    color: #1A1A1A;
    margin-top: 50px;
    opacity: 0; }
    @media (max-height: 980px) {
      .clients h3 {
        font-size: 6.5rem;
        line-height: 7.9rem; } }
    @media (max-height: 850px) {
      .clients h3 {
        font-size: 5.5rem;
        line-height: 6.9rem; } }
    @media (max-height: 768px) {
      .clients h3 {
        font-size: 4.5rem;
        line-height: 5.9rem; } }
    @media (max-width: 1330px) {
      .clients h3 {
        font-size: 5.5rem;
        line-height: 6.9rem; } }
    @media (max-width: 1100px) {
      .clients h3 {
        font-size: 4.5rem;
        line-height: 5.9rem; } }
    @media (max-width: 991.98px) {
      .clients h3 {
        font-size: 3.8rem;
        line-height: 4.6rem; } }
    @media (max-width: 575.98px) {
      .clients h3 {
        font-size: 2.8rem;
        line-height: 3.6rem; } }
    @media (max-width: 374.98px) {
      .clients h3 {
        font-size: 2rem;
        line-height: 2.8rem; } }
    .clients h3 span {
      display: block; }
      @media (max-width: 575.98px) {
        .clients h3 span {
          display: inline; } }
  .clients ul {
    margin: 55px -15px 17px -15px;
    opacity: 0; }
    @media (max-width: 575.98px) {
      .clients ul {
        margin-bottom: 20px;
        display: table; } }
    @media (max-width: 374.98px) {
      .clients ul {
        margin-top: 0 !important; } }
    .clients ul li {
      width: 25%;
      float: left;
      padding: 0 15px; }
      @media (max-width: 575.98px) {
        .clients ul li {
          width: 50%;
          margin-bottom: -20px; } }
      @media (max-height: 750px) {
        .clients ul li {
          margin-bottom: -37px; } }
      .clients ul li img {
        max-width: 100%; }
      @media (max-width: 575.98px) {
        .clients ul li.middle_xs {
          display: none; } }
  .clients a.all-button {
    font-family: Metric-Medium;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #0000E6;
    display: table;
    margin: 0 auto;
    opacity: 0; }

/*************************clients*************************/
/*************************medium*************************/
.medium {
  width: 100%;
  position: relative;
  max-height: 722px;
  margin-top: 150px;
  /*min-height: 466px;*/ }
  @media (max-width: 1400px) {
    .medium {
      max-width: 980px;
      margin: 0 auto; } }
  @media (max-width: 1100px) {
    .medium {
      max-width: 830px;
      margin: 0 auto; } }
  @media (max-width: 991.98px) {
    .medium {
      max-width: 100%; } }
  @media (max-width: 767.98px) {
    .medium {
      height: 500px !important; } }
  @media (max-width: 374.98px) {
    .medium {
      height: 400px !important; } }
  @media (max-height: 480px) {
    .medium {
      height: 330px !important; } }
  .medium .inner {
    background: #04E17D;
    max-height: 722px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
    min-height: 500px;
    opacity: 0; }
    @media (max-width: 767.98px) {
      .medium .inner {
        height: 500px !important;
        min-height: auto !important; } }
    @media (max-width: 374.98px) {
      .medium .inner {
        height: 400px !important; } }
    @media (max-height: 480px) {
      .medium .inner {
        height: 330px !important; } }
    .medium .inner ul {
      display: table;
      margin: 0 auto;
      padding: 0 15px;
      position: relative;
      z-index: 2; }
      @media (max-width: 767.98px) {
        .medium .inner ul {
          display: none; } }
      .medium .inner ul li {
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -ms-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        position: relative;
        display: table; }
        .medium .inner ul li:first-child {
          margin-bottom: 40px; }
          @media (max-width: 575.98px) {
            .medium .inner ul li:first-child {
              margin-bottom: 40px; } }
        .medium .inner ul li:last-child {
          margin-top: 80px; }
          @media (max-width: 575.98px) {
            .medium .inner ul li:last-child {
              margin-top: 30px; } }
        .medium .inner ul li span {
          display: block;
          font-family: Metric-Regular;
          font-size: 2.4rem;
          line-height: 3rem;
          color: #FFFFFF;
          opacity: 0.7;
          position: relative;
          z-index: 1; }
        .medium .inner ul li figure {
          width: 452px;
          height: 276px;
          position: absolute;
          right: -7%;
          top: 30%;
          transform: translate(0, -50%);
          background-size: cover !important;
          z-index: 0;
          opacity: 0; }
          @media (max-width: 1100px) {
            .medium .inner ul li figure {
              right: -5%;
              width: 330px;
              height: 190px; } }
          @media (max-width: 767.98px) {
            .medium .inner ul li figure {
              width: 160px;
              height: 90px; } }
        .medium .inner ul li a {
          position: relative;
          display: inline-block;
          pointer-events: all;
          z-index: 1; }
          .medium .inner ul li a h4 {
            font-family: Metric-Bold;
            font-size: 6rem;
            line-height: 7.4rem;
            color: #FFFFFF;
            margin-top: -7px;
            position: relative;
            z-index: 1;
            pointer-events: none; }
            @media (max-width: 1249.98px) {
              .medium .inner ul li a h4 {
                font-size: 6rem;
                line-height: 7.4rem; } }
            @media (max-width: 991.98px) {
              .medium .inner ul li a h4 {
                font-size: 5rem;
                line-height: 6.4rem; } }
            @media (max-width: 767.98px) {
              .medium .inner ul li a h4 {
                font-size: 3rem;
                line-height: 4.4rem; } }
            @media (max-width: 575.98px) {
              .medium .inner ul li a h4 {
                font-size: 2.5rem;
                line-height: 3.9rem; } }
          .medium .inner ul li a.more-medium {
            font-family: Metric-Medium;
            font-size: 2rem;
            line-height: 2.4rem;
            color: #0000E6;
            pointer-events: all; }
            .medium .inner ul li a.more-medium i {
              vertical-align: inherit;
              line-height: 0;
              margin-left: 5px;
              pointer-events: none; }
              .medium .inner ul li a.more-medium i:before {
                color: #0000E6;
                font-size: 12px; }
    .medium .inner .owl-theme {
      display: none; }
      @media (max-width: 767.98px) {
        .medium .inner .owl-theme {
          display: block; } }
      .medium .inner .owl-theme .owl-stage {
        padding-left: 20px !important; }
      .medium .inner .owl-theme .wrap figure {
        height: 260px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        margin-bottom: 7px; }
        @media (max-width: 575.98px) {
          .medium .inner .owl-theme .wrap figure {
            height: 171px; } }
      .medium .inner .owl-theme .wrap .title span {
        display: block;
        font-family: Metric-Regular;
        font-size: 2rem;
        line-height: 3rem;
        opacity: 0.7;
        color: #FFFFFF; }
      .medium .inner .owl-theme .wrap .title h4 {
        font-family: Metric-Bold;
        font-size: 3.8rem;
        line-height: 4.6rem;
        color: #FFFFFF; }
        @media (max-width: 767.98px) {
          .medium .inner .owl-theme .wrap .title h4 {
            font-size: 3.2rem;
            line-height: 4rem; } }
        @media (max-width: 374.98px) {
          .medium .inner .owl-theme .wrap .title h4 {
            font-size: 2.8rem;
            line-height: 3.6rem; } }
  .medium i.def-icon-narrow {
    width: 35px;
    height: 180px;
    background: url(../images/svg/narrow-plus.svg) no-repeat;
    position: absolute;
    right: 424px;
    bottom: -90px;
    transform: rotate(90deg); }
    @media (max-width: 767.98px) {
      .medium i.def-icon-narrow {
        display: none; } }
  .medium > figure {
    width: 494px;
    height: 312px;
    position: absolute;
    right: -49px;
    bottom: -97px;
    background: url(../images/content/slide-figure.png) no-repeat;
    background-size: 100%; }
    @media (max-width: 767.98px) {
      .medium > figure {
        display: none; } }

/*************************medium*************************/
/*************************team*************************/
.team {
  position: relative; }
  @media (max-width: 1330px) {
    .team {
      max-width: 960px;
      margin: 0 auto; } }
  @media (max-width: 1100px) {
    .team {
      max-width: 830px; } }
  .team h4 {
    font-family: Metric-Bold;
    font-size: 7rem;
    line-height: 8.4rem;
    color: #1A1A1A;
    padding-right: 100px;
    position: relative; }
    @media (max-height: 980px) {
      .team h4 {
        font-size: 6.5rem;
        line-height: 7.9rem; } }
    @media (max-height: 850px) {
      .team h4 {
        font-size: 5.5rem;
        line-height: 6.9rem; } }
    @media (max-height: 768px) {
      .team h4 {
        font-size: 4.5rem;
        line-height: 5.9rem; } }
    @media (max-width: 1330px) {
      .team h4 {
        font-size: 5.5rem;
        line-height: 6.9rem;
        padding-right: 0; } }
    @media (max-width: 1100px) {
      .team h4 {
        font-size: 4.5rem;
        line-height: 5.9rem; } }
    @media (max-width: 991.98px) {
      .team h4 {
        font-size: 3.8rem;
        line-height: 4.6rem; } }
    @media (max-width: 575.98px) {
      .team h4 {
        font-size: 2.8rem;
        line-height: 3.6rem; } }
    @media (max-width: 374.98px) {
      .team h4 {
        font-size: 2rem;
        line-height: 2.8rem; } }
    .team h4 i.def-icon-cube {
      width: 50px;
      height: 35px;
      background: #f9df6e;
      position: absolute;
      top: 39%;
      z-index: -1;
      left: 43%; }
  .team .photo {
    position: relative; }
    .team .photo ul {
      margin-top: 43px;
      display: table;
      width: 100%;
      position: relative; }
      @media (max-height: 768px) {
        .team .photo ul {
          margin-top: 25px; } }
      @media (max-width: 991.98px) {
        .team .photo ul {
          margin-top: 25px; } }
      .team .photo ul li {
        float: left;
        display: inline-block;
        text-align: center; }
        @media (max-width: 1330px) {
          .team .photo ul li {
            width: 33.3333%; } }
        @media (max-width: 991.98px) {
          .team .photo ul li {
            width: 50%; } }
        .team .photo ul li:not(:last-child) {
          padding-right: 28px; }
        .team .photo ul li:nth-child(2) figure img {
          max-height: 320px; }
          @media (max-width: 1330px) {
            .team .photo ul li:nth-child(2) figure img {
              max-height: 270px; } }
        .team .photo ul li:last-child {
          position: absolute;
          bottom: 0; }
          @media (max-width: 991.98px) {
            .team .photo ul li:last-child {
              bottom: -30%;
              left: 55%; } }
        @media (max-width: 991.98px) {
          .team .photo ul li:first-child {
            padding-top: 44px; } }
        .team .photo ul li i.def-icon-border {
          width: 18px;
          height: 46px;
          background: #50f1bb;
          position: absolute;
          left: -10px;
          top: -19px;
          z-index: -1;
          opacity: 0; }
          @media (max-width: 991.98px) {
            .team .photo ul li i.def-icon-border {
              top: 20px; } }
        .team .photo ul li figure img {
          max-width: 100%; }
          @media (max-height: 980px) {
            .team .photo ul li figure img {
              max-height: 400px; } }
          @media (max-height: 850px) {
            .team .photo ul li figure img {
              max-height: 370px; } }
          @media (max-width: 991.98px) {
            .team .photo ul li figure img {
              max-width: 350px; } }
          @media (max-width: 767.98px) {
            .team .photo ul li figure img {
              max-height: 220px;
              max-width: 190px; } }
          @media (max-width: 575.98px) {
            .team .photo ul li figure img {
              max-width: 150px; } }
          @media (max-width: 374.98px) {
            .team .photo ul li figure img {
              max-width: 120px; } }
    .team .photo i.def-icon-arrow-up {
      width: 270px;
      height: 420px;
      background: url(../images/content/team-arrow-up.png) no-repeat center;
      background-size: cover;
      position: absolute;
      right: 0;
      bottom: 0; }
      @media (max-width: 991.98px) {
        .team .photo i.def-icon-arrow-up {
          display: none; } }
    .team .photo i.def-icon-fly {
      width: 291px;
      height: 251px;
      position: absolute;
      right: -150px;
      top: -21px;
      background: url(../images/content/team-icon.png) no-repeat center;
      background-size: cover !important;
      top: 10%;
      opacity: 0; }
      @media (max-height: 980px) {
        .team .photo i.def-icon-fly {
          top: -71px; } }
      @media (max-width: 991.98px) {
        .team .photo i.def-icon-fly {
          width: 185px;
          height: 195px;
          position: absolute;
          top: 80%;
          left: 35%;
          right: auto;
          transform: rotate(28deg); } }
      @media (max-width: 767.98px) {
        .team .photo i.def-icon-fly {
          left: 95px;
          width: 150px;
          height: 165px; } }
      @media (max-width: 374.98px) {
        .team .photo i.def-icon-fly {
          left: 65px; } }
  .team a.team-all-button {
    font-family: Metric-Medium;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #0000E6;
    display: table;
    margin: 60px auto 0 auto; }
    @media (max-height: 850px) {
      .team a.team-all-button {
        margin-top: 30px; } }
    @media (max-width: 991.98px) {
      .team a.team-all-button {
        display: none; } }

/*************************team*************************/
/*************************footer*************************/
.contact {
  background: #0000E6;
  padding: 96px 0 0 0;
  position: relative;
  font-size: 2rem;
  line-height: 2.4rem; }
  .contact.main-contact {
    position: absolute;
    bottom: -100%;
    width: 100%; }
  @media (max-height: 850px) {
    .contact {
      padding-top: 50px; } }
  @media (max-height: 768px) {
    .contact {
      padding-top: 40px; } }
  @media (max-width: 991.98px) {
    .contact {
      padding-top: 0; } }
  .contact:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 110px;
    background: #000000;
    opacity: 0.1;
    bottom: 0; }
    @media (max-height: 768px) {
      .contact:before {
        height: 90px; } }
    @media (max-width: 991.98px) {
      .contact:before {
        content: none; } }
  @media (max-width: 991.98px) {
    .contact footer {
      display: none; } }
  .contact .page-top {
    display: inline-block;
    width: 67px;
    height: 67px;
    background: #FFFFFF;
    border-radius: 6px;
    position: absolute;
    right: 0;
    bottom: 0;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -ms-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
    @media (max-width: 991.98px) {
      .contact .page-top {
        width: 47px;
        height: 47px;
        bottom: auto;
        top: -25px;
        right: 20px; } }
    .contact .page-top:before {
      content: "\e900";
      font-family: 'icomoon';
      color: #1A1A1A;
      font-size: 58px;
      transform: rotate(270deg);
      position: absolute;
      left: 50%;
      margin-left: -28px;
      bottom: 0;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: .3s ease-in-out; }
      @media (max-width: 991.98px) {
        .contact .page-top:before {
          font-size: 38px;
          margin-left: -19px;
          bottom: -5px; } }
    .contact .page-top:hover:before {
      bottom: 10px; }
  .contact .footer-top h6 {
    font-family: Metric-Medium;
    color: #FFFFFF;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 17px; }
  .contact .footer-top nav ul li:not(:last-child) {
    margin-bottom: 9px; }
  .contact .footer-top nav ul li a {
    font-family: Metric-Regular;
    color: #FFFFFF; }
  .contact .footer-top address {
    font-family: Metric-Regular;
    color: #FFFFFF;
    opacity: 0.7;
    margin-top: -2px; }
    .contact .footer-top address a {
      display: block;
      margin-top: 25px;
      color: #FFFFFF; }
  .contact .footer-top .email {
    margin-top: 36px; }
    .contact .footer-top .email h6 {
      margin-bottom: 0; }
    .contact .footer-top .email a {
      color: #FFFFFF; }
  .contact .footer-top a.f-contact-us {
    pointer-events: all;
    display: inline-block;
    float: right; }
    .contact .footer-top a.f-contact-us > * {
      pointer-events: none; }
    .contact .footer-top a.f-contact-us h5 {
      font-family: Metric-Bold;
      font-size: 7rem;
      line-height: 8.4rem;
      color: #FFFFFF;
      margin-top: -12px;
      float: right;
      margin-right: 30px; }
      .contact .footer-top a.f-contact-us h5 span {
        display: block; }
      .contact .footer-top a.f-contact-us h5 i {
        font-size: 5.8rem;
        line-height: 0.1rem;
        margin-top: -60px; }
  .contact .footer-middle {
    position: relative;
    margin-top: 174px; }
    @media (max-height: 850px) {
      .contact .footer-middle {
        margin-top: 134px; } }
    @media (max-height: 768px) {
      .contact .footer-middle {
        margin-top: 100px; } }
    .contact .footer-middle ul.social-media {
      display: table; }
      .contact .footer-middle ul.social-media li {
        float: left;
        display: inline-block; }
        .contact .footer-middle ul.social-media li:not(:last-child) {
          margin-right: 26px; }
        .contact .footer-middle ul.social-media li a {
          pointer-events: all;
          display: block; }
          .contact .footer-middle ul.social-media li a i {
            pointer-events: none;
            font-size: 1.6rem;
            line-height: 0.1rem; }
            .contact .footer-middle ul.social-media li a i:before {
              color: #FFFFFF; }
            .contact .footer-middle ul.social-media li a i.icon-behance {
              font-size: 2rem; }
  .contact .footer-bottom {
    position: relative;
    padding: 43px 0;
    margin-top: 68px;
    width: 100%;
    display: table; }
    @media (max-height: 768px) {
      .contact .footer-bottom {
        margin-top: 45px;
        padding: 33px 0; } }
    .contact .footer-bottom div {
      width: 50%;
      float: left; }
    .contact .footer-bottom p {
      color: #FFFFFF;
      opacity: 0.7; }
    .contact .footer-bottom ul {
      display: table;
      float: right; }
      .contact .footer-bottom ul li {
        display: inline-block;
        float: left;
        color: #FFFFFF;
        font-family: Metric-Regular;
        position: relative; }
        .contact .footer-bottom ul li:nth-child(2) {
          opacity: 0.7;
          padding-left: 13px; }
          .contact .footer-bottom ul li:nth-child(2):before {
            content: "";
            position: absolute;
            left: 5px;
            top: 6px;
            width: 2px;
            height: 15px;
            background: #FFFFFF;
            opacity: 0.7; }
        .contact .footer-bottom ul li a {
          color: #FFFFFF; }
  .contact .mobile-footer {
    padding-top: 30px;
    display: none; }
    @media (max-width: 991.98px) {
      .contact .mobile-footer {
        display: block; } }
    @media (max-width: 374.98px) {
      .contact .mobile-footer {
        padding-top: 15px; } }
    .contact .mobile-footer address {
      margin-bottom: 40px;
      text-align: center; }
      @media (max-width: 374.98px) {
        .contact .mobile-footer address {
          margin-bottom: 15px; } }
      .contact .mobile-footer address h6 {
        font-size: 2rem;
        line-height: 2.4rem;
        font-family: Metric-Medium;
        margin-bottom: 10px;
        color: #FFFFFF; }
        @media (max-width: 374.98px) {
          .contact .mobile-footer address h6 {
            font-size: 1.8rem;
            line-height: 2.2rem; } }
      .contact .mobile-footer address p {
        font-family: Metric-Regular;
        color: #FFFFFF;
        opacity: 0.7;
        font-size: 2rem;
        line-height: 2.4rem; }
        @media (max-width: 374.98px) {
          .contact .mobile-footer address p {
            font-size: 1.8rem;
            line-height: 2.2rem; } }
      .contact .mobile-footer address a {
        font-family: Metric-Regular;
        font-size: 2rem;
        line-height: 2.4rem;
        color: #FFFFFF;
        opacity: 0.7;
        display: inline-block;
        margin-top: 20px; }
        @media (max-width: 374.98px) {
          .contact .mobile-footer address a {
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin-top: 15px; } }
    .contact .mobile-footer ul.social-media {
      display: table;
      margin: 0 auto; }
      .contact .mobile-footer ul.social-media li {
        float: left;
        display: inline-block; }
        .contact .mobile-footer ul.social-media li:not(:last-child) {
          margin-right: 26px; }
          @media (max-width: 374.98px) {
            .contact .mobile-footer ul.social-media li:not(:last-child) {
              margin-right: 15px; } }
        .contact .mobile-footer ul.social-media li a {
          display: block; }
          .contact .mobile-footer ul.social-media li a i {
            font-size: 1.9rem;
            line-height: 0.1rem; }
            .contact .mobile-footer ul.social-media li a i:before {
              color: #FFFFFF; }
            .contact .mobile-footer ul.social-media li a i.icon-behance {
              font-size: 2rem; }
    .contact .mobile-footer a.contact-url {
      font-family: Metric-Bold;
      font-size: 3rem;
      line-height: 3.6rem;
      color: #FFFFFF;
      display: table;
      margin: 57px auto 46px auto; }
      @media (max-width: 374.98px) {
        .contact .mobile-footer a.contact-url {
          margin: 15px auto; } }
      .contact .mobile-footer a.contact-url i.icon-arrow-right {
        font-size: 5.8rem;
        line-height: 0.1rem;
        margin-left: 10px; }
    .contact .mobile-footer .email-bar {
      text-align: center; }
      .contact .mobile-footer .email-bar h6 {
        font-family: Metric-Medium;
        font-size: 2rem;
        line-height: 2.4rem;
        color: #FFFFFF; }
      .contact .mobile-footer .email-bar a {
        color: #FFFFFF;
        font-family: Metric-Regular;
        display: inline-block; }
    .contact .mobile-footer .mobile-footer-bottom {
      margin-top: 30px;
      padding: 24px 0; }
      @media (max-width: 374.98px) {
        .contact .mobile-footer .mobile-footer-bottom {
          margin-top: 10px;
          padding: 15px 0; } }
      .contact .mobile-footer .mobile-footer-bottom:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        background: #000000;
        opacity: 0.1;
        height: 110px;
        width: 100%; }
        @media (max-width: 374.98px) {
          .contact .mobile-footer .mobile-footer-bottom:before {
            height: 70px; } }
      .contact .mobile-footer .mobile-footer-bottom p {
        font-family: Metric-Regular;
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: #FFFFFF;
        opacity: 0.7;
        text-align: center;
        margin-bottom: 17px; }
        @media (max-width: 374.98px) {
          .contact .mobile-footer .mobile-footer-bottom p {
            font-size: 1.5rem;
            line-height: 1.8rem;
            margin-bottom: 9px; } }
      .contact .mobile-footer .mobile-footer-bottom ul {
        display: table;
        margin: 0 auto; }
        .contact .mobile-footer .mobile-footer-bottom ul li {
          display: inline-block;
          float: left;
          color: #FFFFFF;
          font-family: Metric-Regular;
          position: relative;
          font-size: 1.8rem;
          line-height: 2.2rem; }
          @media (max-width: 374.98px) {
            .contact .mobile-footer .mobile-footer-bottom ul li {
              font-size: 1.5rem;
              line-height: 1.8rem; } }
          .contact .mobile-footer .mobile-footer-bottom ul li:nth-child(2) {
            opacity: 0.7;
            padding-left: 13px; }
            .contact .mobile-footer .mobile-footer-bottom ul li:nth-child(2):before {
              content: "";
              position: absolute;
              left: 5px;
              top: 4px;
              width: 2px;
              height: 15px;
              background: #FFFFFF;
              opacity: 0.7; }
          .contact .mobile-footer .mobile-footer-bottom ul li a {
            color: #FFFFFF; }

/*************************footer*************************/
/*************************wrapper*************************/
.wrapper {
  overflow: hidden; }

/*************************wrapper*************************/
/*************************project-detail*************************/
.wrapper .top-banner {
  background: #0000E6;
  position: relative; }
  .wrapper .top-banner .this-name {
    font-family: Metric-Regular;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #FFFFFF;
    position: absolute;
    left: 65px;
    bottom: 87px;
    transform: rotate(270deg);
    transform: rotate(270deg) translate(-50%, 0);
    transform-origin: 0 0;
    transform: perspective(1px) translateZ(0px) rotate(270deg);
    position: fixed;
    top: 560px;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -ms-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
    @media (max-width: 1150px) {
      .wrapper .top-banner .this-name {
        opacity: 0; } }
    @media (max-width: 991.98px) {
      .wrapper .top-banner .this-name {
        opacity: 1;
        transform: rotate(0deg);
        transform: rotate(0deg) translate(-50%, 0);
        transform-origin: 0 0;
        transform: perspective(1px) translateZ(0px) rotate(0deg);
        position: initial;
        padding-left: 20px; } }
    .wrapper .top-banner .this-name.colored {
      color: #1A1A1A; }
      @media (max-width: 991.98px) {
        .wrapper .top-banner .this-name.colored {
          color: #FFFFFF; } }

.wrapper .project-detail .top-banner {
  height: 650px;
  padding-top: 147px;
  background: var(--background-color);
  position: relative;
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .wrapper .project-detail .top-banner {
      padding-top: 98px;
      height: auto; } }
  .wrapper .project-detail .top-banner .title {
    width: 66.66%;
    padding-top: 52px; }
    @media (max-width: 1700px) {
      .wrapper .project-detail .top-banner .title {
        width: 50%; } }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .top-banner .title {
        padding-top: 29px;
        width: 100%; } }
    .wrapper .project-detail .top-banner .title span {
      display: block;
      font-family: Metric-Regular;
      font-size: 2.4rem;
      line-height: 3rem;
      color: #FFFFFF;
      opacity: 0.7;
      margin-bottom: 8px; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .top-banner .title span {
          font-size: 1.8rem;
          line-height: 3.6rem;
          margin-bottom: -3px; } }
    .wrapper .project-detail .top-banner .title h1 {
      color: #FFFFFF;
      font-family: Metric-Bold; }
      @media (max-width: 1400px) {
        .wrapper .project-detail .top-banner .title h1 {
          font-size: 5rem;
          line-height: 6.4rem; } }
  .wrapper .project-detail .top-banner figure {
    position: absolute;
    right: 0;
    bottom: 0px;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -ms-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
    @media (max-width: 1920px) {
      .wrapper .project-detail .top-banner figure {
        right: -90px; } }
    @media (max-width: 1249.98px) {
      .wrapper .project-detail .top-banner figure {
        right: -130px; } }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .top-banner figure {
        position: initial; } }
    .wrapper .project-detail .top-banner figure img {
      max-height: 568px;
      max-width: 100%; }
      @media (max-width: 1700px) {
        .wrapper .project-detail .top-banner figure img {
          max-height: 568px; } }
      @media (max-width: 1249.98px) {
        .wrapper .project-detail .top-banner figure img {
          max-height: 468px; } }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .top-banner figure img {
          max-height: 100%;
          max-width: 100%; } }

.wrapper .project-detail .summary {
  padding: 46px 0 21px 0;
  background: #FFFFFF; }
  @media (max-width: 1249.98px) {
    .wrapper .project-detail .summary {
      padding: 30px 0 21px 0; } }
  @media (max-width: 991.98px) {
    .wrapper .project-detail .summary {
      padding: 32px 0 40px 0; } }
  .wrapper .project-detail .summary .row {
    margin: 0; }
    .wrapper .project-detail .summary .row div {
      float: left; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .summary .row div {
          width: 100% !important;
          margin-bottom: 21px; } }
      .wrapper .project-detail .summary .row div:nth-child(1), .wrapper .project-detail .summary .row div:nth-child(2) {
        width: 25%; }
      .wrapper .project-detail .summary .row div:nth-child(3) {
        width: 33%; }
        @media (max-width: 991.98px) {
          .wrapper .project-detail .summary .row div:nth-child(3) {
            margin-bottom: 33px; } }
      .wrapper .project-detail .summary .row div:nth-child(4) {
        width: 17%; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .summary .row div:last-child {
          margin-bottom: 0; } }
  .wrapper .project-detail .summary span {
    font-family: Metric-Medium;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #1A1A1A;
    margin-bottom: 11px;
    display: block; }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .summary span {
        margin-bottom: 3px; } }
  .wrapper .project-detail .summary p {
    font-family: Metric-Regular;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #1A1A1A;
    opacity: 0.7; }
  .wrapper .project-detail .summary a {
    font-family: Metric-Medium;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #0000E6;
    float: right;
    margin-top: 15px; }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .summary a {
        float: none; } }
    .wrapper .project-detail .summary a i {
      vertical-align: inherit;
      line-height: 0;
      margin-left: 3px;
      font-weight: bold; }
      .wrapper .project-detail .summary a i:before {
        color: #0000E6;
        font-size: 12px; }

.wrapper .project-detail .cuff {
  padding: 81px 0 108px 0; }
  @media (max-width: 991.98px) {
    .wrapper .project-detail .cuff {
      padding: 44px 0 86px 0; } }
  .wrapper .project-detail .cuff article {
    max-width: 751px;
    margin: 0 auto; }
    .wrapper .project-detail .cuff article h2 {
      font-family: Metric-Bold;
      color: #1A1A1A;
      margin-bottom: 14px; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .cuff article h2 {
          margin-bottom: 17px;
          font-size: 3.8rem;
          line-height: 4.6rem; } }
    .wrapper .project-detail .cuff article h4 {
      font-family: Metric-Medium; }
    .wrapper .project-detail .cuff article p {
      font-family: Metric-Regular;
      color: #1A1A1A;
      font-size: 2rem;
      line-height: 2.4rem; }
  .wrapper .project-detail .cuff .cuff-banner {
    position: relative;
    margin: 56px 0 55px 0; }
    .wrapper .project-detail .cuff .cuff-banner i.def-icon-full-plus {
      position: absolute;
      left: -53.5px;
      top: -47.5px;
      width: 201px;
      height: 180px;
      background: url(../images/svg/full-plus.svg) no-repeat;
      background-size: cover;
      z-index: -1; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .cuff .cuff-banner i.def-icon-full-plus {
          top: -13.5px;
          left: -12.5px; } }
    .wrapper .project-detail .cuff .cuff-banner img {
      max-width: 100%;
      width: 100%; }
      @media (max-width: 767.98px) {
        .wrapper .project-detail .cuff .cuff-banner img.web-image {
          display: none; } }
      .wrapper .project-detail .cuff .cuff-banner img.mobile-image {
        display: none; }
        @media (max-width: 767.98px) {
          .wrapper .project-detail .cuff .cuff-banner img.mobile-image {
            display: block; } }

.wrapper .project-detail .user-experience {
  padding: 64px 0 97px 0;
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .wrapper .project-detail .user-experience .center {
      padding: 0; } }
  .wrapper .project-detail .user-experience .wrap {
    background: var(--background-color);
    height: 543px;
    border-radius: 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 5px; }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .user-experience .wrap {
        height: auto;
        display: block;
        padding: 98px 0 130px 0;
        border-radius: 0; } }
    .wrapper .project-detail .user-experience .wrap:before {
      content: "";
      position: absolute;
      left: -240px;
      bottom: 13px;
      width: 357px;
      height: 183px;
      background: url(../images/content/user-interface-before-image.png) no-repeat;
      background-size: cover; }
      @media (max-width: 1249.98px) {
        .wrapper .project-detail .user-experience .wrap:before {
          left: -200px; } }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .user-experience .wrap:before {
          width: 225px;
          height: 110px;
          background-size: auto 100%;
          left: -81px;
          bottom: 5px; } }
    .wrapper .project-detail .user-experience .wrap div.experience-icons {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
    .wrapper .project-detail .user-experience .wrap i.def-icon-full-plus {
      position: absolute !important;
      left: auto !important;
      right: -73.5px !important;
      top: -64.5px !important;
      width: 201px;
      height: 180px;
      background: url(../images/svg/full-plus.svg) no-repeat;
      background-size: cover; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .user-experience .wrap i.def-icon-full-plus {
          right: -93.5px !important; } }
    .wrapper .project-detail .user-experience .wrap i.def-icon-fly {
      width: 200px;
      height: 212px;
      position: absolute !important;
      background: url(../images/content/icon-fly.png) no-repeat;
      background-size: 100%;
      left: 58px !important;
      bottom: -97px !important;
      top: auto !important; }
      @media (max-width: 1249.98px) {
        .wrapper .project-detail .user-experience .wrap i.def-icon-fly {
          left: 98px !important; } }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .user-experience .wrap i.def-icon-fly {
          width: 110px;
          height: 127px;
          bottom: -61px !important; } }
    .wrapper .project-detail .user-experience .wrap .row {
      margin: -15px auto;
      max-width: 780px; }
      .wrapper .project-detail .user-experience .wrap .row > div {
        float: left;
        width: 50%;
        padding: 0 15px; }
        @media (max-width: 991.98px) {
          .wrapper .project-detail .user-experience .wrap .row > div {
            float: none;
            width: 100%; } }
        @media (max-width: 991.98px) {
          .wrapper .project-detail .user-experience .wrap .row > div:first-child {
            margin-bottom: 25px; } }
        @media (max-width: 991.98px) {
          .wrapper .project-detail .user-experience .wrap .row > div:last-child {
            margin-top: 25px; } }
    .wrapper .project-detail .user-experience .wrap section {
      text-align: center; }
      .wrapper .project-detail .user-experience .wrap section h2 {
        font-family: Metric-Bold;
        color: #FFFFFF;
        margin-bottom: 18px; }
        @media (max-width: 991.98px) {
          .wrapper .project-detail .user-experience .wrap section h2 {
            margin-bottom: 14px; } }
      .wrapper .project-detail .user-experience .wrap section p {
        font-family: Metric-Regular;
        font-size: 2rem;
        line-height: 2.6rem;
        color: #FFFFFF; }

.wrapper .project-detail .wireframe, .wrapper .project-detail .user-interface {
  padding: 99px 0 0 0;
  position: relative; }
  @media (max-width: 991.98px) {
    .wrapper .project-detail .wireframe, .wrapper .project-detail .user-interface {
      padding-top: 63px; } }
  .wrapper .project-detail .wireframe .row, .wrapper .project-detail .user-interface .row {
    margin: 0 -15px; }
    .wrapper .project-detail .wireframe .row > div, .wrapper .project-detail .user-interface .row > div {
      float: left;
      padding: 0 15px; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .wireframe .row > div, .wrapper .project-detail .user-interface .row > div {
          width: 100% !important; } }
      .wrapper .project-detail .wireframe .row > div:first-child, .wrapper .project-detail .user-interface .row > div:first-child {
        width: 33.33333333%; }
      .wrapper .project-detail .wireframe .row > div:last-child, .wrapper .project-detail .user-interface .row > div:last-child {
        width: 66.66666667%;
        float: right; }
  .wrapper .project-detail .wireframe .highlight, .wrapper .project-detail .user-interface .highlight {
    position: relative; }
    .wrapper .project-detail .wireframe .highlight section h3, .wrapper .project-detail .wireframe .highlight section p, .wrapper .project-detail .user-interface .highlight section h3, .wrapper .project-detail .user-interface .highlight section p {
      color: #FFFFFF; }
  .wrapper .project-detail .wireframe .fix-title, .wrapper .project-detail .wireframe .highlight-fix-title, .wrapper .project-detail .user-interface .fix-title, .wrapper .project-detail .user-interface .highlight-fix-title {
    position: absolute;
    top: 99px;
    left: 0;
    width: 100%;
    z-index: 2; }
    .wrapper .project-detail .wireframe .fix-title.fixed, .wrapper .project-detail .wireframe .highlight-fix-title.fixed, .wrapper .project-detail .user-interface .fix-title.fixed, .wrapper .project-detail .user-interface .highlight-fix-title.fixed {
      position: fixed !important; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .wireframe .fix-title.fixed, .wrapper .project-detail .wireframe .highlight-fix-title.fixed, .wrapper .project-detail .user-interface .fix-title.fixed, .wrapper .project-detail .user-interface .highlight-fix-title.fixed {
          position: relative !important;
          top: 0; } }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .wireframe .fix-title, .wrapper .project-detail .wireframe .highlight-fix-title, .wrapper .project-detail .user-interface .fix-title, .wrapper .project-detail .user-interface .highlight-fix-title {
        position: relative;
        top: 0; } }
  @media (max-width: 991.98px) {
    .wrapper .project-detail .wireframe section, .wrapper .project-detail .user-interface section {
      margin-bottom: 47px; } }
  .wrapper .project-detail .wireframe section h3, .wrapper .project-detail .wireframe section p, .wrapper .project-detail .user-interface section h3, .wrapper .project-detail .user-interface section p {
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; }
  .wrapper .project-detail .wireframe section h3, .wrapper .project-detail .user-interface section h3 {
    font-family: Metric-Bold;
    color: #1A1A1A;
    margin-bottom: 8px; }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .wireframe section h3, .wrapper .project-detail .user-interface section h3 {
        font-size: 3rem;
        line-height: 3.6rem;
        margin-bottom: 14px; } }
  .wrapper .project-detail .wireframe section p, .wrapper .project-detail .user-interface section p {
    font-family: Metric-Regular;
    font-weight: 300;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #1A1A1A; }
  .wrapper .project-detail .wireframe .image-list, .wrapper .project-detail .user-interface .image-list {
    /*padding-left: 62px;*/
    margin-top: 8px;
    position: relative;
    z-index: 1; }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .wireframe .image-list, .wrapper .project-detail .user-interface .image-list {
        padding-left: 0;
        margin-top: 0; } }
    .wrapper .project-detail .wireframe .image-list figure, .wrapper .project-detail .user-interface .image-list figure {
      margin-bottom: 53px; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .wireframe .image-list figure, .wrapper .project-detail .user-interface .image-list figure {
          margin-bottom: 37px; } }
      .wrapper .project-detail .wireframe .image-list figure img, .wrapper .project-detail .user-interface .image-list figure img {
        max-width: 100%; }

.wrapper .project-detail .user-interface {
  padding: 0;
  margin-bottom: 126px;
  /*&:after{
				left: 0;
				bottom: -1px;
				height: 217px;
				background: $typography-light;
				z-index: 0;
				@media (max-width: $sm){
					height: 101px;
				}
			}*/ }
  @media (max-width: 991.98px) {
    .wrapper .project-detail .user-interface {
      padding-top: 49px;
      margin-bottom: 118px; } }
  .wrapper .project-detail .user-interface:before, .wrapper .project-detail .user-interface:after {
    content: "";
    position: absolute;
    width: 100%;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out; }
  .wrapper .project-detail .user-interface:before {
    left: 0;
    top: -421px;
    height: 421px;
    z-index: -1; }
  .wrapper .project-detail .user-interface .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out; }
  .wrapper .project-detail .user-interface.color .bg {
    opacity: 1;
    background: var(--background-color); }
    .wrapper .project-detail .user-interface.color .bg:before {
      background: var(--background-color); }
  .wrapper .project-detail .user-interface.color .fix-title section > * {
    color: #FFFFFF !important; }
  .wrapper .project-detail .user-interface section {
    margin-bottom: 24px; }
  .wrapper .project-detail .user-interface .image-list figure {
    position: relative; }
    .wrapper .project-detail .user-interface .image-list figure:nth-child(3):before {
      content: "";
      position: absolute;
      left: -160px;
      bottom: -69px;
      width: 201px;
      height: 180px;
      background: url(../images/svg/full-plus.svg) no-repeat;
      background-size: cover;
      z-index: -1; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .user-interface .image-list figure:nth-child(3):before {
          left: -12px;
          bottom: auto;
          top: -59px; } }

.wrapper .project-detail .customer-reviews {
  margin-bottom: 224px; }
  @media (max-width: 991.98px) {
    .wrapper .project-detail .customer-reviews {
      margin-bottom: 90px; } }

.wrapper .project-detail .other-projects {
  margin-bottom: 218px;
  overflow: hidden;
  padding-bottom: 70px; }
  @media (max-width: 991.98px) {
    .wrapper .project-detail .other-projects {
      margin-bottom: 114px; } }
  .wrapper .project-detail .other-projects .top {
    margin-bottom: 38px;
    position: relative; }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .other-projects .top {
        margin-bottom: 20px; } }
    .wrapper .project-detail .other-projects .top h3 {
      font-family: Metric-Bold;
      color: #1A1A1A; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .other-projects .top h3 {
          font-size: 3.8rem;
          line-height: 4.6rem; } }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .other-projects .top h3 span {
          display: block; } }
    .wrapper .project-detail .other-projects .top a {
      display: inline-block;
      font-family: Metric-Medium;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #0000E6;
      position: absolute;
      right: 0;
      bottom: 6px; }
      @media (max-width: 991.98px) {
        .wrapper .project-detail .other-projects .top a {
          bottom: 4px; } }
  .wrapper .project-detail .other-projects .owl-carousel .owl-stage-outer {
    overflow: visible; }
    .wrapper .project-detail .other-projects .owl-carousel .owl-stage-outer .owl-stage {
      padding-left: 0 !important; }
  .wrapper .project-detail .other-projects .owl-carousel .owl-item {
    opacity: 0.5;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -ms-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
    .wrapper .project-detail .other-projects .owl-carousel .owl-item.active {
      opacity: 1; }
  .wrapper .project-detail .other-projects .owl-carousel .owl-nav {
    margin: 0;
    max-width: 200px;
    position: absolute;
    width: 100%;
    bottom: -69px;
    height: 31px; }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .other-projects .owl-carousel .owl-nav {
        left: 50%;
        margin-left: -100px; } }
    .wrapper .project-detail .other-projects .owl-carousel .owl-nav > div {
      margin: 0;
      position: absolute;
      width: 78px;
      height: 31px;
      background: none !important;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: .3s ease-in-out; }
      .wrapper .project-detail .other-projects .owl-carousel .owl-nav > div:before {
        font-family: 'icomoon';
        font-size: 7.3rem;
        line-height: 3.1rem;
        position: absolute;
        top: 0;
        color: #1A1A1A; }
      .wrapper .project-detail .other-projects .owl-carousel .owl-nav > div.owl-prev {
        left: 0; }
        .wrapper .project-detail .other-projects .owl-carousel .owl-nav > div.owl-prev:before {
          content: "\e90f";
          left: 0; }
      .wrapper .project-detail .other-projects .owl-carousel .owl-nav > div.owl-next {
        right: 0; }
        .wrapper .project-detail .other-projects .owl-carousel .owl-nav > div.owl-next:before {
          content: "\e900";
          right: 0; }
  .wrapper .project-detail .other-projects .owl-carousel .owl-dots {
    counter-reset: slides-num;
    position: absolute;
    bottom: -66px;
    left: 95px; }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .other-projects .owl-carousel .owl-dots {
        left: 50%;
        margin-left: -4px; } }
    .wrapper .project-detail .other-projects .owl-carousel .owl-dots:before {
      content: " / ";
      position: absolute;
      left: 0;
      top: 0;
      font-family: Metric-Bold;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #1A1A1A; }
    .wrapper .project-detail .other-projects .owl-carousel .owl-dots:after {
      content: counter(slides-num);
      display: inline-block;
      font-size: 2rem;
      line-height: 2.4rem;
      font-family: Metric-Bold;
      color: #1A1A1A;
      padding-left: 12px; }
  .wrapper .project-detail .other-projects .owl-carousel .owl-dot {
    display: inline-block;
    counter-increment: slides-num; }
    .wrapper .project-detail .other-projects .owl-carousel .owl-dot span {
      display: none; }
    .wrapper .project-detail .other-projects .owl-carousel .owl-dot.active:before {
      content: counter(slides-num);
      display: inline-block;
      position: absolute;
      font-family: Metric-Bold;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #1A1A1A;
      left: -11px;
      top: 0; }
  .wrapper .project-detail .other-projects .owl-carousel .wrap a {
    pointer-events: all; }
    .wrapper .project-detail .other-projects .owl-carousel .wrap a > * {
      pointer-events: none; }
  .wrapper .project-detail .other-projects .owl-carousel .wrap figure {
    border-radius: 6px;
    margin-bottom: 31px; }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .other-projects .owl-carousel .wrap figure {
        margin-bottom: 20px; } }
  .wrapper .project-detail .other-projects .owl-carousel .wrap .information span {
    display: block;
    font-family: Metric-Regular;
    font-size: 2rem;
    line-height: 3rem;
    color: #1A1A1A;
    opacity: 0.5; }
    @media (max-width: 991.98px) {
      .wrapper .project-detail .other-projects .owl-carousel .wrap .information span {
        font-size: 1.8rem;
        line-height: 3rem; } }
  .wrapper .project-detail .other-projects .owl-carousel .wrap .information p {
    font-family: Metric-Bold;
    font-size: 3rem;
    line-height: 3.6rem;
    color: #1A1A1A;
    margin-top: -3px; }

/*************************project-detail*************************/
/*************************customer-reviews*************************/
.customer-reviews .wrap {
  text-align: center;
  max-width: 943px;
  margin: 0 auto;
  width: 100%; }
  .customer-reviews .wrap h2 {
    font-family: Metric-Bold;
    color: #1A1A1A; }
    @media (max-width: 991.98px) {
      .customer-reviews .wrap h2 {
        font-size: 3.8rem;
        line-height: 4.6rem; } }
  .customer-reviews .wrap .customer-profile {
    margin: 53px 0 29px 0; }
    @media (max-width: 991.98px) {
      .customer-reviews .wrap .customer-profile {
        margin: 43px 0 33px 0; } }
    .customer-reviews .wrap .customer-profile figure {
      height: 128px;
      text-align: center;
      margin-bottom: 7px; }
      .customer-reviews .wrap .customer-profile figure img {
        max-height: 100%;
        width: auto;
        display: inline-block !important; }
    .customer-reviews .wrap .customer-profile h6 {
      font-family: Metric-Medium;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #1A1A1A; }
    .customer-reviews .wrap .customer-profile p {
      font-family: Metric-Regular;
      color: #BBBBBB;
      margin-top: -3px; }
  .customer-reviews .wrap section {
    max-width: 750px;
    margin: 0 auto; }
    .customer-reviews .wrap section p {
      font-family: Metric-Regular;
      font-weight: 300;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #1A1A1A; }

/*************************customer-reviews*************************/
/*************************work*************************/
.wrapper .common-banner {
  background: #0000E6;
  position: relative;
  height: 650px;
  /*overflow:hidden;*/ }
  @media (max-width: 991.98px) {
    .wrapper .common-banner {
      height: auto;
      padding: 98px 0 70px 0; } }
  .wrapper .common-banner .center {
    position: relative;
    /*padding-top: 237px;*/
    height: 100%; }
    @media (max-width: 991.98px) {
      .wrapper .common-banner .center {
        padding-top: 0; } }
    .wrapper .common-banner .center .subpage-title {
      position: relative;
      height: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center; }
      @media (max-width: 991.98px) {
        .wrapper .common-banner .center .subpage-title {
          display: block; } }
      .wrapper .common-banner .center .subpage-title h1 {
        font-family: Metric-Bold;
        color: #FFFFFF;
        position: relative;
        z-index: 2; }
        .wrapper .common-banner .center .subpage-title h1 span {
          display: block; }
          @media (max-width: 575.98px) {
            .wrapper .common-banner .center .subpage-title h1 span {
              display: inline; } }
  .wrapper .common-banner .this-name {
    font-family: Metric-Regular;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #FFFFFF;
    position: absolute;
    left: 65px;
    bottom: 87px;
    transform: rotate(270deg);
    transform: rotate(270deg) translate(-50%, 0);
    transform-origin: 0 0;
    transform: perspective(1px) translateZ(0px) rotate(270deg); }
    @media (max-width: 991.98px) {
      .wrapper .common-banner .this-name {
        transform: rotate(0deg);
        transform: rotate(0deg) translate(-50%, 0);
        transform-origin: 0 0;
        transform: perspective(1px) translateZ(0px) rotate(0deg);
        position: initial;
        padding-left: 20px; } }

.wrapper .work-page .work-slide {
  margin: 99px 0 100px 0; }
  @media (max-width: 991.98px) {
    .wrapper .work-page .work-slide {
      margin-bottom: 40px; } }
  @media (max-width: 1400px) {
    .wrapper .work-page .work-slide .center {
      max-width: 1050px; } }
  @media (max-width: 1100px) {
    .wrapper .work-page .work-slide .center {
      max-width: 905px; } }
  @media (max-width: 767.98px) {
    .wrapper .work-page .work-slide .center {
      padding: 0; } }
  .wrapper .work-page .work-slide .information {
    width: 48%;
    right: 50%; }
  .wrapper .work-page .work-slide .banner figure {
    opacity: 1;
    top: 0; }

.wrapper .work-page .brands {
  padding-top: 65px;
  margin-bottom: 158px; }
  .wrapper .work-page .brands .top-title {
    position: relative;
    padding-right: 224px;
    margin-bottom: 49px; }
    @media (max-width: 991.98px) {
      .wrapper .work-page .brands .top-title {
        padding-right: 0;
        margin-bottom: -60px; } }
    .wrapper .work-page .brands .top-title h2 {
      font-family: Metric-Bold;
      font-size: 7rem;
      line-height: 8.4rem;
      color: #1A1A1A; }
      @media (max-width: 1249.98px) {
        .wrapper .work-page .brands .top-title h2 {
          font-size: 5rem;
          line-height: 6.4rem; } }
      @media (max-width: 991.98px) {
        .wrapper .work-page .brands .top-title h2 {
          font-size: 3.8rem;
          line-height: 4.6rem; } }
    .wrapper .work-page .brands .top-title figure {
      position: absolute;
      right: -88px;
      top: -50px; }
      @media (max-width: 1100px) {
        .wrapper .work-page .brands .top-title figure {
          right: 0; } }
      @media (max-width: 991.98px) {
        .wrapper .work-page .brands .top-title figure {
          position: relative;
          z-index: -1; } }
      .wrapper .work-page .brands .top-title figure img {
        max-width: 260px; }
  .wrapper .work-page .brands ul {
    margin: 0 -15px;
    display: table; }
    @media (max-width: 991.98px) {
      .wrapper .work-page .brands ul {
        margin: 0; } }
    .wrapper .work-page .brands ul li {
      float: left;
      width: 25%;
      padding: 0 15px; }
      @media (max-width: 991.98px) {
        .wrapper .work-page .brands ul li {
          width: 33%;
          padding: 0; } }
      @media (max-width: 767.98px) {
        .wrapper .work-page .brands ul li {
          width: 50%; } }
      .wrapper .work-page .brands ul li img {
        max-width: 100%; }

/*************************work*************************/
/*************************what-we-do*************************/
.wrapper .content {
  margin-top: 129px; }
  @media (max-width: 991.98px) {
    .wrapper .content {
      margin-top: 90px; } }
  .wrapper .content .center > section {
    color: #1A1A1A; }
    .wrapper .content .center > section:not(:last-child) {
      margin-bottom: 170px; }
      @media (max-width: 991.98px) {
        .wrapper .content .center > section:not(:last-child) {
          margin-bottom: 60px; } }
    .wrapper .content .center > section:last-child {
      margin-bottom: 147px; }
      @media (max-width: 991.98px) {
        .wrapper .content .center > section:last-child {
          margin-bottom: 60px; } }
    .wrapper .content .center > section > .row + .row {
      margin-top: 49px; }
    .wrapper .content .center > section > .row > div {
      float: left;
      min-height: 1px;
      padding: 0 15px; }
      @media (max-width: 991.98px) {
        .wrapper .content .center > section > .row > div {
          width: 100% !important; } }
      .wrapper .content .center > section > .row > div:first-child {
        width: 20%; }
      .wrapper .content .center > section > .row > div:last-child {
        width: 80%; }
    .wrapper .content .center > section > .row .row > div {
      float: left;
      width: 50%;
      padding: 0 15px; }
      @media (max-width: 767.98px) {
        .wrapper .content .center > section > .row .row > div {
          width: 100% !important; } }
    .wrapper .content .center > section h4 {
      font-family: Metric-Medium;
      margin-top: 30px; }
    .wrapper .content .center > section h2 {
      font-family: Metric-Bold;
      margin-bottom: 18px;
      padding-right: 70px; }
      @media (max-width: 991.98px) {
        .wrapper .content .center > section h2 {
          padding-right: 0;
          font-size: 3.8rem;
          line-height: 4.6rem; } }
    .wrapper .content .center > section h6 {
      font-family: Metric-Medium;
      font-size: 2rem;
      line-height: 2.4rem; }
      @media (max-width: 991.98px) {
        .wrapper .content .center > section h6 {
          margin-bottom: 9px; } }
    .wrapper .content .center > section p {
      font-family: Metric-Thin;
      font-size: 2rem;
      line-height: 2.8rem; }
    .wrapper .content .center > section ul li {
      font-family: Metric-Medium;
      font-size: 2rem;
      line-height: 3.4rem;
      position: relative;
      padding-left: 40px; }
      @media (max-width: 991.98px) {
        .wrapper .content .center > section ul li {
          font-size: 1.8rem;
          line-height: 2.6rem;
          margin-bottom: 5px; } }
      .wrapper .content .center > section ul li:before {
        content: "";
        position: absolute;
        left: 0;
        top: 16px;
        height: 2px;
        background: #1A1A1A;
        width: 16px; }

.wrapper .open-positions-page .content {
  margin-top: 0; }
  .wrapper .open-positions-page .content .center > section {
    margin: 0 !important;
    padding-top: 170px; }
    @media (max-width: 991.98px) {
      .wrapper .open-positions-page .content .center > section {
        padding-top: 100px; } }
    .wrapper .open-positions-page .content .center > section:last-child {
      margin-bottom: 147px !important; }
    .wrapper .open-positions-page .content .center > section .row .row > div {
      width: 100%;
      float: none; }
    .wrapper .open-positions-page .content .center > section h4 {
      margin-top: 0; }
    .wrapper .open-positions-page .content .center > section a {
      font-family: Metric-Medium;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #0000E6;
      display: inline-block;
      margin-top: 88px; }
      @media (max-width: 991.98px) {
        .wrapper .open-positions-page .content .center > section a {
          margin-top: 30px; } }

/*direction-bar*/
.direction-bar {
  background: #0000E6;
  height: 380px;
  border-radius: 6px;
  margin-bottom: 165px;
  position: relative; }
  .direction-bar.team-bar {
    margin-bottom: 130px; }
  .direction-bar h2 {
    font-family: Metric-Bold;
    color: #FFFFFF;
    /*max-width: 652px;*/
    margin: 0 auto;
    text-align: center;
    position: relative; }
    @media (max-width: 991.98px) {
      .direction-bar h2 {
        font-size: 3.8rem;
        line-height: 4.6rem; } }
    @media (max-width: 767.98px) {
      .direction-bar h2 {
        font-size: 3rem;
        line-height: 4.1rem; } }
    .direction-bar h2:before {
      content: "\e900";
      position: absolute;
      left: 50%;
      bottom: -71px;
      font-family: 'icomoon';
      font-size: 5.8rem;
      line-height: 3.1rem;
      color: #FFFFFF;
      margin-left: -29px;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: .3s ease-in-out; }
      @media (max-width: 991.98px) {
        .direction-bar h2:before {
          bottom: -50px; } }
    .direction-bar h2 span {
      display: block; }
      @media (max-width: 575.98px) {
        .direction-bar h2 span {
          display: inline; } }
  .direction-bar a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 0 30px;
    pointer-events: all; }
    @media (max-width: 575.98px) {
      .direction-bar a {
        padding: 0 15px; } }
    .direction-bar a > * {
      pointer-events: none; }
    .direction-bar a:hover h2:before {
      margin-left: -15px; }
  .direction-bar .icons {
    width: 100%;
    height: 100%; }
    .direction-bar .icons i {
      /*&.def-icon-word-tv{
				width: 230px;
				height: 290px;
				background: url(../images/content/word-tv.png) no-repeat;
				background-size: cover;
				position: absolute !important;
				bottom: -131px !important;
				left: -43px !important;
				top:auto !important;
				@media (max-width: $sm){
					left: 0 !important;
					width: 190px;
					height: 250px;
				}
			}
			&.icon-figure-3{
				@include font-size(162,73);
				color:$color-primary;
				position: absolute !important;
				bottom: -116px !important;
				left: -64px !important;
				top: auto !important;
				z-index: -1;
				@media (max-width: $sm){
					left: 0 !important;
				}
			}
			&.def-icon-bullet{
				width: 9px;
				height: 58px;
				background: url(../images/svg/bullet.svg) no-repeat;
				background-size: cover;
				position: absolute !important;
				top:100% !important;
				margin-top: 132px;
				left: -7px !important;
			}*/ }
      .direction-bar .icons i.def-icon-full-plus {
        position: absolute !important;
        left: -79.5px !important;
        bottom: -51.5px !important;
        top: auto !important;
        width: 201px;
        height: 180px;
        background: url(../images/svg/full-plus.svg) no-repeat;
        background-size: cover;
        z-index: -1; }
        @media (max-width: 991.98px) {
          .direction-bar .icons i.def-icon-full-plus {
            left: -70px !important; } }
        @media (max-width: 767.98px) {
          .direction-bar .icons i.def-icon-full-plus {
            width: 150px;
            height: 170px; } }
      .direction-bar .icons i.def-icon-image {
        width: 287px;
        height: 321px;
        background: url(../images/content/lets-build-icon.png) no-repeat;
        background-size: cover;
        position: absolute;
        left: -67px !important;
        bottom: -112px;
        top: auto !important; }
        @media (max-width: 767.98px) {
          .direction-bar .icons i.def-icon-image {
            width: 190px;
            height: 250px;
            left: 0 !important; } }
        @media (max-width: 374.98px) {
          .direction-bar .icons i.def-icon-image {
            width: 170px;
            height: 200px; } }
  .direction-bar i.def-icon-full-plus {
    position: absolute;
    left: -51px;
    top: 10.5px;
    width: 201px;
    height: 180px;
    background: url(../images/svg/full-plus.svg) no-repeat;
    background-size: cover;
    z-index: -1; }
  .direction-bar i.def-icon-world-icon {
    width: 295px;
    height: 243px;
    background: url(../images/content/team-world-icon.png) no-repeat;
    position: absolute !important;
    top: -118px !important;
    right: -45px !important;
    left: auto !important;
    background-size: cover; }

/*direction-bar*/
.what-we-do-page .customer-reviews {
  margin-bottom: 145px; }
  .what-we-do-page .customer-reviews .owl-carousel {
    padding-bottom: 102px; }
    .what-we-do-page .customer-reviews .owl-carousel .owl-nav {
      margin: 0;
      max-width: 200px;
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 31px;
      left: 50%;
      margin-left: -100px; }
      @media (max-width: 991.98px) {
        .what-we-do-page .customer-reviews .owl-carousel .owl-nav {
          left: 50%;
          margin-left: -100px; } }
      .what-we-do-page .customer-reviews .owl-carousel .owl-nav > div {
        margin: 0;
        position: absolute;
        width: 78px;
        height: 31px;
        background: none !important;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -ms-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: .3s ease-in-out; }
        .what-we-do-page .customer-reviews .owl-carousel .owl-nav > div:before {
          font-family: 'icomoon';
          font-size: 7.3rem;
          line-height: 3.1rem;
          position: absolute;
          top: 0;
          color: #1A1A1A; }
        .what-we-do-page .customer-reviews .owl-carousel .owl-nav > div.owl-prev {
          left: 0; }
          .what-we-do-page .customer-reviews .owl-carousel .owl-nav > div.owl-prev:before {
            content: "\e90f";
            left: 0; }
        .what-we-do-page .customer-reviews .owl-carousel .owl-nav > div.owl-next {
          right: 0; }
          .what-we-do-page .customer-reviews .owl-carousel .owl-nav > div.owl-next:before {
            content: "\e900";
            right: 0; }
    .what-we-do-page .customer-reviews .owl-carousel .owl-dots {
      counter-reset: slides-num;
      position: absolute;
      bottom: 4px;
      left: 50%;
      margin-left: -5px; }
      @media (max-width: 991.98px) {
        .what-we-do-page .customer-reviews .owl-carousel .owl-dots {
          left: 50%;
          margin-left: -4px; } }
      .what-we-do-page .customer-reviews .owl-carousel .owl-dots:before {
        content: " / ";
        position: absolute;
        left: 0;
        top: 0;
        font-family: Metric-Bold;
        font-size: 2rem;
        line-height: 2.4rem;
        color: #1A1A1A; }
      .what-we-do-page .customer-reviews .owl-carousel .owl-dots:after {
        content: counter(slides-num);
        display: inline-block;
        font-size: 2rem;
        line-height: 2.4rem;
        font-family: Metric-Bold;
        color: #1A1A1A; }
    .what-we-do-page .customer-reviews .owl-carousel .owl-dot {
      display: inline-block;
      counter-increment: slides-num;
      margin-right: 5px; }
      .what-we-do-page .customer-reviews .owl-carousel .owl-dot span {
        display: none; }
      .what-we-do-page .customer-reviews .owl-carousel .owl-dot.active:before {
        content: counter(slides-num);
        display: inline-block;
        position: absolute;
        font-family: Metric-Bold;
        font-size: 2rem;
        line-height: 2.4rem;
        color: #1A1A1A;
        left: -11px;
        top: 0; }

/*************************what-we-do*************************/
/*************************team*************************/
.team-page .video-player {
  position: relative;
  margin: 82px auto 106px auto;
  max-width: 946px; }
  @media (max-width: 991.98px) {
    .team-page .video-player {
      margin-bottom: 60px; } }
  .team-page .video-player i.def-icon-full-plus {
    position: absolute;
    left: -51px;
    top: -42px;
    width: 201px;
    height: 180px;
    background: url(../images/svg/full-plus.svg) no-repeat;
    background-size: cover; }
    @media (max-width: 991.98px) {
      .team-page .video-player i.def-icon-full-plus {
        left: -41px; } }
  .team-page .video-player figure {
    height: 551px;
    border-radius: 8px;
    position: relative;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    /*&:before{
				content:'';
				position: absolute;
				width: 82px;
				height: 82px;
				background: $color-white;
				border-radius: 50%;
				left: 50%;
				top: 50%;
				margin-top: -41px;
				margin-left: -41px;
			}
			&:after{
				content:'\e90d';
				position: absolute;
				font-family: 'icomoon';
				font-size: 26px;
				color:$color-primary;
				top: 50%;
				left: 50%;
				margin-left: -10px;
				margin-top: -12px;
				line-height: 1;
			}*/ }
    @media (max-width: 991.98px) {
      .team-page .video-player figure {
        height: 400px; } }
    @media (max-width: 767.98px) {
      .team-page .video-player figure {
        height: 350px; } }
    @media (max-width: 575.98px) {
      .team-page .video-player figure {
        height: 300px; } }

.team-page .team-list {
  max-width: 1082px;
  margin: 0 auto 125px auto; }
  .team-page .team-list ul {
    display: table;
    width: 100%; }
    .team-page .team-list ul li {
      float: left;
      display: inline-block;
      position: relative;
      /*&:nth-child(3n+2){background: red}*/ }
      @media (max-width: 575.98px) {
        .team-page .team-list ul li {
          float: none;
          display: block;
          text-align: center; } }
      .team-page .team-list ul li:not(.disable):after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #1A1A1A;
        display: inline-block;
        margin: 0 6px;
        vertical-align: super; }
        @media (max-width: 991.98px) {
          .team-page .team-list ul li:not(.disable):after {
            width: 8px;
            height: 8px; } }
        @media (max-width: 575.98px) {
          .team-page .team-list ul li:not(.disable):after {
            content: none; } }
      .team-page .team-list ul li:nth-child(1):after {
        background: #04E17D; }
      .team-page .team-list ul li:nth-child(7):after {
        background: #E80127; }
      .team-page .team-list ul li:nth-child(9):after {
        background: #E7C300; }
      .team-page .team-list ul li:nth-child(6):after {
        background: #0000E6; }
      .team-page .team-list ul li:nth-child(16):after {
        background: #E7C300; }
      .team-page .team-list ul li:nth-child(17):after {
        background: #E80127; }
      .team-page .team-list ul li:nth-child(21):after {
        background: #04E17D; }
      .team-page .team-list ul li:nth-child(24):after {
        background: #04E17D; }
      .team-page .team-list ul li a {
        font-family: Metric-Bold;
        font-size: 4.5rem;
        line-height: 5.4rem;
        color: #1A1A1A; }
        @media (max-width: 1249.98px) {
          .team-page .team-list ul li a {
            font-size: 4rem;
            line-height: 4.9rem; } }
        @media (max-width: 991.98px) {
          .team-page .team-list ul li a {
            font-size: 3.7rem;
            line-height: 4.6rem; } }
        @media (max-width: 767.98px) {
          .team-page .team-list ul li a {
            font-size: 3.4rem;
            line-height: 4.3rem; } }
        @media (max-width: 575.98px) {
          .team-page .team-list ul li a {
            font-size: 2rem;
            line-height: 2.9rem; } }

/*************************team*************************/
/*************************contact*************************/
.contact-page .contact-information {
  margin: 125px auto 103px auto;
  max-width: 1000px; }
  @media (max-width: 767.98px) {
    .contact-page .contact-information {
      margin: 60px 0 30px 0; } }
  .contact-page .contact-information .row > div {
    width: 50%;
    float: left;
    padding: 0 15px; }
    @media (max-width: 767.98px) {
      .contact-page .contact-information .row > div {
        width: 100%;
        float: none; } }
  .contact-page .contact-information table tr td {
    font-family: Metric-Regular;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #1A1A1A;
    opacity: 0.7;
    padding-bottom: 30px;
    vertical-align: top;
    padding-right: 45px; }
    .contact-page .contact-information table tr td:first-child {
      font-family: Metric-Bold;
      opacity: 1;
      padding-right: 60px; }
    @media (max-width: 767.98px) {
      .contact-page .contact-information table tr td {
        display: block;
        padding-bottom: 10px; } }
    @media (max-width: 767.98px) {
      .contact-page .contact-information table tr td.url-list {
        padding-bottom: 0; } }
    .contact-page .contact-information table tr td a {
      color: #BBBBBB;
      font-family: Metric-Medium; }
      .contact-page .contact-information table tr td a.email {
        color: #0000E6;
        font-family: Metric-Regular; }

.contact-page .map-image {
  margin-bottom: 140px; }
  @media (max-width: 991.98px) {
    .contact-page .map-image {
      margin-bottom: 70px; } }
  .contact-page .map-image .row > div {
    width: 50%;
    float: left;
    padding: 0 15px; }
    @media (max-width: 767.98px) {
      .contact-page .map-image .row > div {
        width: 100%;
        float: none;
        padding: 15px; } }
  .contact-page .map-image .map {
    position: relative; }
    .contact-page .map-image .map > a {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 9;
      font-family: Metric-Medium;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #0000E6;
      background: #FFFFFF;
      padding: 10px 20px;
      border-radius: 6px;
      display: inline-block; }
  .contact-page .map-image #map, .contact-page .map-image figure {
    width: 100%;
    height: 451px;
    background-size: cover !important; }
    @media (max-width: 1249.98px) {
      .contact-page .map-image #map, .contact-page .map-image figure {
        height: 390px; } }
    @media (max-width: 991.98px) {
      .contact-page .map-image #map, .contact-page .map-image figure {
        height: 320px; } }
    @media (max-width: 767.98px) {
      .contact-page .map-image #map, .contact-page .map-image figure {
        height: 300px; } }
    @media (max-width: 374.98px) {
      .contact-page .map-image #map, .contact-page .map-image figure {
        height: 280px; } }

/*************************contact*************************/
/*************************common-banner-pages*************************/
.work-page .common-banner figure {
  max-width: 406px;
  width: 100%;
  position: absolute;
  right: -85px;
  bottom: -53px; }

.open-positions-page .common-banner figure {
  max-width: 465px;
  width: 100%;
  position: absolute;
  right: -124px;
  bottom: -81px;
  z-index: 1; }

.open-positions-page .common-banner i.def-icon-full-plus {
  position: absolute;
  right: -63px;
  bottom: -112px;
  width: 201px;
  height: 180px;
  background: url(../images/svg/full-plus.svg) no-repeat;
  background-size: cover; }

.what-we-do-page .common-banner figure {
  max-width: 350px;
  width: 100%;
  position: absolute;
  right: -101px;
  bottom: -43px;
  z-index: 0; }

.what-we-do-page .common-banner i.def-icon-full-plus {
  position: absolute;
  right: -49px;
  bottom: -136px;
  width: 201px;
  height: 180px;
  background: url(../images/svg/full-plus.svg) no-repeat;
  background-size: cover; }

@media (max-width: 991.98px) {
  .team-page .common-banner {
    padding-bottom: 0; } }

.team-page .common-banner figure {
  max-width: 353px;
  width: 100%;
  position: absolute;
  right: -85px;
  bottom: 0; }

@media (max-width: 991.98px) {
  .contact-page .common-banner {
    padding-bottom: 0; } }

.contact-page .common-banner figure {
  max-width: 469px;
  width: 100%;
  position: absolute;
  right: -61px;
  bottom: -43px; }

@media (max-width: 991.98px) {
  .wrapper .common-banner figure {
    position: initial;
    margin-top: 15px;
    display: table; } }

@media (max-width: 767.98px) {
  .wrapper .common-banner figure {
    max-width: 100%; } }

.wrapper .common-banner figure img {
  max-width: 100%; }

@media (max-width: 991.98px) {
  .wrapper .common-banner figure i {
    left: -38px;
    height: 114px;
    bottom: -120px; } }

/*************************common-banner-pages*************************/
/*************************cursor*************************/
#cursor {
  position: fixed;
  text-align: center;
  width: 80px;
  height: 80px;
  z-index: 1000;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  pointer-events: none; }

@media (max-width: 768.98px) {
  #cursor {
    display: none; } }

#cursor .cursor__bg {
  -webkit-transition: transform .2s ease-in-out;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: block;
  left: 0;
  top: 0;
  mix-blend-mode: exclusion;
  background: #0000E6;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  -webkit-transform: scale(0.15);
  transform: scale(0.15); }

#cursor .cursor__label {
  display: block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 1.1em;
  font-weight: 600;
  letter-spacing: .14384615em;
  text-transform: uppercase; }

#cursor .cursor__label {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  z-index: 1; }

#cursor.is-medium .cursor__bg {
  -webkit-transform: scale(0.475);
  transform: scale(0.475); }

#cursor.is-big .cursor__bg {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.7; }

[data-cursor-type], [data-cursor-type]:hover {
  cursor: none !important; }

html.msie #cursor {
  display: none !important; }

/*a{pointer-events: all;
	*{pointer-events: none;}
}*/
.owl-carousel {
  -ms-touch-action: pan-y !important;
  touch-action: pan-y !important; }

/*************************cursor*************************/
