@font-face {
    font-family: 'Metric-Bold';
    src: url('#{$fonts}/metric-bold.eot');
    src: url('#{$fonts}/metric-bold.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/metric-bold.woff2') format('woff2'),
         url('#{$fonts}/metric-bold.woff') format('woff'),
         url('#{$fonts}/metric-bold.ttf')  format('truetype'),
         url('#{$fonts}/metric-bold.svg#Metric') format('svg');
}
@font-face {
    font-family: 'Metric-Medium';
    src: url('#{$fonts}/metric-medium.eot');
    src: url('#{$fonts}/metric-medium.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/metric-medium.woff2') format('woff2'),
         url('#{$fonts}/metric-medium.woff') format('woff'),
         url('#{$fonts}/metric-medium.ttf')  format('truetype'),
         url('#{$fonts}/metric-medium.svg#Metric') format('svg');
}
@font-face {
    font-family: 'Metric-Regular';
    src: url('#{$fonts}/metric-regular.eot');
    src: url('#{$fonts}/metric-regular.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/metric-regular.woff2') format('woff2'),
         url('#{$fonts}/metric-regular.woff') format('woff'),
         url('#{$fonts}/metric-regular.ttf')  format('truetype'),
         url('#{$fonts}/metric-regular.svg#Metric') format('svg');
}
@font-face {
    font-family: 'Metric-Thin';
    src: url('#{$fonts}/metric-thin.eot');
    src: url('#{$fonts}/metric-thin.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/metric-thin.woff2') format('woff2'),
         url('#{$fonts}/metric-thin.woff') format('woff'),
         url('#{$fonts}/metric-thin.ttf')  format('truetype'),
         url('#{$fonts}/metric-thin.svg#Metric') format('svg');
}
@font-face {
  font-family: 'icomoon';
  src:  url('#{$fonts}/icomoon.eot?t1k8qq');
  src:  url('#{$fonts}/icomoon.eot?t1k8qq#iefix') format('embedded-opentype'),
        url('#{$fonts}/icomoon.ttf?t1k8qq') format('truetype'),
        url('#{$fonts}/icomoon.woff?t1k8qq') format('woff'),
        url('#{$fonts}/icomoon.svg?t1k8qq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}