//............................................................
// = VARIABLES
//............................................................

$images:							'../images';
$fonts:								'../fonts';
$transition:						.5s ease-in-out;

$metricbold:						Metric-Bold;
$metricmedium:						Metric-Medium;
$metricregular:						Metric-Regular;
$metricthin:						Metric-Thin;

$color-primary:						#0000E6;
$color-secondry:					#04E17D;
$typography-dark:					#1A1A1A;
$typography-middle:					#BBBBBB;
$typography-light:					#FAFAFA;
$color-successfull:					#5AD52C;
$color-error:						#D52C43;

$color-black:						#000000;
$color-white:						#FFFFFF;

$products-color-1:					#E65800;
$products-color-2:					#ed2d34;
/*$color-alternatifbank:				#9E0D49;*/

$media_height_980: 					'max-height:980px';
$media_height_850: 					'max-height:850px';
$media_height_768: 					'max-height:768px';
$media_height_480: 					'max-height:480px';

$extra_xs:							'374.98px';
$middle_xs: 						'575.98px';
$xs: 								'767.98px';
$sm: 								'991.98px';
$md1150: 							'1150px';
$md1100: 							'1100px';
$md: 								'1249.98px';
$md1330:							'1330px';
$md1400:							'1400px';
$md1700:							'1700px';
$md1920:							'1920px';